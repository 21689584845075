import React from "react";
import NavbarAdmin from "../NavbarAdmin/NavbarAdmin";
import "./HeaderAdmin.scss";

interface Props {
	title: string;
	subtitle: string;
	icon: JSX.Element;
}

const HeaderAdmin: React.FC<Props> = ({
	title = "",
	subtitle = "",
	icon = <></>,
}: Props) => {
	return (
		<>
			<div className="HeaderAdmin">
				<h1>
					{icon} {title}
				</h1>
				{/* <h2>{subtitle}</h2> */}
			</div>
			<NavbarAdmin />
		</>
	);
};

export default HeaderAdmin;
