import "./CardGradesResources.scss";

import { Button, Card, Col, Row } from "reactstrap";
import React, { FC, useEffect, useState, useRef } from "react";

import CardScore from "../../CardScore/CardScore";
import CardScore2 from "../../CardScore2/CardScore2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Plyr from "plyr";
import VideoModal from "../../VideoModal/VideoModal";
import { faCheck, faPrint } from "@fortawesome/free-solid-svg-icons";
import { useDashboard } from "../../../providers/DashboardProvider";
import { useReactToPrint } from "react-to-print";
import { Link } from "react-router-dom";
import ModalTrail from "../../ModalTrail/ModalTrail";

interface CardGradesResourcesProps {
	data: any[];
	page: string;
	cardscore?: JSX.Element;
	titleTutorial?: string;
	videoTutorial?: string;
	informacaoPiorSubRecurso?: any;
	showVideo?: boolean;
}

const CardGradesResources: FC<CardGradesResourcesProps> = ({
	data,
	page,
	cardscore,
	titleTutorial,
	videoTutorial,
	informacaoPiorSubRecurso,
	showVideo = true,
}) => {
	const player = new Plyr("#player");
	const { renderIcon, renderBgIcon, theme, renderBgIconHex } = useDashboard();
	const [style, setStyle] = useState({ "--list-color": "var(--c1)" });
	const [showModalTrail, setShowModalTrail] = useState<boolean>(false);
	const [sourceModalTrail, setSourceModalTrail] = useState<string>("");
	const [showModalVideo, setShowModalVideo] = useState<boolean>(false);

	useEffect(() => {
		if (informacaoPiorSubRecurso?.length) {
			setStyle({
				"--list-color": informacaoPiorSubRecurso.piorSubRecurso.corFundo,
			});
		}
	}, [informacaoPiorSubRecurso]);

	useEffect(() => {
		if (sourceModalTrail != "") setShowModalTrail(true);
	}, [sourceModalTrail]);

	return (
		<div
			className="CardGradesResources"
			data-testid="CardGradesResources"
			style={style as React.CSSProperties}
		>
			{data?.length > 0 && (
				<div>
					<div>
						<div className="square">
							<div className="content">
								{data.map((item: any, key: number) => {
									return (
										<>
											{page === "diagnose" ? (
												<CardScore key={key} item={item} />
											) : (
												<CardScore2 key={key} item={item} />
											)}
										</>
									);
								})}
								{cardscore}
							</div>
						</div>

						{informacaoPiorSubRecurso && (
							<div className="piorrecurso wrapper">
								<Card
									className={`card-inverse bg-uelf ${informacaoPiorSubRecurso.piorSubRecurso.recurso}`}
									style={{
										borderLeftWidth: "1.625rem",
										borderLeftColor: renderBgIconHex(
											informacaoPiorSubRecurso.piorSubRecurso.recurso,
										),
										borderStyle: "solid",
									}}
								>
									<div className="introducao">
										<div className="image">
											{renderIcon(0, informacaoPiorSubRecurso.piorSubRecurso.recurso)}
										</div>
										<div className="grouping">
											<div className="title main">
												<span>{informacaoPiorSubRecurso.piorSubRecurso.descricao}</span>
											</div>
											{/* <div
												className="title"
												style={{ color: informacaoPiorSubRecurso.piorSubRecurso.corFundo }}
											>
												<span>{informacaoPiorSubRecurso.piorSubRecurso.recurso}</span>
											</div> */}
										</div>
									</div>
									<div className="text-description">
										{informacaoPiorSubRecurso?.introducao.map(
											(item: any, key: number) => {
												return <p key={key}>{item?.descricao}</p>;
											},
										)}
									</div>
									{/* <div className="avaliacao">
										<span className="title">O que minha avaliação me diz</span>
										<div className="text">
											{informacaoPiorSubRecurso?.avaliacao.map(
												(item: any, key: number) => {
													return <p key={key}>{item?.descricao}</p>;
												},
											)}
										</div>
									</div> */}
									{/* <div className="atividades">
										<span className="title">Atividades desta experiência</span>
										<ul className="special-list-type">
											{informacaoPiorSubRecurso?.atividades.map(
												(item: any, key: number) => {
													return <li key={key}>{item?.descricao}</li>;
												},
											)}
										</ul>
									</div> */}
									<div className="aprendizado">
										{/* <div className="wrapper">
											<span className="title">O que você poderá aprender</span>
											<ul>
												{informacaoPiorSubRecurso?.aprendizado.map(
													(item: any, key: number) => {
														return (
															<li key={key}>
																<FontAwesomeIcon
																	icon={faCheck}
																	style={{
																		background:
																			informacaoPiorSubRecurso?.piorSubRecurso?.corFundo,
																	}}
																/>
																{item?.descricao}
															</li>
														);
													},
												)}
											</ul>
										</div> */}
										<div className="wrapper">
											<div
												className="image-title"
												style={{
													background: `url('${process.env.PUBLIC_URL}/assets/img/dicas/bg-dicas-1.svg') no-repeat center`,
												}}
											>
												O que você escolheu fazer
											</div>
											<div className="text">
												{informacaoPiorSubRecurso?.listaEvitarFazer.map(
													(item: any, key: number) => {
														if (item?.idEtapa === 4)
															return (
																<p key={key}>
																	<img
																		src={`/assets/img/icons/common/greenlike.svg`}
																		className="mr-3"
																	/>
																	{item?.resposta}
																</p>
															);
													},
												)}
											</div>
										</div>
										<div className="wrapper">
											<div
												className="image-title"
												style={{
													background: `url('${process.env.PUBLIC_URL}/assets/img/dicas/bg-dicas-2.svg') no-repeat center`,
												}}
											>
												O que você escolheu evitar
											</div>
											<div className="text">
												{informacaoPiorSubRecurso?.listaEvitarFazer.map(
													(item: any, key: number) => {
														if (item?.idEtapa === 3)
															return (
																<p key={key}>
																	<img
																		src={`/assets/img/icons/common/redunlike.svg`}
																		className="mr-3"
																	/>
																	{item?.resposta}
																</p>
															);
													},
												)}
											</div>
										</div>
									</div>
								</Card>
								<Card className="card-learning">
									<Row>
										<Col className="col-12 col-md-6">
											<div className="title main">
												<span>Primeiro Passo:</span>
											</div>
											<p className="text mt-3">
											Espaço reservado para você dar o primeiro passo rumo a sua melhor versão. Clique nos próximos passos para começar sua jornada no UELF EDUCATION.
											</p>

											<div className="list">
												{informacaoPiorSubRecurso?.aprendizado.map((v: any, k: number) => {
													return (
														<p
															style={{
																background: `url('${
																	process.env.PUBLIC_URL
																}/assets/img/icons/checks/${renderBgIcon(
																	informacaoPiorSubRecurso.piorSubRecurso.recurso,
																)}.svg') no-repeat left`,
																paddingLeft: "2rem",
															}}
															key={k}
														>
															{v.descricao}
														</p>
													);
												})}
											</div>
										</Col>
										<Col className="col-12 col-md-6">
											<div
												className="image-performance"
												style={{
													background: `url(/assets/img/subrecursos/1.svg) no-repeat center center / cover`,
												}}
											></div>
										</Col>
									</Row>
								</Card>
							</div>
						)}
						{/* <div className="d-flex justify-content-end">
							<Link
								to={"/home"}
								style={{ display: "contents" }}
							>
								<Button>
									<span>Início</span>
								</Button>
							</Link>
						</div> */}
					</div>

					{videoTutorial ? (
						<div
							className="tutorial"
							style={{ display: showVideo && page == "diagnose" ? "block" : "none" }}
						>
							{/* <div className="title">{titleTutorial}</div> */}
							<Button
								className="btn-video-modal"
								onClick={() => {
									setShowModalVideo(true);
								}}
							>
								<div className="d-flex">
									<div className="image">
										<img src="assets/video-icon.png" alt="Video Icon" />
									</div>
									<span>ASSISTA AO VÍDEO</span>
								</div>
							</Button>
							<VideoModal
								show={showModalVideo}
								onClose={setShowModalVideo}
								// @ts-ignore
								url={videoTutorial}
							/>
							{/* <div className="video-container">
								<div className="player">
									<video id="player" playsInline controls>
										<source src={videoTutorial} type="video/mp4" />
									</video>
								</div>
							</div> */}
						</div>
					) : (
						""
					)}
				</div>
			)}
			<ModalTrail
				show={showModalTrail}
				onClose={setShowModalTrail}
				source={sourceModalTrail}
			/>
		</div>
	);
};

export default CardGradesResources;
