import "./ModalAccountRecovery.scss";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useEffect, useState } from "react";

import AuthService from "../../services/AuthService";
import { toastSettings } from "../../models/toastConfig";
import { useForm } from "react-hook-form";

interface RecoveryEmailInputs {
	recoveryEmail: string;
}

interface ModalNewsProps {
	show: boolean;
	onClose?: any;
}

const ModalAccountRecovery = ({ show, onClose }: ModalNewsProps) => {
	const [modal, setModal] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const {
		register: register,
		handleSubmit: handleSubmit,
		reset,
		formState: { errors: errors },
	} = useForm<RecoveryEmailInputs>();

	const toggle = () => setModal(!modal);
	const handleOnClose = () => onClose(false);

	const sendEmail = async (data: { recoveryEmail: string }) => {
		const login = {
			login: data.recoveryEmail,
		};

		try {
			setIsSubmitting(true);
			const [_Response, _Error] = await new AuthService().SendRecoveryEmail(login);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				setTimeout(() => setIsSubmitting(false), 1000);
				return false;
			} else {
				toastSettings(
					"O email foi enviado com sucesso.",
					"bottom-center",
					"success",
				);
			}
		} catch (err) {
			console.warn(err);
		}

		toggle();
		setIsSubmitting(false);
	};

	useEffect(() => {
		if (show) {
			reset();
			setIsSubmitting(false);
			setModal(show);
		}
	}, [show]);

	return (
		<Modal
			centered
			scrollable
			size="md"
			isOpen={modal}
			toggle={toggle}
			onClosed={handleOnClose}
			className="modal-forgot modal-account-recovery"
			data-testid="ModalAccountRecovery"
		>
			<form key={1} onSubmit={handleSubmit(sendEmail)}>
				<ModalHeader toggle={toggle}>Recuperação de conta</ModalHeader>
				<ModalBody>
					<h2 className="title s">
						Com isso, é possível confirmar que a conta realmente é sua.
					</h2>
					<div className="text">
						<p>
							Para receber um código de verificação, primeiro confirme o endereço de
							e-mail que você adicionou à sua conta UELF.
						</p>
						<p>
							Vamos te ajudar a recuperar a sua senha em 5 etapas.
							<ul>
								<li>
									1. Você receberá um email da Equipe UELF para informar a sua nova senha
									(preste atenção na sua caixa de Spam, o email é valido por apenas 15
									min).
								</li>
								<li>
									2. Clique no botão recuperar senha e você será redirecionado para uma
									tela de redefinição de senhas. Você deverá digitar a sua senha e
									confirmá-la.
								</li>
							</ul>
							<p><span className="font-weight-bold">Importante:</span> A nova senha precisa conter 8 caracteres, pelo menos um
							número e letras maiúsculas e minúsculas.</p>
							<ul>
								<li>
									3. Você receberá um segundo e-mail com um código de validação da sua
									solicitação. Está etapa é necessária em virtude da nova lei de proteção
									de Dados LGPD, e vc só precisa informar o código recebido por email no
									último campo da tela de alteração de senha, para validar-la.
								</li>
								<li>
									4. Clique no botão de redefinir a senha e a sua senha será alterada com
									sucesso.
								</li>
								<li> 5. Acesse o sistema com a sua nova senha.</li>
							</ul>
						</p>
					</div>
					<div className="fieldset">
						<label htmlFor="recovery-email">E-mail</label>
						<input
							type="email"
							id="recovery-email"
							{...register("recoveryEmail", { required: true })}
							maxLength={50}
						/>
						{errors.recoveryEmail && (
							<span className="error">Este campo é obrigatório</span>
						)}
					</div>
					<div className="text">
						<p>
							<b>Atenção</b>: o e-mail de recuperação é válido por apenas 15 minutos!
						</p>
						<p>Verifique o horário que recebeu o mesmo.</p>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button type="button" disabled={isSubmitting} onClick={toggle}>
						Cancelar
					</Button>
					<Button type="submit" disabled={isSubmitting} color="primary">
						Enviar email de recuperação
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default ModalAccountRecovery;
