import React, { FC } from "react";
import GaugeChart from "react-gauge-chart";
import { Card, CardTitle } from "reactstrap";
import { useActiveJourney } from "../../../providers/ActiveJourneyProvider";
import "./CardGrades.scss";

interface CardGradesProps {
	pontuacao: string;
	fraseNotaVida?: string;
	status?: string;
}

const CardGrades: FC<CardGradesProps> = ({
	pontuacao = "",
	fraseNotaVida = "",
	status = "",
}) => {
	const { activeJourney } = useActiveJourney();
	let pontuacaoFormat = parseInt(pontuacao).toLocaleString("pt-BR");

	return (
		<div className="CardGrades">
			<div className="header-card">
				<h1 className="title">Sua nota final</h1>
			</div>
			<div className="note-of-life" data-testid="CardGrades">
				{pontuacao ? (
					<>
						<div className="chart-wrapper">
							<div className="chart-label">
								<span className="title">{status}</span>
								<div>
									<GaugeChart
										className="gauge-chart"
										nrOfLevels={5}
										colors={["#A8E297", "#77B974", "#59A632", "#498A28", "#2C671A"]}
										arcWidth={0.5}
										percent={parseFloat(pontuacao) / 100000}
										hideText
										cornerRadius={0}
										needleBaseColor="#fff"
										needleColor="#262626"
									/>
									<span className="status">
										<b>{pontuacaoFormat}</b>/100.000
									</span>
								</div>
								{/* <span className="subtitle">Pontos</span> */}
							</div>
						</div>

						<div className="text">
							<p className="mt-5">{fraseNotaVida || ""}</p>
						</div>
					</>
				) : (
					<div className="items-wrapper">
						<p className="help-text">
							{!activeJourney ? "Não há" : ""} Jornada em Andamento
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default CardGrades;
