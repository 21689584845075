import { faBoxArchive, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Button, Card, FormGroup, Label } from "reactstrap";
import ButtonNextScreen from "../../components/ButtonNextScreen/ButtonNextScreen";
import JourneyBackpackAddModal from "../../components/JourneyBackpackAddModal/JourneyBackpackAddModal";
import JourneyBackpackConfirmModal from "../../components/JourneyBackpackConfirmModal/JourneyBackpackConfirmModal";
import JourneyBackpackItem from "../../components/JourneyBackpackItem/JourneyBackpackItem";
import Loading from "../../components/Loading/Loading";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";
import { useJourneyBackpack } from "../../providers/JourneyBackpackProvider";
import "./JourneyBackpack.scss";
import { useDashboard } from "../../providers/DashboardProvider";

interface JourneyBackpackProps {}

const JourneyBackpack: FC<JourneyBackpackProps> = () => {
	const [loading, setLoading] = useState<boolean>(false);

	const {
		data,
		showModalAdd,
		setShowModalAdd,
		setItemSelected,
		handleList,
		showModalRemove,
		setShowModalRemove,
	} = useJourneyBackpack();
	const { dataUser } = useActiveJourney();
	const { theme } = useDashboard();
	const navigate = useNavigate();

	useEffect(() => {
		if (dataUser && dataUser?.idPlano <= 1) {
			navigate("/home");
		} else {
			setShowModalAdd(false);
			setItemSelected(null);

			setLoading(true);
			handleList().then(() => {
				setLoading(false);
			});
		}
	}, [dataUser]);

	const render = () => {
		return (
			<div className={`journey-backpack-item card`}>
				<FormGroup check inline>
					<Label check>
						<Card>
							<div className="journey-backpack-item-row title-row">
								<div className="journey-backpack-item-col">
									<span className="title">Item</span>
								</div>
								<div className="journey-backpack-item-col">
									<span className="title">Objetivo</span>
								</div>
								<div className="journey-backpack-item-col">
									<span className="title">
										<img src="/assets/img/link.svg" /> Link
									</span>
								</div>
								<div className="journey-backpack-item-col">
									<span className="title">Tempo Estimado</span>
								</div>
								{/* <div className="journey-backpack-item-col"></div> */}
							</div>

							<JourneyBackpackItem data={data} />
						</Card>
					</Label>
				</FormGroup>
			</div>
		);
	};

	return (
		<PainelMasterPage
			title={"Mochila da Jornada"}
			subtitle={
				"Descreva itens que você deseja para lhe auxiliar em sua jornada. Inclua os detalhes."
			}
			icon={
				theme == "dark" ? (
					<img src="assets/img/icons/title/evolution.svg" />
				) : (
					<img src="assets/img/icons/title/evolution-light.svg" />
				)
			}
			onClick={() => {}}
		>
			<div className="JourneyBackpack" data-testid="JourneyBackpack">
				{!loading ? (
					<>
						{/* <div className="image">
							{theme == "dark" ? (
								<>
									{" "}
									<img
										src="/assets/img/objetivos.png"
										alt="Objetivos"
										width="100%"
									/>{" "}
								</>
							) : (
								<>
									{" "}
									<img
										src="/assets/img/objetivo-white.png"
										alt="Objetivos"
										width="100%"
									/>{" "}
								</>
							)}
						</div> */}
						<div className="actions">
							<Button
								onClick={() => {
									setItemSelected(null);
									setShowModalAdd(true);
								}}
							>
								<FontAwesomeIcon icon={faPlusSquare} />
								<span>Adicionar itens</span>
							</Button>
						</div>

						{data && <>{render()}</>}

						<ButtonNextScreen text={"Voltar"} linkScreen={"/macro-goals"} />
					</>
				) : (
					<Loading margin="my-6" />
				)}
			</div>

			<JourneyBackpackAddModal
				show={showModalAdd}
				onClose={() => {
					setShowModalAdd(false);
					handleList().then();
				}}
			/>

			<JourneyBackpackConfirmModal
				show={showModalRemove}
				onClose={() => {
					setShowModalRemove(false);
					handleList().then();
				}}
			/>

			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</PainelMasterPage>
	);
};

export default JourneyBackpack;
