export default function FormatNote(num: any, digits: number = 2) {
	let note: any = parseFloat(num);

	if (isNaN(note)) {
		return "--";
	}

	if (Number.isInteger(note)) {
		// note = note.toFixed(1);
		note = note.toFixed(digits);
	} else {
		note = note.toFixed(digits);
	}

	return note.replace(".", ",");
}
