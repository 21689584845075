import "./CardResourcesPanelAdmin.scss";

import { useEffect } from "react";
import CardResourceAdmin from "../CardResourceAdmin/CardResourceAdmin";
import { useDashboardAdmin } from "../../providers/DashboardAdminProvider";

const CardResourcesPanelAdmin = ({ subResourcesData }: any) => {

	return (
		<div className="CardResourcesPanelAdmin">
			{subResourcesData?.dadosRecursos &&
				subResourcesData?.dadosRecursos[0].map((recurso: any) => {
					let maxScore = !subResourcesData?.dadosRecursos[0].filter((recursoPesquisa: any) => {
						return recursoPesquisa?.mediaGeral > recurso?.mediaGeral;
					}).length;

					return <CardResourceAdmin key={recurso?.titulo} recurso={recurso} maxScore={maxScore} />;
				})}
		</div>
	);
};

// @ts-ignore
export default CardResourcesPanelAdmin;
