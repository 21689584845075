import "./HomeAdmin.scss";

import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import HeaderAdmin from "../../components/HeaderAdmin/HeaderAdmin";
import {
	faArrowDown,
	faCheck,
	faHome,
	faUserGroup,
	faUserXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SVG from "react-inlinesvg";
import { useAdminAuth } from "../../providers/AuthAdminProvider";
import GaugeChart from "react-gauge-chart";
import Footer from "../../components/Footer/Footer";
import { useDashboardAdmin } from "../../providers/DashboardAdminProvider";
import { Link } from "react-router-dom";
import ChartPerformanceAdmin from "../../components/ChartPerformanceAdmin/ChartPerformanceAdmin";
import Loading from "../../components/Loading/Loading";
import CardResourcesPanelAdmin from "../../components/CardResourcesPanelAdmin/CardResourcesPanelAdmin";
import CardStatusDashboard from "../../components/CardStatusDashboard/CardStatusDashboard";
import { useDashboard } from "../../providers/DashboardProvider";
import ModalUserWorseResource from "../../components/ModalUserWorseResource/ModalUserWorseResource";
import CardStatusResourceDashboard from "../../components/CardStatusResourceDashboard/CardStatusResourceDashboard";
import FormatNote from "../../utils/FormatNote";

const HomeAdmin = () => {
	const { admin } = useAdminAuth();
	const { generalData, getData } = useDashboardAdmin();
	const { getSubresourcesData, subResourcesData } = useDashboardAdmin();
	const [isLoading, setLoading] = useState<boolean>(false);
	const { renderIconUrl } = useDashboard();
	const [modalUserWorseResouce, setModalUserWorseResouce] =
		useState<boolean>(false);
	const [selectedResource, setSelectedResource] = useState<string>("");

	useEffect(() => {
		if (!subResourcesData) {
			setLoading(true);
			getSubresourcesData().then(() => setLoading(false));
		}
	}, [subResourcesData]);

	useEffect(() => {
		if (!generalData) {
			getData();
		}
	}, []);

	return (
		<>
			<div className="HomeAdmin">
				<div className="container-fluid">
					<HeaderAdmin
						title="Home"
						subtitle="Área Principal"
						icon={<FontAwesomeIcon icon={faHome} />}
					/>

					<div style={{marginTop:"-2rem"}} className="d-flex align-items-end flex-column">
						<img src="/assets/img/uelf-mark.png"/>
					</div>
					<Card className="card-principal">
						<div className="row d-flex flex-row w-100">
							<div className="circle text-center">
								<SVG src="./../assets/img/admin/circulo-home.svg" />
								<div className="" style={{position: "absolute", zIndex: "1", textAlign: "center"}}>
								<img
									src={`${process.env.REACT_APP_UELF_API}/Storage/${process.env.REACT_APP_PUBLIC_KEY}/logo/current.png`}
									onError={({ currentTarget }) => {
										currentTarget.onerror = null;
										currentTarget.src = `${process.env.REACT_APP_UELF_API}/Storage/${process.env.REACT_APP_PUBLIC_KEY}/logo/default.png`;
									}}
										alt="UELF"
										style={{width: "7rem", height: "4rem", marginTop: "-14rem", marginLeft: "3.8rem"}}
									/>
								</div>
							</div>
							<div className="text-content">
								<h1>Olá {admin?.nome ?? ""}</h1>
								<p>Olá, veja como esta a pontuação de seus colaboradores.</p>
							</div>
							<div className="ml-auto mt-auto p-3 d-flex align-items-end flex-column">
								<div>
									<Link to={"./detailed-search"}>
										<Button className="btn btn-secondary">Pesquisa detalhada</Button>
									</Link>
								</div>
							</div>
						</div>
					</Card>
					{generalData != null && (
						<>
							<Row className="cardsstatus gap-3 d-flex justify-content-center mt-3">
								<Col>
									<CardStatusDashboard
										icon={faUserGroup}
										title="Usuários Ativos"
										content={generalData["usuariosAtivos"] ?? ""}
									/>
								</Col>
								<Col>
									<CardStatusDashboard
										icon={faUserXmark}
										title="Usuários Inativos"
										content={generalData["usuariosInativos"] ?? ""}
									/>
								</Col>
								<Col>
									<CardStatusDashboard
										icon={faCheck}
										title="Jornadas Concluídas"
										content={generalData["qtdJornadasConcluidas"] ?? ""}
									/>
								</Col>
							</Row>

							<Row className="cardsstatus gap-3 d-flex justify-content-center mt-3">
								{generalData?.["qtdPioresRecursos"] &&
									Object.entries(generalData["qtdPioresRecursos"])?.map(
										(recurso: any) => {
											return (
												<Col
													role={"button"}
													onClick={() => {
														setSelectedResource(recurso?.[0]);
														setModalUserWorseResouce(true);
													}}
												>
													<CardStatusResourceDashboard
														title={recurso?.[0]}
														content={recurso?.[1]}
														imageIcon={renderIconUrl(0, recurso?.[0])}
													/>
												</Col>
											);
										},
									)}
							</Row>
						</>
					)}
					<Row className="gap-3 cardsstatus">
						<Col className="p-0">
							{generalData && (
								<Card className="card-secundario">
									<CardBody>
										<CardTitle>
											<h2>Nível de engajamento</h2>
										</CardTitle>
										<Row className="w-100">
											<Col className="d-flex align-items-center text-center">
												<span className="text-center w-100 text-engagement">
													Apenas {generalData?.dadosValuation?.percentualConcluido}% dos seus
													colaboradores cadastrados responderam o <b>UELF Valuation</b>
												</span>
											</Col>
											<Col md={6} sm={12} style={{ height: "15rem" }}>
												<GaugeChart
													nrOfLevels={1}
													colors={["#AECD58"]}
													arcWidth={0.3}
													cornerRadius={0}
													percent={generalData?.dadosValuation?.percentualConcluido / 100}
													hideText
												/>
												<h2 className="text-info">
													<b>
														{generalData?.dadosValuation?.totalConcluido} /{" "}
														{generalData?.dadosValuation?.totalPessoas}
													</b>{" "}
													<br />
													Responderam
												</h2>
											</Col>
										</Row>
									</CardBody>
								</Card>
							)}
						</Col>
						<Col>
							<Card className="card-graphic" style={{ height: "92%" }}>
								<CardBody>
									<CardTitle>
										<h2>A média das notas de cada recurso</h2>
									</CardTitle>
									{isLoading ? (
										<Loading />
									) : (
										<div className="row">
										<div className="col-md-5 col-sm-12">
											{
												subResourcesData?.dadosRecursos[0].map((subRecurso: any) => {
													return (
														<label>Nota da {subRecurso.titulo.toLowerCase().replace("dinheiro", "riqueza")} atual:  {FormatNote(subRecurso.mediaGeral, 3)}</label>
													)
												})
											}
										</div>
										<div className="col-md-7 col-sm-12">
											<ChartPerformanceAdmin subResourcesData={subResourcesData} />
											</div>
										</div>
									)}
								</CardBody>
							</Card>
						</Col>
					</Row>

					<div className="dashboard-geral">
						{isLoading ? (
							<Loading />
						) : (
							<CardResourcesPanelAdmin subResourcesData={subResourcesData} />
						)}
					</div>
				</div>
			</div>
			<ModalUserWorseResource
				show={modalUserWorseResouce}
				onClose={setModalUserWorseResouce}
				resource={selectedResource}
			/>
			<Footer />
		</>
	);
};

// @ts-ignore
export default HomeAdmin;
