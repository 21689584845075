import "./SuccessRoadmapSubObjectiveModal.scss";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useEffect, useState } from "react";
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";

import FeedbackService from "../../services/FeedbackService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import Select from "react-select";
import SuccessRoadmapService from "../../services/SuccessRoadmapService";
import { customModalStyles } from "../../models/selectConfig";
import { toastSettings } from "../../models/toastConfig";
import { useForm } from "react-hook-form";
import { useSuccessRoadmap } from "../../providers/SuccessRoadmapProvider";

interface Props {
	show: boolean;
	onClose?: any;
}

interface Inputs {
	description: string;
	target: string;
	necessaryTime: number;
	initialDate: string;
	finalDate: string;
}

const SuccessRoadmapSubObjectiveModal = ({ show, onClose }: Props) => {
	const {
		ID,
		setID,
		isUELF,
		setIsUELF,
		goalID,
		setGoalID,

		description,
		setDescription,
		target,
		setTarget,

		importance,
		setImportance,
		motivation,
		setMotivation,
		mentalEffort,
		setMentalEffort,
		physicalEffort,
		setPhysicalEffort,
		idTempo,
		setIdTempo,
		idTempoSelected,
		setIdTempoSelected,

		initialDate,
		setInitialDate,
		finalDate,
		setFinalDate,

		showModal,
		setShowModal,
	} = useSuccessRoadmap();

	const [modal, setModal] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const date = new Date();
	const dateDefaultValue =
		date.getFullYear() +
		"-" +
		("0" + (date.getMonth() + 1)).slice(-2) +
		"-" +
		("0" + date.getDate()).slice(-2);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<Inputs>();

	const toggle = () => setModal(!modal);

	const handleOnClose = () => onClose(false);

	useEffect(() => {
		if (!initialDate) {
			setInitialDate(dateDefaultValue);
		}

		if (!finalDate) {
			setFinalDate(dateDefaultValue);
		}

		if (show) {
			reset();
			setIsSubmitting(false);
			setModal(show);
			getlistTempo().then();
		}
	}, [show]);

	const createNewGoal = async (data: Inputs) => {
		if (ID) {
			if (isUELF) {
				const newSubObjective = {
					id: ID,
					idObjetivoUelf: goalID,
					descricao: data.description,
					alvo: data.target,
					importancia: importance,
					motivacao: motivation,
					esforcoMental: mentalEffort,
					esforcoFisico: physicalEffort,
					tempoNecessario: idTempoSelected,
					dataInicial: data.initialDate,
					dataFinal: data.finalDate,
				};

				try {
					setIsSubmitting(true);
					const [_Response, _Error] =
						await new SuccessRoadmapService().createSubObjectiveUELF(newSubObjective);

					if (!!_Error || !_Response?.status) {
						toastSettings(_Error || _Response?.message, "bottom-center", "error");
						setTimeout(() => setIsSubmitting(false), 1000);
						return false;
					} else {
						toastSettings(
							"Editado subtarefa com sucesso!",
							"bottom-center",
							"success",
						);
					}
				} catch (err) {
					console.warn(err);
				}
			} else {
				const newSubObjective = {
					id: ID,
					idMetasSecundarias: goalID,
					descricao: data.description,
					alvo: data.target,
					importancia: importance,
					motivacao: motivation,
					esforcoMental: mentalEffort,
					esforcoFisico: physicalEffort,
					tempoNecessario: idTempoSelected,
					dataInicial: data.initialDate,
					dataFinal: data.finalDate,
				};

				try {
					setIsSubmitting(true);
					const [_Response, _Error] =
						await new SuccessRoadmapService().createSubObjective(newSubObjective);

					if (!!_Error || !_Response?.status) {
						toastSettings(_Error || _Response?.message, "bottom-center", "error");
						setTimeout(() => setIsSubmitting(false), 1000);
						return false;
					} else {
						toastSettings(
							"Editado subtarefa com sucesso!",
							"bottom-center",
							"success",
						);
					}
				} catch (err) {
					console.warn(err);
				}
			}
		} else {
			if (isUELF) {
				const newSubObjective = {
					idObjetivoUelf: goalID,
					descricao: data.description,
					alvo: data.target,
					importancia: importance,
					motivacao: motivation,
					esforcoMental: mentalEffort,
					esforcoFisico: physicalEffort,
					tempoNecessario: idTempoSelected,
					dataInicial: data.initialDate,
					dataFinal: data.finalDate,
				};

				try {
					setIsSubmitting(true);
					const [_Response, _Error] =
						await new SuccessRoadmapService().createSubObjectiveUELF(newSubObjective);

					if (!!_Error || !_Response?.status) {
						toastSettings(_Error || _Response?.message, "bottom-center", "error");
						setTimeout(() => setIsSubmitting(false), 1000);
						return false;
					} else {
						toastSettings(
							"Adicionado subtarefa com sucesso!",
							"bottom-center",
							"success",
						);
					}
				} catch (err) {
					console.warn(err);
				}
			} else {
				const newSubObjective = {
					idMetasSecundarias: goalID,
					descricao: data.description,
					alvo: data.target,
					importancia: importance,
					motivacao: motivation,
					esforcoMental: mentalEffort,
					esforcoFisico: physicalEffort,
					tempoNecessario: idTempoSelected,
					dataInicial: data.initialDate,
					dataFinal: data.finalDate,
				};

				try {
					setIsSubmitting(true);
					const [_Response, _Error] =
						await new SuccessRoadmapService().createSubObjective(newSubObjective);

					if (!!_Error || !_Response?.status) {
						toastSettings(_Error || _Response?.message, "bottom-center", "error");
						setTimeout(() => setIsSubmitting(false), 1000);
						return false;
					} else {
						toastSettings(
							"Adicionado subtarefa com sucesso!",
							"bottom-center",
							"success",
						);
					}
				} catch (err) {
					console.warn(err);
				}
			}
		}

		toggle();
		setIsSubmitting(false);
	};

	const getlistTempo = async () => {
		try {
			const [_Response, _Error] = await new FeedbackService().statusTempoList();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			let arrayTempo = _Response.resultSet?.map((item: any) => {
				return { label: item.descricao, value: item.id };
			});

			setIdTempo(arrayTempo);
		} catch (err) {
			console.warn(err);
		}
	};

	return (
		<Modal
			centered
			scrollable
			size="lg"
			isOpen={modal}
			toggle={toggle}
			onClosed={handleOnClose}
			className="success-roadmap-sub-objective-modal"
			data-testid="SuccessRoadmapSubObjectiveModal"
		>
			<form key={1} onSubmit={handleSubmit(createNewGoal)}>
				<ModalHeader toggle={toggle}>
					{ID ? "Editar tarefa" : "Criar tarefa"}
				</ModalHeader>
				<ModalBody>
					<h2 className="title s">
						{ID
							? "Vamos abaixo editar uma tarefa."
							: "Vamos abaixo adicionar uma tarefa."}
					</h2>
					<div className="text">
						<p>Pense e preencha os campos abaixo.</p>
					</div>
					<div className="fieldset">
						<label htmlFor="description">Tarefa</label>
						<input
							type="text"
							id="description"
							defaultValue={description}
							{...register("description", { required: true })}
							maxLength={100}
						/>
						{errors.description && (
							<span className="error">Este campo é obrigatório</span>
						)}
					</div>
					{/* <div className="fieldset">
						<label htmlFor="target">
							Alvo - descreva o que valida a sua tarefa como concluída
						</label>
						<input
							type="text"
							id="target"
							defaultValue={target}
							{...register("target", { required: true })}
							maxLength={100}
						/>
						{errors.target && <span className="error">Este campo é obrigatório</span>}
					</div> */}
					{/* <div className="fieldset">
						<label htmlFor="">Qual a importancia?</label>
						<div className="stars-container">
							<ReactStars
								count={5}
								size={24}
								edit={true}
								isHalf={false}
								onChange={(ratingChanged: any) => {
									setImportance(ratingChanged);
								}}
								emptyIcon={<FontAwesomeIcon icon={faStar} />}
								halfIcon={<FontAwesomeIcon icon={faStarHalfAlt} />}
								fullIcon={<FontAwesomeIcon icon={faStar} />}
								activeColor="#99dc3b"
								value={importance}
							/>
						</div>
					</div> */}
					{/* <div className="fieldset">
						<label htmlFor="">Qual sua motivação?</label>
						<div className="stars-container">
							<ReactStars
								count={5}
								size={24}
								edit={true}
								isHalf={false}
								onChange={(ratingChanged: any) => {
									setMotivation(ratingChanged);
								}}
								emptyIcon={<FontAwesomeIcon icon={faStar} />}
								halfIcon={<FontAwesomeIcon icon={faStarHalfAlt} />}
								fullIcon={<FontAwesomeIcon icon={faStar} />}
								activeColor="#99dc3b"
								value={motivation}
							/>
						</div>
					</div>
					<div className="fieldset">
						<label htmlFor="">Quanto esforço mental é necessario?</label>
						<div className="stars-container">
							<ReactStars
								count={5}
								size={24}
								edit={true}
								isHalf={false}
								onChange={(ratingChanged: any) => {
									setMentalEffort(ratingChanged);
								}}
								emptyIcon={<FontAwesomeIcon icon={faStar} />}
								halfIcon={<FontAwesomeIcon icon={faStarHalfAlt} />}
								fullIcon={<FontAwesomeIcon icon={faStar} />}
								activeColor="#99dc3b"
								value={mentalEffort}
							/>
						</div>
					</div>
					<div className="fieldset">
						<label htmlFor="">E esforço fisico?</label>
						<div className="stars-container">
							<ReactStars
								count={5}
								size={24}
								edit={true}
								isHalf={false}
								onChange={(ratingChanged: any) => {
									setPhysicalEffort(ratingChanged);
								}}
								emptyIcon={<FontAwesomeIcon icon={faStar} />}
								halfIcon={<FontAwesomeIcon icon={faStarHalfAlt} />}
								fullIcon={<FontAwesomeIcon icon={faStar} />}
								activeColor="#99dc3b"
								value={physicalEffort}
							/>
						</div>
					</div> */}

					<div className="fieldset">
						<label htmlFor="necessaryTime">Qual o tempo necessario?</label>
						<Select
							name="necessaryTime"
							isSearchable={false}
							isClearable={false}
							options={idTempo}
							defaultValue={
								idTempo?.find((x: any) => x.value === idTempoSelected) || ""
							}
							placeholder="Selecione..."
							className={`select`}
							onChange={(val: any) => {
								setIdTempoSelected(val.value);
							}}
							noOptionsMessage={() => "Não há registros"}
							styles={customModalStyles}
							menuPosition={"fixed"}
						/>
					</div>
					<div className="fieldset">
						<label htmlFor="initial-date">Qual a data inicial dessa tarefa?</label>
						<input
							type="date"
							id="initial-date"
							defaultValue={initialDate}
							{...register("initialDate", { required: true })}
							maxLength={100}
						/>
						{errors.initialDate && (
							<span className="error">Este campo é obrigatório</span>
						)}
					</div>
					<div className="fieldset">
						<label htmlFor="final-date">E a data final?</label>
						<input
							type="date"
							id="final-date"
							defaultValue={finalDate}
							{...register("finalDate", { required: true })}
							maxLength={100}
						/>
						{errors.finalDate && (
							<span className="error">Este campo é obrigatório</span>
						)}
					</div>
				</ModalBody>
				<ModalFooter>
					<Button type="button" disabled={isSubmitting} onClick={toggle}>
						Cancelar
					</Button>
					<Button type="submit" disabled={isSubmitting} color="primary">
						{ID ? "Editar tarefa" : "Criar tarefa"}
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default SuccessRoadmapSubObjectiveModal;
