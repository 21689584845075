import "./Performance.scss";

import { Button, Card, CardBody, CardTitle, Row } from "reactstrap";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CardGrades from "../../components/JourneyStatistics/CardGrades/CardGrades";
import CardGradesResources from "../../components/JourneyStatistics/CardGradesResources/CardGradesResources";
import ChartPerformance from "../../components/ChartPerformance/ChartPerformance";
import DiagnoseService from "../../services/DiagnoseService";
import Loading from "../../components/Loading/Loading";
import ModalGradesSub from "../../components/ModalGradesSub/ModalGradesSub";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import { ToastContainer } from "react-toastify";
import { toastSettings } from "../../models/toastConfig";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";
import { useReactToPrint } from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import DashboardResourceScorePrint from "../../components/DashboardResourceScorePrint/DashboardResourceScorePrint";
import { Link } from "react-router-dom";
import { useConfiguration } from "../../providers/ConfigurationProvider";
import moment from "moment";
import GaugeChart from "react-gauge-chart";
import { useDashboard } from "../../providers/DashboardProvider";
import "../PrintPerformance/PrintPerformance.scss";
import FormatNote from "../../utils/FormatNote";

export interface IData {
	grafico: any[any];
	nome: string;
	objetivos: any;
	informacaoPiorSubRecurso: any;
	notasRecursos: any[];
	pontuacao: string;
	fraseNotaVida: string;
	status: string;
	recursos: [];
}

const Performance = () => {
	const [data, setData] = useState<IData>();
	const [print, setPrint] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { dataConfig } = useConfiguration();
	const { activeJourney } = useActiveJourney();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (location.pathname !== "/login") {
			setIsLoading(true);
			handleList().then();
		}
	}, [activeJourney]);

	const handleList = async () => {
		try {
			const [_Response, _Error] = await new DiagnoseService().listProvisorio();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				setIsLoading(false);
				return;
			}

			setData(_Response.resultSet);

			setIsLoading(false);
		} catch (err) {
			console.warn(err);
		}
	};

	const render = (data: IData) => {
		return (
			<div className="diagnose">
				<div className="d-flex flex-column justify-content-end my-3 text-right">
					<Button className="action-print ml-auto" onClick={() => changePrint()}>
						Imprimir / PDF
					</Button>

					{data?.grafico?.jornadaAtual?.[0]?.dataConclusao && (
						<p className="mt-3">
							Esse resultado corresponde a data de:{" "}
							{moment(data?.grafico?.jornadaAtual?.[0]?.dataConclusao).format(
								"DD/MM/YYYY",
							)}
						</p>
					)}
				</div>
				<div className="d-flex" style={{ gap: "1rem" }}>
					<div className="w-100">
						{data?.notasRecursos && data?.notasRecursos.length > 0 ? (
							<Card>
								<CardTitle>
									<h1 className="title">As notas dos seus recursos</h1>
								</CardTitle>
								<CardBody>
									<ChartPerformance items={data?.grafico} />
								</CardBody>
							</Card>
						) : (
							<Card className="card-inverse">
								<div className="items-wrapper jcenter">
									<p className="text-center h4 text-white my-5">
										{!activeJourney ? "Não há" : ""} Jornada em Andamento
									</p>
								</div>
							</Card>
						)}
					</div>
					<div className="w-100">
						<Card>
							<CardTitle>
								<h1 className="title">Meu sonho</h1>
							</CardTitle>
							<CardBody>
								<div className="my-dream d-flex justify-content-between">
									<div className="my-dream-texts">
										<div>
											<h2>
												Palavra que me define:{" "}
												{
													data?.objetivos?.questao?.[0]?.alternativas?.[0]?.respostas?.[0]
														?.resposta
												}
											</h2>
										</div>
										<div>
											<h2>
												Objetivo de vida:{" "}
												{
													data?.objetivos?.questao?.[1]?.alternativas?.[0]?.respostas?.[0]
														?.resposta
												}
											</h2>
										</div>
										<div>
											<h2>
												Como me sinto:{" "}
												{
													data?.objetivos?.questao?.[2]?.alternativas?.[0]?.respostas?.[0]
														?.resposta
												}
											</h2>
										</div>
									</div>
									<div className="my-dream-img-container">
										<img
											src={
												data?.objetivos?.questao?.[4]?.alternativas?.[0]?.respostas?.[0]
													?.caminhoHttp
													? process.env.REACT_APP_UELF_API +
													  "/" +
													  data?.objetivos?.questao?.[4]?.alternativas?.[0]?.respostas?.[0]
															?.caminhoHttp
													: "assets/img/my-dream.png"
											}
											alt=""
										/>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>

				<div className="row">
					<div
						className={`col col-12 p-0 resource-notes ${
							data?.notasRecursos && data?.notasRecursos.length > 0 ? "h-100" : ""
						}`}
					>
						<CardGradesResources
							data={
								data?.notasRecursos && data?.notasRecursos.length > 0
									? data.notasRecursos
									: []
							}
							page={"performance"}
							cardscore={
								<div className="CardScore2 wrapper">
									<CardGrades
										pontuacao={data?.pontuacao}
										fraseNotaVida={data?.fraseNotaVida}
										status={data?.status}
									/>
								</div>
							}
							titleTutorial="Entenda os caminhos que você pode seguir a partir de agora"
							informacaoPiorSubRecurso={data?.informacaoPiorSubRecurso}
							videoTutorial={`${dataConfig?.video_seu_resultado}`}
						/>
					</div>
				</div>
			</div>
		);
	};

	const componentRefPrint = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRefPrint.current,
		onAfterPrint() {
			setPrint(false);
		},
	});

	const changePrint = () => {
		setPrint(true);
		setTimeout(() => handlePrint(), 1000);
	};

	const { renderIcon, renderBgIcon, theme, renderBgIconHex } = useDashboard();

	const PrintPerformance = (data: IData) => {
		return (
			<div
				id="printArea"
				ref={componentRefPrint}
				className={print ? "d-block" : "d-none"}
			>
				<div
					className={`${
						theme == "dark" ? "PrintPerformance" : "PrintPerformanceLight"
					} m-3 PrintPerformanceBloc`}
					ref={componentRefPrint}
				>
					<hr />
					<Row>
						<div className="col">
							<div className="nome">Olá, {data.nome}! :)</div>
							<div className="descricao">
								As suas notas estão nos cards relacionados aos sentimentos{" "}
							</div>
						</div>
						<div className="col text-right d-flex flex-column mb-4">
							<div>
								<img
									className="logo"
									src={
										theme == "dark"
											? "./assets/img/pag.png"
											: "./assets/img/pag-light.png"
									}
								/>
							</div>
							<div className="w-100 mt-4">
								<span className="descricao">
									Esse resultado corresponde a data de: 12/02/2023
								</span>
							</div>
						</div>
					</Row>
					<Row>
						<div className="col">
							<div className="card" style={{ height: "320px" }}>
								<div className="card-title">Meu sonho</div>

								<div className="row m-0">
									<div className="col p-0">
										<div className="items-my-dream">
											<span>
												<b>Palavra que me define:</b>{" "}
												{
													data?.objetivos?.questao?.[0]?.alternativas?.[0]?.respostas?.[0]
														?.resposta
												}
											</span>
											<span>
												<b>Objetivo de vida:</b>{" "}
												{
													data?.objetivos?.questao?.[1]?.alternativas?.[0]?.respostas?.[0]
														?.resposta
												}
											</span>
											<span>
												<b>Como me sinto:</b>{" "}
												{
													data?.objetivos?.questao?.[2]?.alternativas?.[0]?.respostas?.[0]
														?.resposta
												}
											</span>
										</div>
									</div>
									<div className="col">
										<img
											className="image-my-dream"
											src={
												data?.objetivos?.questao?.[4]?.alternativas?.[0]?.respostas?.[0]
													?.caminhoHttp
													? process.env.REACT_APP_UELF_API +
													  "/" +
													  data?.objetivos?.questao?.[4]?.alternativas?.[0]?.respostas?.[0]
															?.caminhoHttp
													: "assets/img/my-dream.png"
											}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="col">
							<div className="card card-nota-final" style={{ height: "320px" }}>
								<div className="card-title">Sua nota final</div>
								<div className="w-100 d-flex justify-content-center">
									<div className="chart-wrapper">
										<div className="chart-label">
											<div>
												<GaugeChart
													className="gauge-chart"
													nrOfLevels={5}
													colors={["#A8E297", "#77B974", "#59A632", "#498A28", "#2C671A"]}
													arcWidth={0.5}
													percent={parseInt(data?.pontuacao) / 100000}
													hideText
													cornerRadius={0}
													needleBaseColor="#fff"
													needleColor="#262626"
												/>
												<div className="text-center w-100">
													<span className="status">
														<b>{parseInt(data?.pontuacao).toLocaleString("pt-BR")}</b> /
														100.000 - {data?.status}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Row>
					<div className="card card-pior-recurso">
						<div className="row">
							<div className="col-9">
								<div className="pior-recurso d-flex justify-content-between">
									<div className="d-flex flex-row">
										<div className="image">
											{renderIcon(0, data.informacaoPiorSubRecurso.piorSubRecurso.recurso)}
										</div>
										<div>
											<h1
												className="title"
												style={{
													color: renderBgIconHex(
														data.informacaoPiorSubRecurso.piorSubRecurso.recurso,
													),
												}}
											>
												{data.informacaoPiorSubRecurso.piorSubRecurso.recurso}
											</h1>
											<h2 className="subtitle">SUB-RECURSOS</h2>
										</div>
									</div>
									<div>
										<span className="nota">
											{FormatNote(
												data.informacaoPiorSubRecurso.piorSubRecurso.pontuacao,
												2,
											)}
										</span>
									</div>
								</div>
								<div className="row lista-evitar-fazer">
									<div className="col">
										<div className="card">
											<img src="/assets/img/print/fazer.svg" />
											<ul>
												{data.informacaoPiorSubRecurso?.listaEvitarFazer.map(
													(item: any) => {
														if (item?.idEtapa === 4) return <li>{item?.resposta}</li>;
													},
												)}
											</ul>
										</div>
									</div>

									<div className="col">
										<div className="card card-lista">
											<img src="/assets/img/print/evitar.svg" />
											<ul>
												{data.informacaoPiorSubRecurso?.listaEvitarFazer.map(
													(item: any) => {
														if (item?.idEtapa === 3) return <li>{item?.resposta}</li>;
													},
												)}
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className="col col-pior-sub-recurso">
								{data.recursos
									.filter(
										(item: any) =>
											item.descricaoRecurso ==
											data.informacaoPiorSubRecurso.piorSubRecurso.recurso,
									)
									.map((item: any) => {
										return item.subRecursos.map((subRecurso: any) => {
											return (
												<div className="card card-sub-recurso">
													<h1 className="title">{subRecurso.descricaoSubRecurso}</h1>
													<div className="circle">{FormatNote(subRecurso.pontuacao, 2)}</div>
												</div>
											);
										});
									})}
							</div>
						</div>
					</div>

					<div className="row">
						{data.recursos
							.filter(
								(item: any) =>
									item.descricaoRecurso !=
									data.informacaoPiorSubRecurso.piorSubRecurso.recurso,
							)
							.map((item: any) => {
								return (
									<div className="col-md-6 col-sm-12 ">
										<div className="card card-sub-recurso">
											<div className="header d-flex justify-content-between">
												<div className="d-flex flex-row">
													<div className="image">{renderIcon(0, item.descricaoRecurso)}</div>
													<div>
														<h1 className="title">
															<span
																style={{ color: `${renderBgIconHex(item.descricaoRecurso)}` }}
															>
																{item.descricaoRecurso}
															</span>
														</h1>
														<h2 className="subtitle">SUB-RECURSOS</h2>
													</div>
												</div>
												<div>
													<span className="nota">2,66</span>
												</div>
											</div>
											<div className="notas row">
												{item.subRecursos.map((subRecurso: any) => {
													return (
														<div className="col-sm-12 col-md-6">
															<div className="card m-0 mt-4">
																<div className="conteudo row">
																	<div className="col-7 card-title title-nota">
																		{subRecurso.descricaoSubRecurso}
																	</div>
																	<div className="col nota">
																		{FormatNote(subRecurso.pontuacao, 1)}
																	</div>

																	{item.subRecursos.filter(
																		(item: any) => item.pontuacao > subRecurso.pontuacao,
																	).length == 0 && (
																		<div className="estrela">
																			<img
																				src={`${process.env.PUBLIC_URL}/assets/img/star.png`}
																				alt=""
																			/>
																		</div>
																	)}
																</div>
															</div>
														</div>
													);
												})}
											</div>
										</div>
									</div>
								);
							})}
					</div>
					<div className="w-100 text-center mt-4">
						<span style={{ color: "#43433B" }}>
							Zeneconomics 2022 - Todos os direitos reservados
						</span>
					</div>
				</div>
			</div>
		);
	};

	return (
		<PainelMasterPage
			title={"Seu Resultado"}
			subtitle={"Clique nos recursos para ver o detalhamento das notas"}
			subtitleclass="text-white"
			icon={<></>}
			onClick={() => {}}
		>
			<div className="your-result">
				{!isLoading ? <>{data && render(data)}</> : <Loading margin="my-6" />}
			</div>

			<div className="d-flex justify-content-end gap-3">
				<div>
					<div className="navigate">
						<Link to={"/home"} style={{ display: "contents" }}>
							<Button>
								<span>Início</span>
							</Button>
						</Link>
					</div>
				</div>
				<div className="ml-3">
					<div className="navigate">
						<Button
							onClick={() => {
								navigate("/journey-map");
							}}
						>
							<span>Próximos Passos</span>
						</Button>
					</div>
				</div>
			</div>

			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			<ModalGradesSub />

			{data && PrintPerformance(data)}
		</PainelMasterPage>
	);
};

export default Performance;
