import "./HeaderPage.scss";

import { Col, Container, Row } from "reactstrap";
import React, { MouseEventHandler, useState } from "react";
import ReactPixel from "react-facebook-pixel";

import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import ModalHelper from "../ModalHelper/ModalHelper";
import { Tooltip } from "react-tooltip";

interface Props {
	title: string;
	subtitle: string;
	titleclass: string;
	subtitleclass: string;
	icon: JSX.Element;
	onClick: MouseEventHandler<HTMLButtonElement>;
	helpText?: string;
}

const HeaderPage = ({
	title,
	subtitle = "",
	titleclass = "",
	subtitleclass = "",
	icon = <></>,
	onClick,
	helpText,
}: Props) => {
	ReactPixel.init("744591990099086");
	ReactPixel.pageView();
	ReactPixel.track("track");

	const [modalHelper, setModalHelper] = useState<boolean>(false);

	return (
		<>
			<div className="header">
				<Container fluid>
					<div className="header-body">
						<div>
							<h1 className="title">
								{icon}
								{title}
								{helpText && (
									<div className="helper">
										<FontAwesomeIcon
											icon={faCircleQuestion}
											// onClick={() => setModalHelper(true)}
											data-tooltip-html={helpText}
											data-tooltip-id="header-page-tooltip"
										/>
									</div>
								)}
							</h1>
							{ title.toLowerCase() !== "uelf evolution" && <div className="text">
								<p>{subtitle}</p>
							</div>} 
						</div>
						{title.toLowerCase() !== "home" && title.toLowerCase() !== "uelf evolution" && title.toLowerCase() !== "faq" && <Breadcrumb namePage={title} />}
					</div>
				</Container>
			</div>
			{/* <ModalHelper
				show={modalHelper}
				helpText={helpText}
				onClose={setModalHelper}
			/> */}

			<Tooltip
				id="header-page-tooltip"
				className="adp-tooltip--component"
			></Tooltip>
		</>
	);
};

export default HeaderPage;
