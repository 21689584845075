import "./ConfigurationAdmin.scss";

import { useEffect, useState, FC, useRef } from "react";
import Footer from "../../components/Footer/Footer";
import HeaderAdmin from "../../components/HeaderAdmin/HeaderAdmin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import AdminService from "../../services/AdminService";
import { toastSettings } from "../../models/toastConfig";
import { Button, Col, Input, Row } from "reactstrap";
import Loading from "../../components/Loading/Loading";
import { ToastContainer } from "react-toastify";

const ConfigurationAdmin: FC = () => {
	const [isLoading, setLoading] = useState<boolean>(false);
	const inputImageRef = useRef<any>(null);

	const [videoBoasVindas, setVideoBoasVindas] = useState<string>("");
	const [videoEvolution, setVideoEvolution] = useState<string>("");
	const [videoPontosAtencao, setVideoPontosAtencao] = useState<string>("");
	const [videoSeuResultado, setVideoSeuResultado] = useState<string>("");
	const [videoEducation, setVideoEducation] = useState<string>("");
	const [emailRh, setEmailRh] = useState<string>("");

	const handleImageClick = () => {
		inputImageRef.current.click();
	};

	const onImageChange = async (event: any) => {
		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0];
			setLoading(true);
			const result = (await AdminService.editLogo(img))[0];
			setLoading(false);
			if (result?.status) {
				toastSettings(
					"Logotipo atualizado com sucesso!",
					"bottom-center",
					"success",
				);
			}
		}
	};

	const update = async () => {
		const data = {
			video_boas_vindas: videoBoasVindas,
			video_evolution: videoEvolution,
			video_pontos_atencao: videoPontosAtencao,
			video_seu_resultado: videoSeuResultado,
			video_education: videoEducation,
			email_rh: emailRh,
		};
		setLoading(true);

		const [_Response, _Error]: any = await AdminService.updateConfigurations(
			data,
		);

		if (_Response?.status)
			toastSettings(
				"Configurações atualizadas com sucesso",
				"bottom-center",
				"success",
			);

		setLoading(false);
	};

	const [configData, setConfigData] = useState<any>(null);

	useEffect(() => {
		if (!configData) {
			getData();
		}
	}, [configData]);

	const getData = () => {
		setLoading(true);
		AdminService.listConfigurations()
			.then((data) => {
				const [_Response, _Error] = data;
				if (_Response?.status && !_Error) {
					var objects = _Response?.resultSet.reduce(
						(obj: any, item: any) => Object.assign(obj, { [item.chave]: item.valor }),
						{},
					);

					setVideoBoasVindas(objects.video_boas_vindas);
					setVideoEvolution(objects.video_evolution);
					setVideoPontosAtencao(objects.video_pontos_atencao);
					setVideoSeuResultado(objects.video_seu_resultado);
					setVideoEducation(objects.video_education);
					setEmailRh(objects.email_rh);

					setConfigData(objects);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<div className="ConfigurationAdmin">
				<div className="container-fluid">
					<HeaderAdmin
						title="Configuração"
						subtitle=""
						icon={<FontAwesomeIcon icon={faGear} />}
					/>

					<div className="mt-5">
						<Row>
							<Col md={3}>
								<div className="card p-3">
									<div className="menu">
										<h2 role={"button"}>Aparência</h2>
									</div>
								</div>
							</Col>
							<Col md={9}>
								<div className="card p-3">
									<Row>
										<Col xs={12} md={3} className="p-0 m-0">
											<h2>Logotipo</h2>
										</Col>
										<Col xs={12} md={8}>
											<Button className="btn-secondary" onClick={handleImageClick}>
												Importar Arquivo
											</Button>
											<input
												ref={inputImageRef}
												style={{ display: "none" }}
												type="file"
												onChange={onImageChange}
												accept="image/png, image/jpeg"
											/>
										</Col>
									</Row>
									<div className="mt-3"></div>
									<Row>
										<Col xs={12} md={3} className="p-0 m-0">
											<h2>Vídeo de Boas-Vindas</h2>
										</Col>
										<Col xs={12} md={8} className="input-text-row">
											<input
												type="text"
												placeholder="URL do Youtube"
												value={videoBoasVindas}
												onChange={(e) => setVideoBoasVindas(e.target.value)}
											/>
										</Col>
									</Row>
									<div className="mt-3"></div>
									<Row>
										<Col xs={12} md={3} className="p-0 m-0">
											<h2>Vídeo do Evolution</h2>
										</Col>
										<Col xs={12} md={8} className="input-text-row">
											<input
												type="text"
												placeholder="URL do Youtube"
												value={videoEvolution}
												onChange={(e) => setVideoEvolution(e.target.value)}
											/>
										</Col>
									</Row>
									<div className="mt-3"></div>
									<Row>
										<Col xs={12} md={3} className="p-0 m-0">
											<h2>Vídeo dos Pontos de Atenção</h2>
										</Col>
										<Col xs={12} md={8} className="input-text-row">
											<input
												type="text"
												placeholder="URL do Youtube"
												value={videoPontosAtencao}
												onChange={(e) => setVideoPontosAtencao(e.target.value)}
											/>
										</Col>
									</Row>
									<div className="mt-3"></div>
									<Row>
										<Col xs={12} md={3} className="p-0 m-0">
											<h2>Vídeo do Seu Resultado</h2>
										</Col>
										<Col xs={12} md={8} className="input-text-row">
											<input
												type="text"
												placeholder="URL do Youtube"
												value={videoSeuResultado}
												onChange={(e) => setVideoSeuResultado(e.target.value)}
											/>
										</Col>
									</Row>
									<div className="mt-3"></div>
									<Row>
										<Col xs={12} md={3} className="p-0 m-0">
											<h2>Vídeo do Education</h2>
										</Col>
										<Col xs={12} md={8} className="input-text-row">
											<input
												type="text"
												className="fieldset"
												placeholder="URL do Youtube"
												value={videoEducation}
												onChange={(e) => setVideoEducation(e.target.value)}
											/>
										</Col>
									</Row>
									<div className="mt-3"></div>
									<Row>
										<Col xs={12} md={3} className="p-0 m-0">
											<h2>E-mail do RH</h2>
										</Col>
										<Col xs={12} md={8} className="input-text-row">
											<input
												type="email"
												className="fieldset"
												placeholder="E-mail"
												value={emailRh}
												onChange={(e) => setEmailRh(e.target.value)}
											/>
										</Col>

										<Button className="btn-secondary mt-3" onClick={update}>
											Salvar Informações
										</Button>
									</Row>

									{isLoading && <Loading />}
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
			<Footer />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};

// @ts-ignore
export default ConfigurationAdmin;
