import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./ModalAcceptTermsStartJorney.scss";

interface ModalAcceptTermsStartJorneyProps {
	show: boolean;
	onClose?: any;
	onAccept?: any;
}

const ModalAcceptTermsStartJorney = ({
	show,
	onClose,
	onAccept,
}: ModalAcceptTermsStartJorneyProps) => {
	const [modal, setModal] = useState<boolean>(false);

	const toggle = () => setModal(!modal);
	const handleOnClose = () => onClose(false);

	useEffect(() => {
		if (show) {
			setModal(show);
		}
	}, [show]);

	return (
		<Modal
			isOpen={modal}
			toggle={toggle}
			className="ModalAcceptTermsStartJorney"
			data-testid="ModalAcceptTermsStartJorney"
			onClosed={handleOnClose}
			size="md"
			centered
		>
			<ModalHeader>Dados sigilosos</ModalHeader>
			<ModalBody className="d-flex p-0">
				<p>
					<b>
						Termo de esclarecimento sobre a privacidade das informações pessoais:
					</b>
				</p>
				<p>
					Por favor, note que todas as informações pessoais fornecidas por você serão
					mantidas em sigilo e utilizadas apenas para fins internos de coleta de
					dados. Essas informações não serão compartilhadas com terceiros sem sua
					permissão explícita.
				</p>
				<p>
					Além disso, todas as informações coletadas serão utilizadas apenas de forma
					agregada e anônima, para fins estatísticos e de análise de dados. Nenhuma
					informação individual será divulgada ou utilizada de forma a identificar
					você especificamente.
				</p>
				<p>
					Nós valorizamos sua privacidade e garantimos que todas as informações
					fornecidas serão tratadas com o máximo cuidado e segurança. Se você tiver
					alguma dúvida ou preocupação sobre o uso de suas informações pessoais, por
					favor entre em contato conosco. (email: contato@uelf.com.br).
				</p>
			</ModalBody>
			<ModalFooter>
				<Button className="btn" onClick={onAccept}>
					Aceitar
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ModalAcceptTermsStartJorney;
