import FormataData from "../../utils/FormataDate";
import "./DashboardTrackScore.scss";

const DashboardTrackScore = ({ data, piorRecurso, porcentagemTrilha }: any) => {
	const renderStatus = (status: string) => {
		switch (status) {
			case "COMPLETED":
				return "Concluído";
			case "NOT_STARTED":
				return "Não iniciado";
			default:
				return "Em andamento";
		}
	};

	return (
		<div className="DashboardTrackScore card" data-testid="DashboardTrackScore">
			<div className="list-wrapper">
				<div className="title-wrapper">
					<div>
						<span className="title">Education - Trilhas de aprendizado</span>
					</div>
				</div>

				<div className="card card-trails p-4 px-5">
					<div className="img-sub">
						<img src={`assets/img/trilhas/${piorRecurso?.idSubRecurso ?? 0}.jpg`} />
					</div>
					<div>
						<div className="description">
							<h1 className="title">
								<b>Nome da trilha:</b> {piorRecurso?.descricao}
							</h1>
							<div className="progressbar mt-5">
								<progress value={porcentagemTrilha} max="100">
									{porcentagemTrilha} %
								</progress>
								<div className="d-flex justify-content-around">
									<h2 className="subtitle">
										{porcentagemTrilha == 100
											? "Trilha completada"
											: "Trilha em andamento"}
									</h2>
									<h2 className="subtitle text-right">
										{isNaN(porcentagemTrilha) ? 0 : porcentagemTrilha}%
									</h2>
								</div>
							</div>
						</div>
					</div>

					{/* <div>
						{data.cursos.map((item: any, index: number) => (
							// <div className="wrapper" key={index}>
							// 	<div className={item.status == "COMPLETED" ? "item completed" : "item"}>
							// 		<div>
							// 			<div>
							// 				<span className="title">Nome da trilha: </span>
							// 				<span>{item.course_title}</span>
							// 			</div>
							// 			<div>
							// 				<span className="title">Data de conclusão: </span>
							// 				<span>
							// 					{item.complete_date != null && FormataData(item.complete_date)}
							// 				</span>
							// 			</div>
							// 		</div>
							// 		<div>
							// 			<span className="title">Status: </span>
							// 			<span>
							// 				{renderStatus(item.course_status)}{" "}
							// 				{item.course_status == "COMPLETED" && (
							// 					<img
							// 						src={`${process.env.PUBLIC_URL}/assets/img/check.svg`}
							// 						alt=""
							// 					/>
							// 				)}
							// 			</span>
							// 		</div>
							// 	</div>
							// </div>
						))} 
					</div> */}
				</div>
			</div>
			<div className="note-wrapper">
				<div className="note">
					<span className="title">{data.notaAluno}</span>
					<span className="subtitle">Pontos</span>
				</div>
			</div>
		</div>
	);
};

export default DashboardTrackScore;
