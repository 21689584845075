import "./PerformanceAdmin.scss";

import { Card, CardBody, CardTitle } from "reactstrap";
import { useEffect, useState } from "react";
import CardGrades from "../../components/JourneyStatistics/CardGrades/CardGrades";
import CardGradesResources from "../../components/JourneyStatistics/CardGradesResources/CardGradesResources";
import ChartPerformance from "../../components/ChartPerformance/ChartPerformance";
import Loading from "../../components/Loading/Loading";
import ModalGradesSub from "../../components/ModalGradesSub/ModalGradesSub";
import { ToastContainer } from "react-toastify";
import { toastSettings } from "../../models/toastConfig";
import AdminService from "../../services/AdminService";
import HeaderAdmin from "../../components/HeaderAdmin/HeaderAdmin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";

export interface IData {
	grafico: any[];
	informacaoPiorSubRecurso: any;
	notasRecursos: any[];
	pontuacao: string;
	fraseNotaVida: string;
	status: string;
	pessoa: any[any];
	objetivos: any[any];
}

const PerformanceAdmin = () => {
	const [data, setData] = useState<IData>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { userId } = useParams();

	useEffect(() => {
		if (!data) {
			setIsLoading(true);
			handleList().then();
		}
	}, [data]);

	const handleList = async () => {
		try {
			const [_Response, _Error] = await AdminService.diagnose(
				parseInt(userId || "0"),
			);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				setIsLoading(false);
				return;
			}

			setData(_Response.resultSet);

			setIsLoading(false);
		} catch (err) {
			console.warn(err);
		}
	};

	const render = (data: IData) => {
		return (
			<div className="diagnose">
				<div className="d-flex" style={{ gap: "1rem" }}>
					<div className="w-100">
						<Card>
							<CardTitle>
								<h1 className="title">As notas dos seus recursos</h1>
							</CardTitle>
							<CardBody>
								<ChartPerformance items={data?.grafico} />
							</CardBody>
						</Card>
					</div>
					<div className="w-100">
						<Card>
							<CardTitle>
								<h1 className="title">Meu sonho</h1>
							</CardTitle>
							<CardBody>
								<div className="my-dream d-flex justify-content-between">
									<div className="my-dream-texts">
										<div>
											<h2>
												Palavra que me define:{" "}
												{
													data?.objetivos?.questao?.[0]?.alternativas?.[0]?.respostas?.[0]
														?.resposta
												}
											</h2>
										</div>
										<div>
											<h2>
												Objetivo de vida:{" "}
												{
													data?.objetivos?.questao?.[1]?.alternativas?.[0]?.respostas?.[0]
														?.resposta
												}
											</h2>
										</div>
										<div>
											<h2>
												Como me sinto:{" "}
												{
													data?.objetivos?.questao?.[2]?.alternativas?.[0]?.respostas?.[0]
														?.resposta
												}
											</h2>
										</div>
									</div>
									<div className="my-dream-img-container">
										<img
											src={
												data?.objetivos?.questao?.[4]?.alternativas?.[0]?.respostas?.[0]
													?.caminhoHttp
													? process.env.REACT_APP_UELF_API +
													  "/" +
													  data?.objetivos?.questao?.[4]?.alternativas?.[0]?.respostas?.[0]
															?.caminhoHttp
													: "/assets/img/my-dream.png"
											}
											alt=""
										/>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>

				<div className="row">
					<div
						className={`col col-12 resource-notes ${
							data?.notasRecursos && data?.notasRecursos.length > 0 ? "h-100" : ""
						}`}
					>
						<CardGradesResources
							data={
								data?.notasRecursos && data?.notasRecursos.length > 0
									? data.notasRecursos
									: []
							}
							page={"performance"}
							cardscore={
								<div className="col p-0">
									<CardGrades
										pontuacao={data?.pontuacao}
										fraseNotaVida={data?.fraseNotaVida}
										status={data?.status}
									/>
								</div>
							}
							titleTutorial=""
							informacaoPiorSubRecurso={data?.informacaoPiorSubRecurso}
							videoTutorial=""
							showVideo={false}
						/>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div>
			<div className="PerformanceAdmin">
				<div className="container-fluid">
					<HeaderAdmin
						title={
							data
								? `Resultado de ${data?.pessoa?.nomeRazaoSocial || "Ninguém"}`
								: "Resultado de ..."
						}
						subtitle="Clique nos recursos para ver o detalhamento das notas"
						icon={<FontAwesomeIcon icon={faSearch} />}
					/>
					<div className="your-result">
						{!isLoading ? <>{data && render(data)}</> : <Loading margin="my-6" />}
					</div>
				</div>
			</div>

			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			<ModalGradesSub isAdminDashboard />
		</div>
	);
};

export default PerformanceAdmin;
