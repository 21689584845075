import "./Home.scss";
import "swiper/css";

import { Button, Col, Row } from "reactstrap";
import { Chart, Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import MenuRoute from "../../routes/MenuRoute";
import ModalNews from "../../components/ModalNews/ModalNews";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import VideoModal from "../../components/VideoModal/VideoModal";
import annotationPlugin from "chartjs-plugin-annotation";
import {
	faCircleInfo,
	faCircleQuestion,
	faHome,
	faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";
import { useAuth } from "../../providers/AuthProvider";
import { useDashboard } from "../../providers/DashboardProvider";
import { useMenu } from "../../providers/MenuProvider";
import GoogleTagManager, { EventEnum } from "../../services/GoogleTagManager";
import ModalChangePassword from "../../components/ModalChangePassword/ModalChangePassword";
import { ToastContainer } from "react-toastify";
import CardResourceHome from "../../components/CardResourceHome/CardResourceHome";
import ModalAcceptTermsStartJorney from "../../components/ModalAcceptTermsStartJorney/ModalAcceptTermsStartJorney";
import { useConfiguration } from "../../providers/ConfigurationProvider";
import { Tooltip } from "react-tooltip";
import moment from "moment";

// import "plyr-react/dist/plyr.css";

const Home = () => {
	const [activeMenu, setActiveMenu] = useState<any>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [showModalNews, setShowModalNews] = useState<boolean>(false);
	const { chartJornadas, renderIcon } = useDashboard();
	const [showModalVideo, setShowModalVideo] = useState<boolean>(false);
	const [showModalAcceptTermsStartJorney, setShowModalAcceptTermsStartJorney] =
		useState<boolean>(false);

	const chartRef = React.useRef<any>(null);
	Chart.register(zoomPlugin);

	const { rules } = useMenu();
	const { activeJourney, startJourney, dataUser, finishJourney } =
		useActiveJourney();
	const { slide, setSlide, firstLogin } = useAuth();
	const { dataConfig } = useConfiguration();

	const ruleStepJorney = async () => {
		for (var i = 0; i < rules.length; i++) {
			if (!rules[i].status && rules[i].nome !== "diagnose") {
				switch (rules[i].nome) {
					case "purpose":
						setActiveMenu(MenuRoute[i]);
						break;
					case "survey":
						setActiveMenu(MenuRoute[i]);
						break;
					case "survey-finish":
						setActiveMenu(MenuRoute[i]);
						break;
					case "todoavoid":
						setActiveMenu(MenuRoute[i]);
						break;
					case "goals":
						setActiveMenu(MenuRoute[i]);
						break;
					case "education":
						setActiveMenu(MenuRoute[i]);
						break;
					case "evolution":
						setActiveMenu(MenuRoute[i]);
						break;
					case "performance":
						setActiveMenu(MenuRoute[i]);
						break;
					default:
						break;
				}

				return;
			}
		}
	};

	const resetZoomDashboardGraph = () => {
		if (chartRef && chartRef.current) {
			chartRef.current.resetZoom();
		}
	};

	const validateRoute = (obj: any, plano: number) => {
		let menus: any = ["education", "evolution"];

		if (dataUser.idPlano <= 1 && menus.includes(obj?.icon.toLowerCase())) {
			return "/performance";
		}

		return obj?.layout;
	};

	useEffect(() => {
		GoogleTagManager.event(EventEnum.Marketing, {
			action: dataUser?.jornadasConcluidas ? "home_grafico" : "home_boas_vindas",
		});
		if (rules) {
			if (activeJourney != null) {
				if (activeJourney > 0) {
					ruleStepJorney().then();
				} else {
					setActiveMenu(MenuRoute[0]);
				}
			}
		}
	}, [activeJourney, rules]);

	useEffect(() => {
		if (activeMenu) {
			setIsLoading(false);
		}
	}, [activeMenu]);

	useEffect(() => {
		finishJourney().then();
	}, []);

	Chart.register(annotationPlugin);

	return (
		<>
			<ModalChangePassword show={firstLogin} />
			<PainelMasterPage
				title={"Home"}
				subtitle={""}
				subtitleclass={""}
				icon={<FontAwesomeIcon icon={faHome} />}
				onClick={() => {}}
			>
				<div className="homepage">
					<div className="card">
						<Row className="justify-content-center">
							<div className="col-md-9 align-items-center d-flex">
								<div className="text-content">
									<h2 className="title mb-1">Olá {dataUser?.usuario?.nome}</h2>
									<div className="text">
										{dataUser?.jornadasConcluidas?.length > 0 ||
										dataUser?.jornadaEmAndamento ? (
											<p className="mb-0">
												Olá, que bom que você voltou. Caso ainda tenha alguma dúvida sobre o
												funcionamento do UELF clique em ”Assistir vídeo de boas vindas”,
												caso queira continuar de onde você parou, clique em ”próximos
												passos”.
											</p>
										) : (
											<p className="mb-0">
												Estamos muito felizes em te ver por aqui. Temos certeza de que você
												acaba de tomar uma excelente decisão rumo ao seu sucesso pessoal.
												Assista ao nosso vídeo de boas vindas e saiba como iniciar a sua
												experiência UELF.
											</p>
										)}
									</div>
								</div>
							</div>
							<div className="col d-flex align-items-end flex-column">
								{!isLoading && (
									<>
										{!dataUser?.jornadaEmAndamento &&
										dataUser?.jornadasConcluidas?.length ? (
											<div className="mt-1">
												<Link to="/performance" style={{ display: "contents" }}>
													<Button>
														<span>Seu último resultado</span>
													</Button>
												</Link>
											</div>
										) : (
											""
										)}
										{!dataUser?.jornadaEmAndamento &&
										dataUser?.jornadasConcluidas?.length ? (
											<div className="mt-1">
												<Button onClick={() => startJourney()}>
													<span>Refazer Valuation</span>
												</Button>
											</div>
										) : (
											""
										)}

										{!dataUser?.jornadaEmAndamento &&
										!dataUser?.jornadasConcluidas?.length ? (
											<div className="mt-1">
												<Button onClick={() => setShowModalAcceptTermsStartJorney(true)}>
													<span>Começar</span>
												</Button>
											</div>
										) : (
											""
										)}
										{dataUser?.jornadaEmAndamento ? (
											<div className="mt-1">
												<Link
													to={validateRoute(activeMenu, dataUser?.idPlano)}
													style={{ display: "contents" }}
												>
													<Button>
														<span>Continuar Jornada</span>
													</Button>
												</Link>
											</div>
										) : (
											""
										)}
									</>
								)}
							</div>
						</Row>
						{dataUser?.jornadasConcluidas?.length ? (
							<Row>
								<div
									className="img-recurso"
									data-tooltip-html={
										"A inteligência emocional influencia diretamente no comportamento do ambiente de trabalho e na produtividade do profissional."
									}
									data-tooltip-id="header-page-tooltip"
								>
									{renderIcon(5)}
								</div>
								<div
									className="img-recurso"
									data-tooltip-html={
										"Você não precisa somente dominar a sua área, mas também as ferramentas necessárias para o exercício da sua profissão."
									}
									data-tooltip-id="header-page-tooltip"
								>
									{renderIcon(3)}
								</div>
								<div
									className="img-recurso"
									data-tooltip-html={
										"Fazer uma gestão do tempo e se aproveitar da inovação podem trazer resultados fantásticos, sem sobrecarregar a equipe."
									}
									data-tooltip-id="header-page-tooltip"
								>
									{renderIcon(4)}
								</div>
								<div
									className="img-recurso"
									data-tooltip-html={
										"Se você não se cuidar, as faltad começarão a aumentar e os afastamentos passarão a ser cada vez mais frequentes."
									}
									data-tooltip-id="header-page-tooltip"
								>
									{renderIcon(2)}
								</div>
								<div
									className="img-recurso"
									data-tooltip-html={
										"Problemas financeiros podem afetar sua vida profissional, levando a preocupações constantes, dificuldade de concentração no trabalho e faltas."
									}
									data-tooltip-id="header-page-tooltip"
								>
									{renderIcon(1)}
								</div>
							</Row>
						) : (
							""
						)}
					</div>
					<div className="card graph-video">
						<Swiper
							spaceBetween={100}
							slidesPerView={1}
							pagination={{ clickable: true }}
							initialSlide={slide}
							// autoHeight={true}
						>
							{dataUser?.jornadasConcluidas?.length && chartJornadas?.data ? (
								<SwiperSlide>
									<div className="chart-container">
										<div className="chart-header"></div>
										<div className="chart-notes justify-content-between">
											<div>
												<h1 className="title-pontuacao-home">
													<span>
														Minha Evolução{" "}
														<FontAwesomeIcon
															icon={faCircleInfo}
															// onClick={() => setModalHelper(true)}
															data-tooltip-html={
																"Você pode responder na ordem que preferir, mas é recomendável seguir a sequência sugerida: dinheiro, saúde, conhecimento, tempo e emoções. Ao concluir todas as perguntas em um tópico, clique no botão abaixo para avançar para a próxima etapa. É fundamental responder a todas as perguntas para progredir na UELF."
															}
															data-tooltip-id="header-page-tooltip"
														/>
													</span>
												</h1>
												<h1 className="title-pontuacao-home">
													<span>Nota da Vida: </span>
													<span className="note">
														{dataUser?.ultimaJornadaNotas?.pontuacao > 0 &&
														dataUser?.ultimaJornadaNotas?.pontuacao !== undefined
															? dataUser?.ultimaJornadaNotas?.pontuacao?.toLocaleString(
																	"pt-br",
															  )
															: " -- "}{" "}
														<FontAwesomeIcon
															icon={faCircleInfo}
															// onClick={() => setModalHelper(true)}
															data-tooltip-html={
																"A nota da vida é calculada pelo algorítimo da plataforma UELF. A nota estará entre 0 (colapso) e 100.000 (alta performance)."
															}
															data-tooltip-id="header-page-tooltip"
														/>
													</span>
												</h1>
												<h1 className="title-pontuacao-home">
													<span>Nível de Performance: </span>
													<span className="note">
														{dataUser?.ultimaJornadaNotas?.notaVida > 0 &&
														dataUser?.ultimaJornadaNotas?.notaVida !== undefined
															? dataUser?.ultimaJornadaNotas?.notaVida?.toLocaleString(
																	"pt-br",
																	{ minimumFractionDigits: 2 },
															  )
															: " -- "}{" "}
														<FontAwesomeIcon
															icon={faCircleInfo}
															// onClick={() => setModalHelper(true)}
															data-tooltip-html={
																"A sua performance na plataforma UELF é calculada pela média dos 5 recursos (dinheiro, saúde, conhecimento, tempo e emoção). A nota varia de 0 (colapso) a 10 pontos (alta performance). Se a sua nota for 5, significa que o seu desempenho é medíocre, você tem muito para evoluir. Se a sua nota estiver entre 6 e 8, você está na zona de performance adequada. Se a sua nota estiver entre 8 e 10, você estará na zona de alta performance."
															}
															data-tooltip-id="header-page-tooltip"
														/>
													</span>
												</h1>
											</div>
											{/* <h1 className="title-pontuacao-home">
												<span>Média: </span>
												<span className="note">
													{dataUser?.ultimaJornadaNotas?.media > 0 &&
													dataUser?.ultimaJornadaNotas?.media !== undefined
														? dataUser?.ultimaJornadaNotas?.media?.toLocaleString("pt-br", {
																minimumFractionDigits: 2,
														  })
														: " -- "}
												</span>
											</h1> */}
											<div className="text-right">
												{dataConfig?.video_boas_vindas ? (
													<Button
														onClick={() => {
															setShowModalVideo(true);
														}}
													>
														Vídeo de boas-vindas
													</Button>
												) : (
													""
												)}
												<p className="date-last-result">
													Esse resultado corresponde a data de:{" "}
													{
														dataUser?.jornadasConcluidas?.[
															dataUser?.jornadasConcluidas?.length - 1
														]?.dataConclusao
													}
												</p>
											</div>
										</div>
										<div className="text-right w-100">
											<Button
												title="Zoom Inicial"
												onClick={() => resetZoomDashboardGraph()}
												data-tooltip-html={
													"Ao clicar na lupa o gráfico será apresentado na sua escala exponencial. Caso você queira identificar onde a sua nota atual se encontra, basta movimentar o gráfico."
												}
												data-tooltip-id="header-page-tooltip"
												className="graphic-zoom"
											>
												<FontAwesomeIcon icon={faMagnifyingGlass} />
											</Button>
										</div>
										<div className="chart">
											<Line
												ref={chartRef}
												data={chartJornadas?.data}
												options={chartJornadas?.options}
											/>
										</div>
									</div>
								</SwiperSlide>
							) : (
								""
							)}

							{!dataUser?.jornadasConcluidas?.length ? (
								<SwiperSlide>
									<div className="mb-3">
										<h5 className="title">Os recursos que nós trabalhamos</h5>
									</div>

									<div className="my-5">
										<div className="cards-resource">
											<CardResourceHome idRecurso={1} recurso={"Dinheiro"} />
											<CardResourceHome idRecurso={2} recurso={"Saúde"} />
											<CardResourceHome idRecurso={3} recurso={"Tempo"} />
											<CardResourceHome idRecurso={4} recurso={"Conhecimento"} />
											<CardResourceHome idRecurso={5} recurso={"Emoções"} />
										</div>
										<div className="cards-resource-mobile">
											<Swiper>
												<SwiperSlide>
													<CardResourceHome idRecurso={1} recurso={"Dinheiro"} />
												</SwiperSlide>
												<SwiperSlide>
													<CardResourceHome idRecurso={2} recurso={"Saúde"} />
												</SwiperSlide>
												<SwiperSlide>
													<CardResourceHome idRecurso={3} recurso={"Tempo"} />
												</SwiperSlide>
												<SwiperSlide>
													<CardResourceHome idRecurso={4} recurso={"Conhecimento"} />
												</SwiperSlide>
												<SwiperSlide>
													<CardResourceHome idRecurso={5} recurso={"Emoções"} />
												</SwiperSlide>
											</Swiper>
										</div>
									</div>
									{dataConfig?.video_boas_vindas ? (
										<>
											<div className="mb-3">
												<h5 className="title">Iniciando um plano de performance</h5>
											</div>
											<Button
												className="btn-video-modal-slide"
												onClick={() => {
													setShowModalVideo(true);
												}}
											>
												<div className="image">
													<img src="assets/video-icon.png" alt="Video Icon" />
												</div>
												<span> ASSISTA AO VÍDEO</span>
											</Button>
										</>
									) : (
										""
									)}
								</SwiperSlide>
							) : (
								""
							)}
						</Swiper>
					</div>
				</div>
			</PainelMasterPage>
			<ModalNews show={showModalNews} onClose={setShowModalNews} />
			<ModalAcceptTermsStartJorney
				show={showModalAcceptTermsStartJorney}
				onClose={setShowModalAcceptTermsStartJorney}
				onAccept={startJourney}
			/>
			<VideoModal
				show={showModalVideo}
				onClose={setShowModalVideo}
				url={`${dataConfig?.video_boas_vindas}`}
			/>
			<Tooltip
				id="header-page-tooltip"
				className="adp-tooltip--component"
			></Tooltip>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};

// @ts-ignore
export default Home;
