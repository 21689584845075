import "./Notes.scss";

import React, { useEffect, useState } from "react";
import {
	ToastContainer,
	ToastPosition,
	TypeOptions,
	toast,
} from "react-toastify";
import { faFileMedical, faNoteSticky } from "@fortawesome/free-solid-svg-icons";

import { Button } from "reactstrap";
import ButtonNextScreen from "../../components/ButtonNextScreen/ButtonNextScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotesAddModal from "../../components/NotesAddModal2/NotesAddModal2";
import NotesService from "../../services/NotesService";
import NotesStage from "../../components/NotesStage/NotesStage";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";
import { useNavigate } from "react-router-dom";
import { useDashboard } from "../../providers/DashboardProvider";

export interface NotesStage {
	id: number;
	resourceId: number;
	anotation: string;
	mood: number;
	isUelf: number;
	data: Date;
	isSelectedEdit: boolean;
	isSelectedDelete: boolean;
}

const Notes = () => {
	const [data, setData] = useState<any>([]);
	const [objUelf, setObjUelf] = useState<boolean>(false);
	const [idObjetivo, setIdObjetivo] = useState<number>(0);
	const [notes, setNotes] = useState<NotesStage>();

	const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
	const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
	const [hasBeenUpdated, setHasBeenUpdated] = useState<boolean>(false);
	const { dataUser } = useActiveJourney();
	const navigate = useNavigate();
	const { theme } = useDashboard();

	useEffect(() => {
		if (dataUser && dataUser?.idPlano <= 1) {
			navigate("/home");
		} else {
			handleList().then();
		}
	}, [dataUser]);

	const toastSettings = (
		message: string,
		position: ToastPosition = "bottom-center",
		type: TypeOptions = "warning",
		onClose?: () => void,
	) => {
		toast(message, {
			position: position,
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			type,
			theme: "dark",
			onClose: () => onClose,
		});
	};

	const handleList = async () => {
		try {
			let data: object = {};

			const [_Response, _Error] = await new NotesService().list(data);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			let response = _Response.resultSet;

			setNotes(response);
		} catch (err) {
			console.warn(err);
		}
	};

	const renderNotesFunctions = () => {
		return (
			<>
				<div className="notes-actions">
					<div className="actions">
						<Button onClick={() => setShowModalAdd(true)}>
							<FontAwesomeIcon icon={faFileMedical} />
							<span>Adicionar anotação</span>
						</Button>
					</div>
				</div>
				<div className="notes">
					{notes ? (
						<div className="NotesStage">
							<NotesStage notes={notes} setFunction={handleList} isFeedback={false} />
						</div>
					) : (
						"Não há registros disponíveis!"
					)}
				</div>
			</>
		);
	};

	return (
		<PainelMasterPage
			title={"Diário da Jornada"}
			subtitle={
				"Escreva aqui as anotações referentes as suas jornadas. É importante e prazeroso lembrar dos detalhes."
			}
			subtitleclass="text-white"
			icon={
				theme == "dark" ? (
					<img src="assets/img/icons/title/evolution.svg" />
				) : (
					<img src="assets/img/icons/title/evolution-light.svg" />
				)
			}
			onClick={() => {}}
		>
			<>
				{data ? (
					renderNotesFunctions()
				) : (
					<>
						<h1>Algo deu errado!</h1>
						<div className="text">
							<p>Aguarde mais alguns momentos e recarregue a pagina!</p>
						</div>
					</>
				)}

				<ButtonNextScreen text={"Voltar"} linkScreen={"/macro-goals"} />

				<NotesAddModal
					show={showModalAdd}
					isObjUelf={objUelf}
					idObjetivo={idObjetivo}
					setfunction={handleList}
					onClose={() => {
						handleList().then();
						setShowModalAdd(false);
					}}
				/>
			</>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</PainelMasterPage>
	);
};

export default Notes;
