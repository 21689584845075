import React, { useEffect, useState } from "react";
import { Admin } from "../models/Admin";
import { toastSettings } from "../models/toastConfig";
import AdminService from "../services/AdminService";
import { useActiveJourney } from "./ActiveJourneyProvider";
import { useLocation, useNavigate } from "react-router-dom";

export const ConfigurationContext = React.createContext<Admin | any>({});

export const ConfigurationProvider = (props: any) => {
	const [dataConfig, setData] = useState<Object | null>(null);
	const { activeJourney, dataUser } = useActiveJourney();
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const getData = async () => {
		try {
			const [_Response, _Error] = await AdminService.listUserConfigurations();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			var objects = _Response?.resultSet.reduce(
				(obj: any, item: any) => Object.assign(obj, { [item.chave]: item.valor }),
				{},
			);

			setData(objects);
		} catch (err) {
			console.warn(err);
		}
	};

	useEffect(() => {
		if (pathname !== "/login" && !pathname.includes("admin")) {
			!dataConfig && getData();
		}
	}, [navigate]);

	return (
		<ConfigurationContext.Provider
			value={{
				dataConfig,
				getData,
			}}
		>
			{props.children}
		</ConfigurationContext.Provider>
	);
};

export const useConfiguration = () => React.useContext(ConfigurationContext);
