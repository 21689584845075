import "./ToDoAvoid.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import ToDoAvoidAddModal from "../../components/ToDoAvoidAddModal/ToDoAvoidAddModal";
import ToDoAvoidStage from "../../components/ToDoAvoidStage/ToDoAvoidStage";
import { ToastContainer } from "react-toastify";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useToDoAvoid } from "../../providers/ToDoAvoidProvider";
import GoogleTagManager, { EventEnum } from "../../services/GoogleTagManager";
import Slugify from "../../utils/Slugify";
import { Card } from "reactstrap";

const ToDoAvoid = () => {
	const { handleList, showModalAdd, setShowModalAdd } = useToDoAvoid();

	useEffect(() => {
		setShowModalAdd(false);
		GoogleTagManager.event(EventEnum.Marketing, { action: "evitar_fazer" });
	}, []);

	return (
		<PainelMasterPage
			title={"Lista de evitar e fazer"}
			helpText={
				"Você sabia que evitar algo pode ser uma forma eficiente de mudar um hábito angular? Por isso, muitas vezes, antes de se propor a fazer mais coisas, é importante avaliar o que pode ser evitado. Além de economizar energia, a via negativa pode ser mais fácil e eficiente. Portanto, considere deixar de fazer algumas coisas antes de se propor a fazer mais, e assim, você pode mudar hábitos e melhorar sua performance"
			}
			subtitleclass="text-white"
			icon={<FontAwesomeIcon icon={faFileAlt} />}
			onClick={() => {}}
		>
			<Card className="p-3 card-principal">
				<div className="to-do-avoid">
					<p>
						Escolha apenas duas coisas para evitar e concentre-se em uma única
						atividade para começar. Foque sua energia em um objetivo específico para
						alcançá-lo com eficiência. Por isso você deve escolher 2 coisas para
						evitar e apenas 1 para começar a fazer.
					</p>
					<ToDoAvoidStage />
				</div>
			</Card>

			<ToDoAvoidAddModal
				show={showModalAdd}
				onClose={() => {
					setShowModalAdd(false);
					handleList().then();
				}}
			/>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</PainelMasterPage>
	);
};

export default ToDoAvoid;
