import { useDashboard } from "../../providers/DashboardProvider";
import "./CardResourceHome.scss";
import { FC, useEffect } from "react";

interface CardResourceHomeProps {
	idRecurso: Number;
	recurso: String;
}

const CardResourceHome: FC<CardResourceHomeProps> = ({
	idRecurso,
	recurso,
	...rest
}) => {
	const { renderIcon, renderBgIconHex, renderTextHome } = useDashboard();

	return (
		<div className="resources-info">
			<div className="img">{renderIcon(0, recurso)}</div>
			<div
				className="card-resource"
				style={{ backgroundColor: renderBgIconHex(recurso) }}
			>
				<h2 className="title">{recurso}</h2>
				<p className="body">{renderTextHome(idRecurso)}</p>
			</div>
		</div>
	);
};

export default CardResourceHome;
