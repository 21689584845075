import "./DashboardResourceScore.scss";

import { Chart, Doughnut } from "react-chartjs-2";

import FormatNote from "../../utils/FormatNote";
import React from "react";
import { useDashboard } from "../../providers/DashboardProvider";

const DashboardResourceScore = ({ data }: any) => {
	const { renderIcon, renderBgIconHex, renderTextHome } = useDashboard();
	let maxFinded: boolean = false;
	Chart.defaults.plugins.tooltip.enabled = false;

	const chartOptions = {
		options: {
			responsive: true,
			cutout: 60,
			legend: {
				display: false,
			},
			animation: {
				animateScale: true,
				animateRotate: true,
			},
		},
	};

	return (
		<div
			className="DashboardResourceScore card"
			data-testid="DashboardResourceScore"
		>
			<div className="label">
				<div className="image">
					<img src={data.iconeRecurso} alt={data.descricaoRecurso} />
				</div>
				<div className="title-wrapper">
					<span
						className="title p"
						style={{ color: renderBgIconHex(data.descricaoRecurso) }}
					>
						{data.descricaoRecurso}
					</span>
					<span className="title s">SUB-RECURSOS</span>
				</div>
				{data.maiorNota && (
					<div className="trophy">
						<svg
							width="186"
							height="164"
							viewBox="0 0 186 164"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g opacity="0.3">
								<path
									d="M168.407 8.28325C162.41 6.95696 155.485 7.80751 148.964 10.2654C149.021 6.74218 149.003 3.30114 148.904 0H37.097C36.9975 3.29981 36.9788 6.74103 37.0364 10.2654C30.5161 7.80751 23.592 6.957 17.5935 8.28325C8.72343 10.2337 2.70057 16.3303 0.63611 25.434C-2.06111 37.32 3.8768 50.9474 17.3529 63.8113C27.9715 73.9443 42.2175 82.3748 57.2617 87.5817C63.4953 96.3695 71.4745 103.449 81.5986 107.754C80.1224 113.145 74.9414 128.765 62.4225 138.324H123.577C111.056 128.767 105.876 113.145 104.401 107.754C114.526 103.449 122.508 96.368 128.738 87.5788C143.781 82.3734 158.028 73.9428 168.647 63.8099C182.125 50.9452 188.061 37.3192 185.364 25.4325C183.297 16.3303 177.275 10.2337 168.405 8.28325H168.407ZM26.3237 54.4143C16.5772 45.1058 11.7102 35.3507 13.3074 28.3126C14.2459 24.1579 16.4977 21.8269 20.3913 20.9678C25.5307 19.8333 32.2355 21.5574 37.7583 24.9727C39.0125 39.8716 41.9563 55.4306 47.4632 69.2363C39.5386 65.1148 32.2588 60.0806 26.3237 54.4139V54.4143ZM159.676 54.4143C153.741 60.0784 146.462 65.1152 138.536 69.2382C144.046 55.4336 146.988 39.873 148.241 24.9746C153.764 21.5609 160.467 19.8367 165.608 20.9696C169.502 21.8288 171.755 24.1599 172.692 28.3144C174.289 35.3537 169.422 45.1091 159.676 54.4146V54.4143Z"
									fill={data.recursoCorFundo}
								/>
								<path
									d="M53.7344 146.519H132.268V163.495H53.7344V146.519Z"
									fill={data.recursoCorFundo}
								/>
							</g>
							<path d="M98.6 69V31.3H84.35V40H88.4V69H98.6Z" fill="var(--text)" />
						</svg>
					</div>
				)}
			</div>
			<div className="items-container">
				<div className="wrapper">
					{data.subRecursos.map((item: any, key: number) => {
						let isMaxValue = false;
						const maxValue = Math.max(
							...data.subRecursos.map((o: any) => o?.pontuacao),
							0,
						);

						if (maxValue == parseFloat(item.pontuacao) && !maxFinded) {
							isMaxValue = maxFinded = true;
						}
						return (
							<div key={key} className="item">
								<span className="title">{item.descricaoSubRecurso}</span>
								<div className="graph-wrapper">
									<Doughnut
										id={`doughnut-${key}`}
										data={{
											datasets: [
												{
													data: [parseFloat(item.pontuacao)],
													backgroundColor: [renderBgIconHex(item.descricaoRecurso)],
													hoverBackgroundColor: [renderBgIconHex(item.descricaoRecurso)],
													borderWidth: 0,
												},
											],
										}}
										redraw
										options={{
											responsive: true,
											maintainAspectRatio: true,
											cutout: 0,
										}}
									/>
									<span className="note">{FormatNote(item.pontuacao, 1)}</span>
									{
										// isMaxValue && <img src={`${process.env.PUBLIC_URL}/assets/img/star.png`} alt="" />
									}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default DashboardResourceScore;
