import "./Timeline.scss";

import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Nav,
	NavItem,
	Row,
	TabContent,
	TabPane,
	Table,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import {
	faFileMedical,
	faStar,
	faStarHalfAlt,
} from "@fortawesome/free-solid-svg-icons";

import ButtonNextScreen from "../../components/ButtonNextScreen/ButtonNextScreen";
import CalendarComponent from "../../components/CalendarComponent/CalendarComponent";
import CalendarTableComponent from "../../components/CalendarTableComponent/CalendarTableComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../components/Loading/Loading";
import ModalFilterTimeline from "../../components/ModalFilterTimeline/ModalFilterTimeline";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import TimelineService from "../../services/TimelineService";
import { ToastContainer } from "react-toastify";
import { toastSettings } from "../../models/toastConfig";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";
import { Link, useNavigate } from "react-router-dom";
import FormataData from "../../utils/FormataDate";
import MacroGoalsAddModal from "../../components/MacroGoalsAddModal/MacroGoalsAddModal";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import SuccessRoadmapSubObjectiveModal from "../../components/SuccessRoadmapSubObjectiveModal/SuccessRoadmapSubObjectiveModal";
import { useDashboard } from "../../providers/DashboardProvider";

const Timeline = () => {
	const [dataTimeline, setDataTimeline] = useState<Array<any>>([]);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [activeTab, setActiveTab] = useState<string>("1");
	const [loading, setLoading] = useState<boolean>(false);
	const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
	const { theme } = useDashboard();

	const { dataUser } = useActiveJourney();
	const navigate = useNavigate();

	const renderStatusSelect = (status: number) => {
		if (status == 4) {
			return <img src="/assets/img/icons/common/redellipse.svg" />;
		} else if (status == 2) {
			return <img src="/assets/img/icons/common/yellowellipse.svg" />;
		} else if (status == 3) {
			return <img src="/assets/img/icons/common/greenellipse.svg" />;
		}
	};

	useEffect(() => {
		if (dataUser && dataUser?.idPlano <= 1) {
			navigate("/home");
		} else {
			getTimeline().then(() => {
				setLoading(false);
			});
		}
	}, [dataUser]);

	const getTimeline = async (form?: object) => {
		try {
			setLoading(true);

			const [_Response, _Error] = await new TimelineService().list(form || {});

			if (!!_Error || !_Response?.status) {
				setLoading(false);
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			setDataTimeline(_Response?.resultSet);

			setLoading(false);
		} catch (e: any) {
			toastSettings("Algo deu errado!" || e?.message, "bottom-center", "error");
			setLoading(false);
		}
	};

	const renderStars = (value: any) => {
		return (
			<div className="stars">
				<ReactStars
					count={5}
					size={24}
					isHalf={false}
					emptyIcon={<FontAwesomeIcon icon={faStar} />}
					halfIcon={<FontAwesomeIcon icon={faStarHalfAlt} />}
					fullIcon={<FontAwesomeIcon icon={faStar} />}
					activeColor="hsla(42, 87%, 61%, 1)"
					value={value}
					edit={false}
				/>
			</div>
		);
	};

	const renderButtonsHeader = () => {
		return (
			<div className="timeline-actions">
				<div className="actions">
					<NavItem>
						<a
							className={`${activeTab === "1" && "active"}`}
							onClick={() => setActiveTab("1")}
						>
							<span>Meu calendário de tarefas</span>
						</a>
					</NavItem>
					<NavItem>
						<a
							className={`${activeTab === "2" && "active"}`}
							onClick={() => setActiveTab("2")}
						>
							<span>Lista de tarefas</span>
						</a>
					</NavItem>
				</div>
			</div>
		);
	};

	const renderCalendarTimeline = () => {
		return (
			<Container className="mt-4 mx-0 px-0" style={{ maxWidth: "100%" }}>
				<Row>
					<Col className="col col-md-6 pl-0">
						<CalendarComponent data={dataTimeline} />
					</Col>
					<Col className="col col-md-6 pr-0">
						<CalendarTableComponent data={dataTimeline} />
					</Col>
				</Row>
			</Container>
		);
	};

	const renderTableTimeline = () => {
		return (
			<div className="table-timeline">
				<div className="d-flex justify-content-end actions">
					<Link to={"/goals-and-tasks"}>
						<Button className="mb-3">
							<FontAwesomeIcon icon={faFile} />
							<span>Organizar minhas tarefas</span>
						</Button>
					</Link>
				</div>
				<div className="container-task">
					<div className="grid">
						<span className="grid-item">Tarefas</span>
						<span className="grid-item">Objetivo</span>
						<span className="grid-item">Status</span>
						<span className="grid-item">Urgência</span>
						<span className="grid-item">Tempo</span>
						<span className="grid-item">Data Início</span>
						<span className="grid-item">Data Fim</span>
					</div>

					<div className="table-task">
						{dataTimeline?.map((task: any, index: number) => {
							return (
								<div className="table-row">
									<span className="table-cell justify-content-left">
										<span className="pos">{index + 1}</span>
										{task.descricao}
									</span>
									<span className="table-cell">{task.alvo}</span>
									<span className="table-cell">{renderStatusSelect(task.status)}</span>
									<span className="table-cell text-right">
										{renderStars(task.urgencia)}
									</span>
									<span className="table-cell">{task.descricaoTempoNecessario}</span>
									<span className="table-cell">{FormataData(task.dataInicial)}</span>
									<span className="table-cell">{FormataData(task.dataFinal)}</span>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	};

	return (
		<PainelMasterPage
			title={"Agenda de Tarefas"}
			subtitle={
				"Consulte o calendário e a lista de tarefas que você definiu para seus objetivos. Use o organizador de tarefas UELF e ele vai te ajudar a realizar tudo priorizando tudo com base na sua vontade de evoluir."
			}
			icon={
				theme == "dark" ? (
					<img src="assets/img/icons/title/evolution.svg" />
				) : (
					<img src="assets/img/icons/title/evolution-light.svg" />
				)
			}
			onClick={() => {}}
		>
			<div className="Timeline" data-testid="Timeline">
				<Nav tabs>{renderButtonsHeader()}</Nav>
				{!loading ? (
					<>
						<TabContent activeTab={activeTab}>
							<TabPane tabId="1">{renderCalendarTimeline()}</TabPane>
							<TabPane tabId="2">{renderTableTimeline()}</TabPane>
						</TabContent>

						<ButtonNextScreen text={"Voltar"} linkScreen={"/macro-goals"} />
					</>
				) : (
					<Loading margin="my-6" />
				)}
				<ModalFilterTimeline
					show={showModal}
					onClose={setShowModal}
					functionGetTimeline={getTimeline}
				/>
			</div>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			<SuccessRoadmapSubObjectiveModal
				show={showModalAdd}
				onClose={() => {
					getTimeline().then();
					setShowModalAdd(false);
				}}
			/>
		</PainelMasterPage>
	);
};

export default Timeline;
