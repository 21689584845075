import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { Table } from "reactstrap";
import "./CalendarTableComponent.scss";

interface CalendarTableComponentProps {
	data: any[];
}

const CalendarTableComponent: FC<CalendarTableComponentProps> = ({ data }) => {
	const [atrasados, setAtrasados] = useState<any[]>([]);
	const [emProgresso, setEmProgresso] = useState<any[]>([]);
	const [feitos, setFeitos] = useState<any[]>([]);

	useEffect(() => {
		const atrasados = data.filter(function (val) {
			return val.status === 4;
		});
		setAtrasados(atrasados);

		const emProgresso = data.filter(function (val) {
			return val.status === 2;
		});

		setEmProgresso(emProgresso);

		const feitos = data.filter(function (val) {
			return val.status === 3;
		});
		setFeitos(feitos);
	}, [data]);

	return (
		<div className="CalendarTableComponent" data-testid="CalendarTableComponent">
			<div className="table-area">
				<div className="title" style={{ backgroundColor: "#F27A7A" }}>
					Atrasado
				</div>
				<Table>
					<thead>
						<tr>
							<th>Dia</th>
							<th>Tarefa</th>
						</tr>
					</thead>
					<tbody>
						{atrasados.length > 0 ? (
							atrasados.map((item: any, key: number) => {
								return (
									<>
										<tr key={key}>
											<td>{moment(item.dataInicial).format("DD/MM")}</td>
											<td>{item.descricao}</td>
										</tr>
									</>
								);
							})
						) : (
							<>
								<tr>
								<td colSpan={3} className="text-center" style={{borderRadius: "0 0 0.9rem 0.9rem"}}>
										Não há registros disponíveis!
									</td>
								</tr>
							</>
						)}
					</tbody>
				</Table>
			</div>

			<div className="table-area">
				<div className="title" style={{ backgroundColor: "hsla(47, 83%, 77%, 1)" }}>
					Em progresso
				</div>
				<Table>
					<thead>
						<tr>
							<th>Dia</th>
							<th>Tarefa</th>
						</tr>
					</thead>
					<tbody>
						{emProgresso.length > 0 ? (
							emProgresso.map((item: any, key: number) => {
								return (
									<>
										<tr key={key}>
											<td>{moment(item.dataInicial).format("DD/MM")}</td>
											<td>{item.descricao}</td>
										</tr>
									</>
								);
							})
						) : (
							<>
								<tr>
								<td colSpan={3} className="text-center" style={{borderRadius: "0 0 0.9rem 0.9rem"}}>
										Não há registros disponíveis!
									</td>
								</tr>
							</>
						)}
					</tbody>
				</Table>
			</div>
			<div className="table-area">
				<div className="title" style={{ backgroundColor: "hsla(140, 72%, 72%, 1)" }}>
					Feito
				</div>

				<Table>
					<thead>
						<tr>
							<th>Dia</th>
							<th>Tarefa</th>
						</tr>
					</thead>
					<tbody>
						{feitos.length > 0 ? (
							feitos.map((item: any, key: number) => {
								return (
									<>
										<tr key={key}>
											<td>{moment(item.dataInicial).format("DD/MM")}</td>
											<td>{item.descricao}</td>
										</tr>
									</>
								);
							})
						) : (
							<>
								<tr>
								<td colSpan={3} className="text-center" style={{borderRadius: "0 0 0.9rem 0.9rem"}}>
										Não há registros disponíveis!
									</td>
								</tr>
							</>
						)}
					</tbody>
				</Table>
			</div>
		</div>
	);
};

export default CalendarTableComponent;
