import { FC } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import "./CardStatusDashboard.scss";

type CardStatusDashboardProps = {
	icon?: IconDefinition;
	imageIcon?: string;
	title: string;
	content: string;
};

const CardStatusDashboard: FC<CardStatusDashboardProps> = ({
	icon,
	title,
	content,
	imageIcon,
}) => {
	return (
		<div className="CardStatusDashboard">
			<Card>
				<CardTitle>
					{icon && <FontAwesomeIcon icon={icon} />}

					{imageIcon && <img className="img" src={imageIcon} />}
					{"  "}
					{title}
				</CardTitle>
				<CardBody className="d-flex justify-content-center w-100">
					{content}
				</CardBody>
			</Card>
		</div>
	);
};

export default CardStatusDashboard;
