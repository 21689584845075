import "./Navbars.scss";

import {
	Collapse,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	ListGroup,
	Media,
	Nav,
	NavItem,
	NavLink,
	Navbar,
	UncontrolledDropdown,
} from "reactstrap";
import { Link, redirect, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { faBell, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ThemeToggle from "../ThemeToggle/ThemeToggle";
import { useAuth } from "../../providers/AuthProvider";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";

interface Props {
	sidenavOpen: boolean;
}

const Navbars = ({ sidenavOpen }: Props) => {
	const { logout, user, planoActive } = useAuth();
	const { dataUser } = useActiveJourney();
	const navigate = useNavigate();

	const handleLogout = async () => {
		const _logout = await logout();
		_logout && redirect("/login");
	};

	return (
		<>
			<Navbar className="navbar-top navbar-expand navbar-dark">
				<Container fluid>
					<Collapse navbar isOpen={true}>
						<Nav className="align-items-center ml-md-auto" navbar>
							<NavItem className="d-xl-none">
								<div
									className={`pr-3 sidenav-toggler ${sidenavOpen && "active"}`}
									onClick={() => {
										document.querySelector(".sidenav")?.classList.toggle("is-active");
										document
											.querySelector(".overlay-sidenav")
											?.classList.toggle("is-active");
									}}
								>
									<div className="sidenav-toggler-inner">
										<i className="sidenav-toggler-line" />
										<i className="sidenav-toggler-line" />
										<i className="sidenav-toggler-line" />
									</div>
								</div>
							</NavItem>
						</Nav>

						<Nav className="align-items-center ml-auto ml-md-0" navbar>
							<NavItem>
								<ThemeToggle />
							</NavItem>

							<UncontrolledDropdown nav>
								<DropdownToggle className="nav-link" color="" tag="a">
									<span>Notificações</span>
									<FontAwesomeIcon icon={faBell} className="notify" />
								</DropdownToggle>
								<DropdownMenu className="dropdown-menu-xl py-0 overflow-hidden" right>
									<div className="px-3 py-3">
										<h6 className="text-sm text-muted text-center m-0">
											<span>Você não possui notificações</span>
										</h6>
									</div>
									<ListGroup flush></ListGroup>
								</DropdownMenu>
							</UncontrolledDropdown>

							<NavItem>
								<NavLink
									onClick={() => {
										navigate("/faq");
									}}
								>
									<span>Ajuda</span>
									<FontAwesomeIcon icon={faQuestionCircle} className="help" />
								</NavLink>
							</NavItem>

							<UncontrolledDropdown nav>
								<DropdownToggle className="nav-link pr-0" color="" tag="a">
									<Media className="align-items-center">
										<span className="avatar avatar-sm rounded-circle">
											{user.imagem ? (
												<img
													src={`${process.env.REACT_APP_UELF_API}/${user.imagem}`}
													alt="Foto de perfil"
													className="img-profile"
												/>
											) : (
												<img
													src="assets/img/1.png"
													alt="Foto de perfil"
													className="img-profile"
												/>
											)}
										</span>
										<Media className="ml-2 d-none d-lg-block">
											<span className="mb-0 text-sm font-weight-bold text-white">
												{user?.name || ""}
											</span>
										</Media>
									</Media>
								</DropdownToggle>
								<DropdownMenu style={{ minWidth: "max-content" }} right>
									<Link to={"/edit-profile"}>
										<DropdownItem>
											<i className="ni ni-single-02" />
											<span>Meu perfil</span>
										</DropdownItem>
									</Link>
									{(planoActive == 3 || planoActive == 4) &&
									dataUser?.jornadasConcluidas?.length ? (
										<Link to={"/journey-education"}>
											<DropdownItem>
												<i className="ni ni-single-02" />
												<span>Education</span>
											</DropdownItem>
										</Link>
									) : (
										""
									)}
									{(planoActive == 2 || planoActive == 4) && (
										<>
											<Link to={"/journey-evolution"}>
												<DropdownItem>
													<i className="ni ni-single-02" />
													<span>Evolution</span>
												</DropdownItem>
											</Link>
											<Link to={"/dashboard"}>
												<DropdownItem>
													<i className="ni ni-single-02" />
													<span>Dashboard</span>
												</DropdownItem>
											</Link>
										</>
									)}
									<DropdownItem divider />
									<DropdownItem href="#" onClick={handleLogout}>
										<i className="ni ni-user-run" />
										<span>Sair</span>
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Nav>
					</Collapse>
				</Container>
			</Navbar>
		</>
	);
};

export default Navbars;
