import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import "./Faq.scss";
import {
	faChartLine,
	faChevronDown,
	faChevronRight,
	faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Collapse } from "reactstrap";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";

const Faq = () => {
	const [activeAccordion, setActiveAccordion] = useState<string>("");
	const [faqs, setFaqs] = useState<[] | null>(null);

	const toggleAccordion = (accordion: string) => {
		setActiveAccordion(activeAccordion === accordion ? "" : accordion);
	};

	const navigate = useNavigate();

	const list = async () => {
		try {
			const response: any = await api.get("app/faq/lista");
			setFaqs(response?.data?.resultSet);
		} catch (e: any) {
			console.error(
				"Não foi possível realizar essa ação por favor tente mais tarde.",
			);
		}
	};

	useEffect(() => {
		if (faqs == null) {
			list();
		}
	}, [faqs]);

	return (
		<>
			<PainelMasterPage
				title={"FAQ"}
				subtitle={""}
				subtitleclass={""}
				icon={<FontAwesomeIcon icon={faMessage} />}
				onClick={() => {}}
			>
				<div className="Faq">
					<div className="row">
						<div className="col-sm-12">
							<h1 className="title">Perguntas Frequentes</h1>
							<p className="descricao">
								Bem-vindo à nossa página de <b>Perguntas Frequentes (FAQ)!</b>
								<br />
								Aqui você encontrará respostas para as dúvidas mais comuns sobre o nosso
								serviço. Se você tiver alguma pergunta que não esteja listada abaixo,
								sinta-se à vontade para entrar em contato conosco. Estamos aqui para
								ajudar!
							</p>
						</div>
						<div className="col-sm-12 lista mt-5">
							{faqs?.map((item: any, index: number) => {
								return (
									<Card
										key={index}
										onClick={() => toggleAccordion(`accordion${index}`)}
										aria-expanded={activeAccordion === `accordion${index}`}
									>
										<CardHeader>
											<span>{item?.pergunta}</span>
											<span>
												{activeAccordion == `accordion${index}` ? (
													<FontAwesomeIcon icon={faChevronDown} />
												) : (
													<FontAwesomeIcon icon={faChevronRight} />
												)}
											</span>
										</CardHeader>
										<Collapse isOpen={activeAccordion === `accordion${index}`}>
											<CardBody>{item?.resposta}</CardBody>
										</Collapse>
									</Card>
								);
							})}
						</div>
					</div>
					<div className="mt-5 w-100 text-right">
						<Button
							onClick={() => {
								navigate("/home");
							}}
						>
							<span>Voltar a página inicial</span>
						</Button>
					</div>
				</div>
			</PainelMasterPage>
		</>
	);
};

export default Faq;
