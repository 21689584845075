import "./ChartPerformance.scss";

import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";

import React, { FC, useEffect } from "react";

import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

interface ChartPerformanceProps {
	items: any;
}

const ChartPerformance: FC<ChartPerformanceProps> = ({ items }) => {
	var root: any = null;

	const initChart = () => {
		am5.ready(function () {
			if (root !== null) return false;

			// Create root element
			// https://www.amcharts.com/docs/v5/getting-started/#Root_element
			root = am5.Root.new("chartdiv");

			// Set themes
			// https://www.amcharts.com/docs/v5/concepts/themes/
			root.setThemes([am5themes_Animated.new(root)]);

			// Create chart
			// https://www.amcharts.com/docs/v5/charts/radar-chart/
			var chart = root.container.children.push(
				am5radar.RadarChart.new(root, {
					panX: false,
					panY: false,
					innerRadius: am5.percent(30),
					layout: root.verticalLayout,
				}),
			);

			// Create axes and their renderers
			// https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
			var xRenderer = am5radar.AxisRendererCircular.new(root, {});
			var yRenderer = am5radar.AxisRendererRadial.new(root, {});

			xRenderer.grid.template.setAll({
				stroke: am5.color("#A6ACBE"),
				strokeWidth: 3,
				strokeOpacity: 1,
			});

			yRenderer.grid.template.setAll({
				stroke: am5.color("#A6ACBE"),
				strokeWidth: 3,
				strokeOpacity: 1,
			});

			xRenderer.labels.template.setAll({
				textType: "adjusted",
				// fill: am5.color("rgba(33, 33, 33, 90%)"),
				fill: am5.color("#A6ACBE"),
			});

			yRenderer.labels.template.setAll({
				opacity: 0,
			});

			var xAxis = chart.xAxes.push(
				am5xy.CategoryAxis.new(root, {
					renderer: xRenderer,
					categoryField: "category",
					tooltip: am5.Tooltip.new(root, {}),
				}),
			);

			var yAxis = chart.yAxes.push(
				am5xy.ValueAxis.new(root, {
					renderer: yRenderer,
					min: 0,
					max: 10,
				}),
			);

			// Create series
			// https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
			let series1 = chart.series.push(
				am5radar.RadarColumnSeries.new(root, {
					stacked: true,
					name: "Jornada Anterior",
					xAxis: xAxis,
					yAxis: yAxis,
					valueYField: "value",
					categoryXField: "category",
				}),
			);

			series1.columns.template.setAll({
				tooltipText: "{categorySob}: {valueSob}",
				strokeOpacity: 0,
				fillOpacity: 0.44,
				width: am5.percent(98.5),
			});

			let ultimaJornada = items?.ultimaJornada?.map((item: any) => {
				return { category: item.descricaoRecurso, value: parseFloat(item.media) };
			});

			let jornadaAtual = items?.jornadaAtual?.map((item: any) => {
				return { category: item.descricaoRecurso, value: parseFloat(item.media) };
			});

			if (ultimaJornada?.length > 0 && jornadaAtual?.length > 0) {
				let newUltimaJornada = ultimaJornada?.map((item: any, index: number) => {
					let valor = 0;
					let valorSob = false;

					if (item.value < jornadaAtual[index].value) {
						valor = item.value;
					} else if (item.value > jornadaAtual[index].value) {
						valorSob = true;
						valor = jornadaAtual[index].value;
					}

					return {
						category: item.category,
						value: valor,
						categorySob: valorSob ? "Jornada Atual" : "Jornada Anterior",
						valueSob: valorSob ? valor : item.value,
						type: valorSob ? "C1" : "C2",
					};
				});

				series1.data.setAll(newUltimaJornada);
			} else {
				series1.data.setAll([
					{ category: "Dinheiro", value: 0 },
					{ category: "Saúde", value: 0 },
					{ category: "Conhecimento", value: 0 },
					{ category: "Tempo", value: 0 },
					{ category: "Emoções", value: 0 },
				]);
			}

			series1.columns.template.adapters.add(
				"fill",
				(fill: am5.Color, target: any) => {
					if (target.dataItem.dataContext) {
						return colorSet(target.dataItem.dataContext.category);
					}
				},
			);

			series1.columns.template.adapters.add(
				"fillOpacity",
				(fillOpacity: number, target: any) => {
					if (target.dataItem.dataContext) {
						return target.dataItem.dataContext.type === "C1" ? 1 : 0.44;
					}
				},
			);

			series1.appear(1000);

			if (jornadaAtual?.length > 0) {
				let series2 = chart.series.push(
					am5radar.RadarColumnSeries.new(root, {
						stacked: true,
						name: "Jornada Atual",
						xAxis: xAxis,
						yAxis: yAxis,
						valueYField: "value",
						categoryXField: "category",
					}),
				);

				series2.columns.template.setAll({
					tooltipText: "{categorySob}: {valueSob}",
					strokeOpacity: 0,
					width: am5.percent(98.5),
				});

				if (ultimaJornada?.length > 0) {
					let newJornadaAtual = jornadaAtual?.map((item: any, index: number) => {
						let valor = 0;
						let valorSob = false;

						if (item.value > ultimaJornada[index].value) {
							valor = item.value - ultimaJornada[index].value;
						} else if (item.value < ultimaJornada[index].value) {
							valorSob = true;
							valor = ultimaJornada[index].value - item.value;
						} else {
							valor = item.value;
						}

						return {
							category: item.category,
							value: valor,
							categorySob: valorSob ? "Jornada Anterior" : "Jornada Atual",
							valueSob: valorSob ? ultimaJornada[index].value : item.value,
							type: valorSob ? "C2" : "C1",
						};
					});

					series2.data.setAll(newJornadaAtual);
				} else {
					series2.data.setAll(
						jornadaAtual?.map((item: any) => {
							return {
								category: item.category,
								value: item.value,
								categorySob: "Jornada Atual",
								valueSob: item.value,
								type: "C1",
							};
						}),
					);
				}

				series2.columns.template.adapters.add(
					"fill",
					(fill: am5.Color, target: any) => {
						return colorSet(target.dataItem.dataContext.category);
					},
				);

				series2.columns.template.adapters.add(
					"fillOpacity",
					(fillOpacity: number, target: any) => {
						if (target.dataItem.dataContext) {
							return target.dataItem.dataContext.type === "C1" ? 1 : 0.44;
						}
					},
				);

				series2.appear(1000);
			}

			let startAngle = 180;
			let endAngle = 360;

			chart.setAll({ startAngle: startAngle, endAngle: endAngle });
			// @ts-ignore
			yAxis.get("renderer").set("axisAngle", startAngle);

			xAxis.data.setAll(jornadaAtual);

			// Animate chart
			// https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
			chart.appear(1000, 100);
		}); // end am5.ready()
	};

	const colorSet = (category: string) => {
		switch (category) {
			case "Dinheiro":
				return am5.color("#045929");
			case "Saúde":
				return am5.color("#27A2DC");
			case "Conhecimento":
				return am5.color("#DC9656");
			case "Tempo":
				return am5.color("#9349AA");
			case "Emoções":
				return am5.color("#C73740");
		}
	};

	useEffect(() => {
		if (items?.jornadaAtual?.length > 0) {
			initChart();
		}
	}, []);

	return (
		<div className="ChartPerformance" data-testid="ChartPerformance">
			<div id="chartdiv" />
		</div>
	);
};

export default ChartPerformance;
