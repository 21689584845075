import "./ChartPerformanceAdmin.scss";

import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";

import React, { FC, useEffect } from "react";

import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useDashboard } from "../../providers/DashboardProvider";

const ChartPerformanceAdmin = ({ subResourcesData }: any) => {
	var root: any = null;
	const { renderBgIconHex } = useDashboard();

	const initChart = () => {
		am5.ready(function () {
			if (root !== null) return false;

			// Create root element
			// https://www.amcharts.com/docs/v5/getting-started/#Root_element
			root = am5.Root.new("chartdiv");

			// Set themes
			// https://www.amcharts.com/docs/v5/concepts/themes/
			root.setThemes([am5themes_Animated.new(root)]);

			// Create chart
			// https://www.amcharts.com/docs/v5/charts/radar-chart/
			var chart = root.container.children.push(
				am5radar.RadarChart.new(root, {
					panX: false,
					panY: false,
					innerRadius: am5.percent(30),
					layout: root.verticalLayout,
				}),
			);

			// Create axes and their renderers
			// https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
			var xRenderer = am5radar.AxisRendererCircular.new(root, {});
			var yRenderer = am5radar.AxisRendererRadial.new(root, {});

			xRenderer.grid.template.setAll({
				stroke: am5.color("#A6ACBE"),
				strokeWidth: 3,
				strokeOpacity: 1,
			});

			yRenderer.grid.template.setAll({
				stroke: am5.color("#A6ACBE"),
				strokeWidth: 3,
				strokeOpacity: 1,
			});

			xRenderer.labels.template.setAll({
				textType: "adjusted",
				// fill: am5.color("rgba(33, 33, 33, 90%)"),
				fill: am5.color("#A6ACBE"),
			});

			yRenderer.labels.template.setAll({
				opacity: 0,
			});

			var xAxis = chart.xAxes.push(
				am5xy.CategoryAxis.new(root, {
					renderer: xRenderer,
					categoryField: "category",
					tooltip: am5.Tooltip.new(root, {}),
				}),
			);

			var yAxis = chart.yAxes.push(
				am5xy.ValueAxis.new(root, {
					renderer: yRenderer,
					min: 0,
					max: 10,
				}),
			);

			// Create series
			// https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
			let series1 = chart.series.push(
				am5radar.RadarColumnSeries.new(root, {
					stacked: true,
					name: "Jornada Anterior",
					xAxis: xAxis,
					yAxis: yAxis,
					valueYField: "value",
					categoryXField: "category",
				}),
			);

			series1.columns.template.setAll({
				tooltipText: "{categorySob}: {valueSob}",
				strokeOpacity: 0,
				fillOpacity: 0.44,
				width: am5.percent(98.5),
			});

			const dataSet = subResourcesData?.dadosRecursos[0].map((subRecurso: any) => {
				return { category: subRecurso.titulo, value: subRecurso.mediaGeral };
			});

			series1.data.setAll(dataSet ?? []);

			series1.columns.template.adapters.add(
				"fill",
				(fill: am5.Color, target: any) => {
					if (target.dataItem.dataContext) {
						return renderBgIconHex(target.dataItem.dataContext.category);
					}
				},
			);

			series1.columns.template.adapters.add(
				"fillOpacity",
				(fillOpacity: number, target: any) => {
					if (target.dataItem.dataContext) {
						return target.dataItem.dataContext.type === "C1" ? 1 : 1;
					}
				},
			);

			series1.appear(1000);

			let startAngle = 180;
			let endAngle = 360;

			chart.setAll({ startAngle: startAngle, endAngle: endAngle });
			// @ts-ignore
			yAxis.get("renderer").set("axisAngle", startAngle);

			xAxis.data.setAll(dataSet ?? []);

			// Animate chart
			// https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
			chart.appear(1000, 100);
		}); // end am5.ready()
	};

	useEffect(() => {
		initChart();
	}, []);

	return (
		<div
			className="ChartPerformanceAdmin card"
			data-testid="ChartPerformanceAdmin"
		>
			<div id="chartdiv" />
		</div>
	);
};

export default ChartPerformanceAdmin;
