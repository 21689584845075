import { faCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import GoalsStage from "../../components/GoalsStage/GoalsStage";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import { useStages } from "../../providers/StagesProvider";
import GoogleTagManager, { EventEnum } from "../../services/GoogleTagManager";
import "./Goals.scss";

const Goals = () => {
	const { currentStage } = useStages();

	useEffect(() => {
		GoogleTagManager.event(EventEnum.Marketing, { action: "foco" });
	}, []);

	return (
		<PainelMasterPage
			title={currentStage === 0 ? "Foco" : ""}
			subtitle={
				currentStage === 0
					? "Chegou a hora de escolher o sub-recurso que você vai trabalhar"
					: "Mantenha o seu compromisso evolutivo acompanhando o que você deve fazer e evitar!"
			}
			icon={<FontAwesomeIcon icon={faCrosshairs} />}
			subtitleclass={"text-white"}
			onClick={() => {}}
			helpText="Quando queremos melhorar nossos hábitos, tendemos a definir muitas metas ao mesmo tempo, como perder peso, ler livros, parar de fumar, ir à academia e muito mais. Mas, para ter sucesso, precisamos nos concentrar em um item de cada vez. Comece pelo que acha mais fácil e as pequenas vitórias impulsionarão você para o próximo objetivo."
		>
			<div className="background-goals">
				<div className="goals">
					<GoalsStage />
				</div>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</div>
		</PainelMasterPage>
	);
};

export default Goals;
