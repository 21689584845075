import "./GoalsStage.scss";

import { Button, Col, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoalsService from "../../services/GoalsService";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { toastSettings } from "../../models/toastConfig";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";
import { useStages } from "../../providers/StagesProvider";
import Loading from "../Loading/Loading";
import { useDashboard } from "../../providers/DashboardProvider";

export interface IData {
	idJornada: number;
	respostas: Array<Goals>;
	subRecurso: Array<SubRecurso>;
}

export interface Goals {
	idResposta: number;
	resposta: string;
	periodicidade: string;
	data: string;
	idListaEvitarFazerResposta: number;
	corRecurso: string;
	corSubRecurso: string;
	recurso: string;
	subrecurso: string;
	iconeRecurso: string;
	tipoResposta: string;
	checked: boolean;
}

export interface SubRecurso {
	idJornada: number;
	corRecurso: string;
	corSubRecurso: string;
	recurso: string;
	subrecurso: string;
}

const GoalsStage = () => {
	const navigate = useNavigate();
	const [data, setData] = useState<IData>();
	const [isLoading, setLoading] = useState<boolean>(false);

	const { currentStage, setCurrentStage } = useStages();
	const { activeJourney } = useActiveJourney();
	const { renderBgIconWithOpacityHex } = useDashboard();
	const [prioritySelected, setPrioritySelected] = useState<number>(0);

	const handleList = async () => {
		try {
			const [_Response, _Error] = await new GoalsService().list(activeJourney);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return false;
			}

			setData(_Response.resultSet);
		} catch (err) {
			console.warn(err);
		}
	};

	const handleData = () => {
		let data: any = null;
		let radios = document.querySelectorAll("input[type='radio']:checked");

		radios.forEach((radio: any) => {
			radio = radio as HTMLInputElement;

			let id = radio.id.split("-");
			let valor = radio.dataset.valor;
			let isEvitarFazer = radio.dataset.tipolista == "evitarefazer";

			data = {
				idJornada: activeJourney,
				idListaEvitarFazerResposta: Number(id[id.length - 1]),
				valor: valor,
				isEvitarFazer: isEvitarFazer,
			};
		});

		return data;
	};

	const saveFoco = async () => {
		try {
			let data: any = handleData();

			setLoading(true);
			const [_Response, _Error] = await new GoalsService().post(data);
			setLoading(false);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return false;
			}

			toastSettings(`Dados inseridos com sucesso!`, "bottom-center", "success");

			navigate("/survey-assessment");
		} catch (err) {
			console.warn(err);
		}
	};

	useEffect(() => {
		if (activeJourney) {
			handleList().then(() => {
				setCurrentStage(0);
			});
		}
	}, [activeJourney]);

	const renderStages = () => {
		if (data) {
			if (data?.respostas?.length > 0 && currentStage === 0) {
				return (
					<>
						<span className="title">Criando seu plano de ação</span>
						<div className="text">
							<p>
								Selecione a atividade que faz mais sentido para o seu momento atual.
							</p>
						</div>
						<div className="priority-wrapper">
							{data?.respostas?.map((item: Goals, index: number) => {
								return (
									<div className="priority-item" key={index}>
										<input
											type="radio"
											name="prioritys"
											id={"priority-cbox-" + item.tipoResposta + "-" + item.idResposta}
											data-valor={item.resposta}
											data-tipolista={item.tipoResposta}
											value={item.idResposta}
											style={{ display: "none" }}
											onClick={(e: any) => {
												setPrioritySelected(
													e.target.checked ? parseInt(e.target.value) : 0,
												);
											}}
											defaultChecked={item.checked}
										/>
										<label
											htmlFor={
												"priority-cbox-" + item.tipoResposta + "-" + item.idResposta
											}
											key={index}
											data-id={item.idResposta}
											style={{
												border:
													(item.checked && prioritySelected === 0) ||
													prioritySelected === item.idResposta
														? "0.313rem solid" + item.corRecurso
														: "",
											}}
										>
											<div className="row-align">
												<div className="col col-3 p-0">
													<img src={item.iconeRecurso} alt="" width="80%" />
												</div>
												<div className="col col-7 p-0">
													<div className="title-wrapper">
														{/* <p className="sub-resource">{item.subrecurso}</p> */}
														<p className="resource" style={{ color: "var(--text)" }}>
															<b>{item.subrecurso}</b>
														</p>
													</div>
												</div>
												<div className="col col-2 p-0 d-flex justify-content-center align-items-center">
													<span
														className="index"
														style={{
															border: "2px solid" + item.corRecurso,
															backgroundColor:
																(item.checked && prioritySelected === 0) ||
																prioritySelected === item.idResposta
																	? item.corRecurso
																	: renderBgIconWithOpacityHex(item.recurso),
														}}
													>
														{(item.checked && prioritySelected === 0) ||
														prioritySelected === item.idResposta ? (
															<FontAwesomeIcon
																icon={faCheck}
																style={{ color: "var(--white)" }}
															/>
														) : (
															""
														)}
													</span>
												</div>
											</div>
											<div className="text-wrapper">
												<div className="text">
													<p>{item.resposta}</p>
												</div>
											</div>
											<div
												className="box-recurso flex-row-reverse"
												style={{ color: item.corRecurso }}
											>
												{item.recurso}
											</div>
										</label>
									</div>
								);
							})}
						</div>

						<div className="priority-button-wrapper">
							<Row>
								<Col className="col-12">
									{/* <Link to="/todoavoid" className="px-2">
										<Button className="btn-view">Voltar</Button>
									</Link> */}

									{isLoading && <Loading />}

									<Button
										className="stage-forward"
										disabled={isLoading}
										onClick={saveFoco}
									>
										Finalizar
									</Button>
								</Col>
							</Row>
						</div>
					</>
				);
			} else {
				return (
					<div className="step text-center">
						<div> Não houve retorno de informação. </div>
					</div>
				);
			}
		} else {
			return (
				<div className="step">
					<div>Algo deu errado.</div>
				</div>
			);
		}
	};

	const renderIndicators = () => {
		if (data) {
			return (
				<>
					{data?.subRecurso?.map((item: SubRecurso, index: number) => {
						return (
							<div
								style={{
									backgroundColor: item.corRecurso,
								}}
								className="stage-indicator"
								key={index}
							>
								<span>{item.subrecurso}</span>
							</div>
						);
					})}
				</>
			);
		}
	};

	return (
		<div className="goals-wrapper">
			{data && <div className="stages-container">{renderIndicators()}</div>}
			{data && <div className="content-container">{renderStages()}</div>}
		</div>
	);
};

export default GoalsStage;
