import {
	Button,
	Col,
	Container,
	Form,
	FormGroup,
	FormText,
	Label,
	Row,
} from "reactstrap";
import SVG from "react-inlinesvg";
import "./LoginAdmin.scss";
import { useForm } from "react-hook-form";
import Validation from "./Validate/Validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAdminAuth } from "../../providers/AuthAdminProvider";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Loading from "../../components/Loading/Loading";
import { ToastContainer } from "react-toastify";

type FormValues = {
	email: string;
	password: string;
};

export default function LoginAdmin() {
	const [isLoading, setLoading] = useState<boolean>(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>({
		resolver: yupResolver(Validation),
	});

	const { login } = useAdminAuth();
	const navigate = useNavigate();

	const handle = handleSubmit(
		async (data: { email: string; password: string }) => {
			setLoading(true);
			if (await login(data)) navigate("/admin/");
			setLoading(false);
		},
	);

	return (
		<div className="LoginAdmin" style={{background: "url(/assets/img/bg-adm.png) no-repeat", backgroundSize: "cover", marginTop: "-1rem", marginBottom: "-1rem", overflow: "hidden !important"}}>
			<Container>
				{/* <Row>
					<Col className="d-none d-lg-block text-center">
						<SVG src={"/assets/img/admin/login-3-image.svg"} />
					</Col>
				</Row> */}
				<Col xs={12} sm={12} lg={5} className="column">
					<div className="brand">
						<img src="/assets/img/logo-uelf-preto.png" alt="Logo UELF" />
					</div>
					<h1 className="mt-5">Login do Administrador</h1>
					<p className="mt-3">
						A plataforma foi projetada para ajudar a rastrear e melhorar o desempenho
						dos colaboradores
					</p>

					<Form className="mt-5" onSubmit={handle}>
						<div className="">
							<label>E-mail</label>
							<input
								placeholder="administrador@uelf.com.br"
								type="email"
								className={`${!errors.email || "invalid"} form-control`}
								{...register("email")}
							/>
							<p className="text-danger">{errors.email?.message}</p>
						</div>
						<div className="">
							<label>Senha</label>
							<input
								placeholder="**********"
								type="password"
								className={`${!errors.password || "invalid"} form-control`}
								{...register("password")}
							/>
							<p className="text-danger">{errors.password?.message}</p>
						</div>
						{isLoading && <Loading />}
						<Button
							className="btn-primary"
							disabled={isLoading ?? false}
							variant="primary"
							type="submit"
						>
							ENTRAR
						</Button>
						<FormText className="mt-3">
							Problemas com o seu acesso?
							<br />
							Entre em contato com o administrador do sistema
						</FormText>
					</Form>
				</Col>
			</Container>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</div>
	);
}
