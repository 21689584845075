import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import FormataData from "../../utils/FormataDate";
import "./TaskAgendas.scss";
import { Doughnut } from "react-chartjs-2";
import { useDashboard } from "../../providers/DashboardProvider";
import { useEffect, useState } from "react";
import SuccessRoadmapService from "../../services/SuccessRoadmapService";
import { toastSettings } from "../../models/toastConfig";
import Loading from "../Loading/Loading";
import { formatDate } from "@fullcalendar/core";

const TaskAgendas = () => {
	const { renderIcon, renderBgIconHex, renderTextHome } = useDashboard();
	const [data, setData] = useState<[]>([]);
	const [loading, setIsLoading] = useState(false);
	const [chartData, setChartData] = useState<any[]>([]);

	const getData = async () => {
		setIsLoading(true);
		const [_Response, _Error] = await new SuccessRoadmapService().list();

		if (!!_Error || !_Response?.status) {
			toastSettings(_Error || _Response?.message, "bottom-center", "error");
			setIsLoading(false);
			return;
		}

		let response = _Response.resultSet;

		let dataObj: any = [];

		dataObj.push(...response?.objetivoUELF?.subobjetivos);
		response?.objetivos?.forEach((element: any) => {
			dataObj.push(...element?.subobjetivos)
		});

		const doneTasks = dataObj.filter((task: any) => task?.status == 3).length;
		const inProgressTasks = dataObj.filter((task: any) => task?.status == 2).length;
		const lateTasks = dataObj.filter((task: any) => task?.status == 4).length;

		setChartData([doneTasks, inProgressTasks, lateTasks]);
		setData(dataObj);
		setIsLoading(false);
		console.log(data)
	}

	useEffect(() => {
		if (!data.length) {
			getData();
		}
	}, [data]);

	return (
		<div className="TaskAgendas">
			<Card>
				<CardBody>
					{loading ? <Loading /> : <Row>
						<Col md="5" sm="12">
							<CardTitle className="m-0 mb-5 p-0">Agendas de Tarefas</CardTitle>
							<div className="graph">
								<Doughnut
									data={{
										labels: [
											"Tarefas feitas",
											"Tarefas em progresso",
											"Tarefas atrasadas",
										],
										datasets: [
											{
												data: chartData,
												backgroundColor: ["#86EBA8", "#F5E095", "#F27A7A"],
												hoverBackgroundColor: ["#86EBA8", "#F5E095", "#F27A7A"],
												borderWidth: 0,
											},
										],
									}}
									redraw
									options={{
										responsive: true,
										maintainAspectRatio: true,
										cutout: 55,
										plugins: {
											legend: {
												position: "right",
												labels: {
													boxWidth: 15,
													color: "#fff",
													padding: 30,
													font: {
														family: "Roboto",
														size: 15,
													},
												},
											},
										},
									}}
								/>
							</div>
						</Col>
						<Col sm="12" md="7">
							<div className="table-task">
								<div className="header-table">
									<table>
										<thead>
											<th>Dia</th>
											<th className="task">Tarefas</th>
										</thead>
									</table>
								</div>
								<table>
									<tbody>
										{
											data.map((task: any) => {
												return <tr>
													<td className="pr-5">{FormataData(task.dataInicial)}</td>
													<td className="task">{task.descricao}</td>
												</tr>
											})
										}
									</tbody>
								</table>
							</div>
						</Col>
					</Row>}
				</CardBody>
			</Card>
		</div>
	);
};

export default TaskAgendas;
