import "./ModalGradesSub.scss";

import { Card, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Chart, Doughnut } from "react-chartjs-2";
import React, { FC, useEffect, useState } from "react";

import DiagnoseService from "../../services/DiagnoseService";
import FormatNote from "../../utils/FormatNote";
import Loading from "../Loading/Loading";
import { toastSettings } from "../../models/toastConfig";
import { useDashboard } from "../../providers/DashboardProvider";
import AdminService from "../../services/AdminService";
import { useParams } from "react-router-dom";

interface ModalGradesSubProps {
	isAdminDashboard?: boolean;
}

const ModalGradesSub: FC<ModalGradesSubProps> = ({
	isAdminDashboard = false,
}: ModalGradesSubProps) => {
	const [modal, setModal] = useState<boolean>(false);
	const [data, setData] = useState<any>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const {
		renderBgIconHex,
		showModalDetails,
		setShowModalDetails,
		idRecurso,
		renderIcon,
	} = useDashboard();

	const toggle = () => setModal(!modal);
	const handleOnClose = () => setShowModalDetails(false);

	Chart.defaults.plugins.tooltip.enabled = false;

	const chartOptions = {
		options: {
			responsive: true,
			cutout: 70,
			legend: {
				display: false,
			},
			animation: {
				animateScale: true,
				animateRotate: true,
			},
		},
	};

	useEffect(() => {
		if (showModalDetails) {
			setModal(showModalDetails);
			getDetails();
		}
	}, [showModalDetails]);

	const { userId } = useParams();

	const getDetails = async () => {
		try {
			const [_Response, _Error] = isAdminDashboard
				? await AdminService.listGradesRecurso(parseInt(userId || ""), idRecurso)
				: await new DiagnoseService().listGradesRecurso(idRecurso);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				setIsLoading(false);
				return;
			}

			setData(_Response.resultSet);

			setIsLoading(false);
		} catch (err) {
			console.warn(err);
			setIsLoading(false);
		}
	};

	return (
		<Modal
			isOpen={modal}
			toggle={toggle}
			className="ModalGradesSub"
			data-testid="ModalGradesSub"
			onClosed={handleOnClose}
			size="md"
			centered
		>
			{!isLoading ? (
				<>
					<div className="modal-header pb-0" style={{ alignItems: "flex-start" }}>
						<h5 className="modal-title" style={{ padding: "0 1rem" }}>
							<div className="wrapper">
								<div className="card-title m-0">
									<div className="grouping">
										<div className="image">{renderIcon(0, data?.recurso?.descricao)}</div>
										<div className="texts-group">
											<div
												className="main-text"
												style={{ color: renderBgIconHex(data?.recurso?.descricao) }}
											>
												<span>{data?.recurso?.descricao}</span>
											</div>
											<div className="sub-text">
												<span>Sub-recursos</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</h5>
						{/* <button
							type="button"
							className="btn-close"
							aria-label="Close"
							onClick={toggle}
						></button> */}
					</div>
					<ModalBody
						className="d-flex"
						style={{ padding: "0 2.5rem 1.5rem !important" }}
					>
						<div className="wrapper">
							<div className="group">
								{data?.subRecursos?.map((item: any, key: number) => (
									<Card
										key={key}
										className={
											item?.subRecursoSelecionado ? "subSelected" : "subNotSelected"
										}
										style={{ borderColor: `${item?.corSubRecurso}` }}
									>
										<div>
											<p>{item?.descricaoSubRecurso}</p>
										</div>
										<div className="graphic">
											<Doughnut
												id="Doughnut"
												data={{
													datasets: [
														{
															data: [
																parseFloat(item?.pontuacao),
																10 - parseFloat(item?.pontuacao),
															],
															backgroundColor: [
																renderBgIconHex(data?.recurso?.descricao),
																"#8B8A79",
															],
															hoverBackgroundColor: [
																renderBgIconHex(data?.recurso?.descricao),
																"#8B8A79",
															],
															borderWidth: 0,
														},
													],
												}}
												options={chartOptions.options}
												width={100}
												height={100}
											/>
											<p className="m-0">{FormatNote(item?.pontuacao)}</p>
										</div>
									</Card>
								))}
							</div>
						</div>
					</ModalBody>
				</>
			) : (
				<Loading margin="my-6" />
			)}
		</Modal>
	);
};

export default ModalGradesSub;
