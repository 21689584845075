import "./CardScore.scss";

import { Chart, Doughnut } from "react-chartjs-2";
import { FC, useEffect } from "react";

import FormatNote from "../../utils/FormatNote";
import { useDashboard } from "../../providers/DashboardProvider";

interface CardScoreProps {
	item: any;
}

const CardScore: FC<CardScoreProps> = ({ item, ...rest }) => {
	const { renderIcon, renderBgIcon } = useDashboard();

	Chart.defaults.plugins.tooltip.enabled = false;

	const chartOptions = {
		options: {
			responsive: true,
			cutout: 105,
			legend: {
				display: false,
			},
			animation: {
				animateScale: true,
				animateRotate: true,
			},
		},
	};

	return (
		<div className="CardScore" data-testid="CardScore" {...rest}>
			<div
				className="image"
				style={{
					background: `url(/assets/img/subrecursos/${item.idSubRecurso}.svg) no-repeat center center / cover`,
				}}
			></div>
			<div className="wrapper">
				<div className="d-flex justify-content-between">
					<div className="card card-title">
						<div className="image">{renderIcon(0, item.recurso)}</div>
						<div className="grouping">
							<div className="title main">
								<span>{item.descricao}</span>
							</div>
							{/* <div className={`title ${renderBgIcon(item.recurso)}`}>
						<span>{item.recurso}</span>
					</div> */}
						</div>
					</div>
					<div className="graphic">
						<Doughnut
							id="Doughnut"
							redraw
							data={{
								datasets: [
									{
										data: [parseFloat(item.pontuacao), 10 - parseFloat(item.pontuacao)],
										backgroundColor: [item.corFundo, "#8B8A79"],
										hoverBackgroundColor: [item.corFundo, "#8B8A79"],
										borderWidth: 0,
									},
								],
							}}
							options={{
								responsive: true,
								maintainAspectRatio: true,
								cutout: 40,
								backgroundColor: "#000",
							}}
						/>
						<p className="m-0">{FormatNote(item.pontuacao)}</p>
						<span>SUA NOTA</span>
					</div>
				</div>
				<div className="group mt-5">
					<div className="text mt-3">
						<p className="m-0 text-center" style={{ fontWeight: "500" }}>
							{item.informativo}
						</p>
						{/* <div className="options mt-2">
						<p className="m-0">Siga algumas dicas UELF para melhor este recurso:</p>
						<div className="avoid mt-3">
							<span className="titleAvoid">Evite</span>
							<ol className="parentes">
								{item.detalhes.evitar.map((item: any, key: number) => {
									return <li key={key}>{item.descricao}</li>;
								})}
							</ol>
						</div>
						<div className="does mt-3">
							<span className="titleDoes">Faça</span>
							<ol className="parentes">
								{item.detalhes.fazer.map((item: any, key: number) => {
									return <li key={key}>{item.descricao}</li>;
								})}
							</ol>
						</div>
					</div> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardScore;
