import "./CardResourceAdmin.scss";

import React, { useEffect, useState, FC } from "react";
import { Card, CardBody, CardTitle, Col } from "reactstrap";
import { useDashboard } from "../../providers/DashboardProvider";
import { Doughnut } from "react-chartjs-2";
import FormatNote from "../../utils/FormatNote";

const chartOptions = {
	options: {
		responsive: true,
		maintainAspectRatio: true,
		aspectRatio: 2,
		cutout: 105,
		legend: {
			display: false,
		},
		animation: {
			animateScale: true,
			animateRotate: true,
		},
	},
};

const CardResourceAdmin = ({ recurso, maxScore }: any) => {
	const { renderBgIconHex, renderIcon } = useDashboard();

	return (
		<div className="CardResourceAdmin">
			<Card>
				<div className="items content-principal d-flex justify-content-between">
					<div className="d-flex align-items-center" style={{width: "30%"}}>
						<div className="d-flex align-items-center">
							<div className="image">{renderIcon(0, recurso?.titulo)}</div>
							<div>
								<h1
									className="title"
									style={{ color: renderBgIconHex(recurso?.titulo) }}
								>
									{recurso?.titulo}
								</h1>
								<h2 className="subtitle">Sub-recursos</h2>
							</div>
						</div>
					</div>
					<div className="d-flex align-items-center">
						<div className="circle-score-sub" style={{ backgroundColor: renderBgIconHex(recurso?.titulo)}}>
							<p className="m-0">{FormatNote(recurso?.mediaGeral, 1)}</p>
							{maxScore && <div className="" style={{position: "absolute", marginBottom: "-5rem", marginLeft: "6.5rem"}}>
								<img src={`${process.env.PUBLIC_URL}/assets/img/star.png`} alt="" />
							</div>}
							
						</div>
					</div>
					<div >
						<div className="subresources d-flex justify-content-center">
							{recurso.subRecursos &&
								recurso.subRecursos.map((sub: any) => {
									return (
										<div className="item " key={sub.idSubRecurso}>
											<h3 className="item-title" title={sub?.descricao}>{sub?.descricao}</h3>
											<div className="graphic">
												<div style={{ position: "relative" }}>
													<Doughnut
													redraw
														data={{
															datasets: [
																{
																	data: [parseFloat(sub?.media), 10 - parseFloat(sub?.media)],
																	backgroundColor: [renderBgIconHex(recurso?.titulo), "#8B8A79"],
																	hoverBackgroundColor: [
																		renderBgIconHex(recurso?.titulo),
																		"#8B8A79",
																	],
																	borderWidth: 0,
																},
															],
														}}
														options={{
															responsive: true,
															maintainAspectRatio: true,
															cutout: 60,
														}}
													/>
													
													<p className="m-0">{FormatNote(sub?.media, 1)}</p>
												</div>
											</div>
										</div>
									);
								})}
						</div>
					</div>
				</div>
			</Card>
		</div>
	);
};

// @ts-ignore
export default CardResourceAdmin;
