import "./ProgressTracker.scss";

import React, { FC, useEffect, useState } from "react";

import ButtonNextScreen from "../../components/ButtonNextScreen/ButtonNextScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../components/Loading/Loading";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import ProgressTrackerItem from "../../components/ProgressTrackerItem/ProgressTrackerItem";
import { ToastContainer } from "react-toastify";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";
import { useNavigate } from "react-router-dom";
import { useProgressTracker } from "../../providers/ProgressTrackerProvider";

interface ProgressTrackerProps {}

const ProgressTracker: FC<ProgressTrackerProps> = () => {
	const [loading, setLoading] = useState<boolean>(false);

	const { handleList, data } = useProgressTracker();
	const { dataUser } = useActiveJourney();
	const navigate = useNavigate();

	useEffect(() => {
		if (dataUser && dataUser?.idPlano <= 1) {
			navigate("/home");
		} else {
			setLoading(true);
			handleList().then(() => setLoading(false));
		}
	}, [dataUser]);

	const render = () => {
		return (
			<div>
				<div className="items">
					<ProgressTrackerItem key={-1} data={data?.objetivoUELF} isUelf={true} />
					{data?.objetivos?.map((item: any, index: number) => {
						return <ProgressTrackerItem key={index} data={item} isUelf={false} />;
					})}
				</div>
			</div>
		);
	};

	return (
		<PainelMasterPage
			title={"Rastreador de Progresso"}
			subtitle={"Rastreie a performance das suas metas e tarefas."}
			icon={<FontAwesomeIcon icon={faChartSimple} />}
			onClick={() => {}}
		>
			<div className="ProgressTracker" data-testid="ProgressTracker">
				{!loading ? (
					<>
						{data && <>{render()}</>}
						<ButtonNextScreen text={"Voltar"} linkScreen={"/macro-goals"} />
					</>
				) : (
					<Loading margin="my-6" />
				)}
			</div>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</PainelMasterPage>
	);
};

export default ProgressTracker;
