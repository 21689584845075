import { FC } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import "./CardStatusResourceDashboard.scss";
import { useDashboard } from "../../providers/DashboardProvider";

type CardStatusResourceDashboardProps = {
	icon?: IconDefinition;
	imageIcon?: string;
	title: string;
	content: string;
};

const CardStatusResourceDashboard: FC<CardStatusResourceDashboardProps> = ({
	icon,
	title,
	content,
	imageIcon,
}) => {
	const { renderBgIconHex } = useDashboard();

	return (
		<div className="CardStatusResourceDashboard">
			<Card style={{ backgroundColor: renderBgIconHex(title) }}>
				<CardTitle>
					{icon && <FontAwesomeIcon icon={icon} />}

					{imageIcon && <img className="img" src={imageIcon} />}
					{"  "}
					{title}
				</CardTitle>
				<CardBody className="d-flex justify-content-center w-100">
					{content}
				</CardBody>
			</Card>
		</div>
	);
};

export default CardStatusResourceDashboard;
