import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import { toastSettings } from "../../models/toastConfig";
import SuccessRoadmapService from "../../services/SuccessRoadmapService";
import "./SuccessRoadmapStars.scss";

interface Props {
	isUELF: boolean;
	prevRating: number;
	ID: number;
	editable: boolean;
}

const SuccessRoadmapStars = ({
	isUELF,
	prevRating,
	ID,
	editable = false,
}: Props) => {
	const ratingChanged = async (newRating: any) => {
		if (!editable) {
			return;
		}

		let data = {
			id: ID,
			prioridade: newRating,
		};

		if (isUELF) {
			try {
				const [_Response, _Error] =
					await new SuccessRoadmapService().updateRatingUELF(data);

				if (!!_Error || !_Response?.status) {
					toastSettings(_Error || _Response?.message, "bottom-center", "error");
					return;
				} else {
					toastSettings(
						"O status foi alterado com sucesso!",
						"bottom-center",
						"success",
					);
				}
			} catch (err) {
				console.warn(err);
			}
		} else {
			try {
				const [_Response, _Error] = await new SuccessRoadmapService().updateRating(
					data,
				);

				if (!!_Error || !_Response?.status) {
					toastSettings(_Error || _Response?.message, "bottom-center", "error");
					return;
				} else {
					toastSettings(
						"Alterado o status com sucesso!",
						"bottom-center",
						"success",
					);
				}
			} catch (err) {
				console.warn(err);
			}
		}
	};

	return (
		<div className="SuccessRoadmapStars stars">
			<ReactStars
				count={5}
				size={24}
				edit={editable}
				isHalf={false}
				onChange={ratingChanged}
				emptyIcon={<FontAwesomeIcon icon={faStar} />}
				halfIcon={<FontAwesomeIcon icon={faStarHalfAlt} />}
				fullIcon={<FontAwesomeIcon icon={faStar} />}
				activeColor="hsla(42, 87%, 61%, 1)"
				value={prevRating}
			/>
		</div>
	);
};

export default SuccessRoadmapStars;
