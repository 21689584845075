import "./CardScore2.scss";

import { Chart, Doughnut } from "react-chartjs-2";
import React, { FC } from "react";

import FormatNote from "../../utils/FormatNote";
import { useDashboard } from "../../providers/DashboardProvider";
import { Button } from "reactstrap";

interface CardScore2Props {
	item: any;
}

const CardScore2: FC<CardScore2Props> = ({ item, ...rest }) => {
	const { renderIcon, renderBgIconHex, setShowModalDetails, setIdRecurso } =
		useDashboard();

	Chart.defaults.plugins.tooltip.enabled = false;

	const chartOptions = {
		options: {
			responsive: true,
			cutout: 105,
			legend: {
				display: false,
			},
			animation: {
				animateScale: true,
				animateRotate: true,
			},
		},
	};

	let evitarCount = 0;
	let fazerCount = 0;

	return (
		<div
			className="CardScore2 wrapper"
			data-testid="CardScore2"
			style={{
				// borderBottomColor: "#000000",
				// borderBottomWidth: 1,
				// borderStyle: "solid",
				// borderColor: item.piorRecurso
				// 	? renderBgIconHex(item.descricaoRecurso)
				// 	: "transparent",
				borderLeftWidth: item.piorRecurso && "1.125rem",
				borderLeftColor: item.piorRecurso && renderBgIconHex(item.descricaoRecurso),
				borderStyle: "solid",
			}}
			{...rest}
		>
			<div className="card">
				<div className="card-title">
					<div className="d-flex flex-column">
						<div className="d-flex">
							<div className="image mr-3">{renderIcon(item.idRecurso)}</div>
							<div className="grouping">
								<div className="title main">
									<span>{item.descricaoRecurso}</span>
								</div>
							</div>
						</div>
						<div>
							<p className="information">{item.informativo}</p>
						</div>
					</div>
				</div>
				<div className="d-flex flex-column">
					<div className="graphic">
						<Doughnut
							id="Doughnut"
							redraw
							data={{
								datasets: [
									{
										data: [parseFloat(item.media), 10 - parseFloat(item.media)],
										backgroundColor: [renderBgIconHex(item.descricaoRecurso), "#8B8A79"],
										hoverBackgroundColor: [
											renderBgIconHex(item.descricaoRecurso),
											"#8B8A79",
										],
										borderWidth: 0,
									},
								],
							}}
							options={{
								responsive: true,
								maintainAspectRatio: true,
								cutout: 40,
								backgroundColor: "#000",
							}}
						/>
						<p className="m-0">{FormatNote(item.media)}</p>
						<span>SUA NOTA</span>
					</div>
					<div className="sentimento text-center d-flex justify-content-center">
						<p className="m-0 mr-2">{item.descricaoSentimento}</p>
						<img src={item.iconeSentimento} alt="" />
					</div>
				</div>
			</div>
			<div className="group">
				<div className="text">
					<div className="options mt-2">
						<div className="does mt-3">
							<span className="titleDoes">
								<img src="/assets/img/icons/common/greenlike.svg" alt="FAZER" /> FAZER
							</span>
							<ul className="parentes">
								{item.pontosAtencao.map((item: any, key: number) => {
									if (item.idProcedimentoAcao === 4) {
										fazerCount++;
										if (fazerCount <= 2) return <li key={key}>{item.descricao}</li>;
									}
								})}
							</ul>
						</div>
						<div className="avoid mt-3">
							<span className="titleAvoid">
								<img src="/assets/img/icons/common/redunlike.svg" alt="EVITAR" /> EVITAR
							</span>
							<ul className="parentes">
								{item.pontosAtencao.map((item: any, key: number) => {
									if (item.idProcedimentoAcao === 3) {
										evitarCount++;
										if (evitarCount <= 2) return <li key={key}>{item.descricao}</li>;
									}
								})}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="w-100 text-right">
				<Button
					className="btn btn-secondary button-details"
					onClick={() => {
						setShowModalDetails(true);
						setIdRecurso(item.idRecurso);
					}}
				>
					Detalhes da minha nota
				</Button>
			</div>
		</div>
	);
};

export default CardScore2;
