import "./CalendarComponent.scss";

import React, { useEffect, useRef, useState } from "react";

import { AgendaModel } from "../../models/AgendaModel";
import { Calendar } from "@fullcalendar/core";
import { MonthsEnum } from "../../models/MonthsEnum";
import { WidgetEventsModel } from "../../models/WidgetEventsModel";
import dayGridPlugin from "@fullcalendar/daygrid";
import moment from "moment";

interface Props<T> {
	data?: T[];
}

const CalendarComponent = ({ data }: Props<AgendaModel>) => {
	const [currentDateView, setCurrentDateView] = useState<Date>(new Date());
	const [currentYear, setCurrentYear] = useState<string>(
		moment(new Date()).format("YYYY"),
	);
	const [widgetEvents, setWidgetEvents] = useState<WidgetEventsModel[]>([]);
	const [calendarRef, setCalendarRef] = useState<Calendar>();
	const widgetCalendarRef = useRef(null);

	const renderEvents = () => {
		setWidgetEvents(
			data?.map(
				(x: AgendaModel) => new WidgetEventsModel(x),
			) as WidgetEventsModel[],
		);
	};

	useEffect(() => {
		if (widgetCalendarRef.current) {
			let calendar = new Calendar(widgetCalendarRef.current, {
				plugins: [dayGridPlugin],
				initialView: "dayGridMonth",
				displayEventTime: false,
				eventDisplay: "background",
				locale: "pt-br",
				headerToolbar: {
					left: "",
					center: "prev,title,next",
					right: "",
				},
				footerToolbar: {
					left: "",
					center: "custom1,custom2",
					right: "",
				},
				customButtons: {
					custom1: {
						text: "Dias com tarefas",
						click: function () {},
					},
					custom2: {
						text: "Tarefas em atraso",
						click: function () {},
					},
					prev: {
						text: "Prev",
						click: function () {
							calendar.prev();
							setCurrentDateView(calendar.getDate());
						},
					},
					next: {
						text: "Next",
						click: function () {
							calendar.next();
							setCurrentDateView(calendar.getDate());
						},
					},
				},
				views: {
					dayGridMonth: {
						titleFormat: { month: "long" },
					},
				},
				buttonText: {
					today: "hoje",
					month: "mês",
					week: "semana",
					day: "dia",
					list: "lista",
				},
			});

			calendar.render();
			setCalendarRef(calendar);

			const dW = moment().isoWeekday() - 1;
			const days = ['segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado', 'domingo'];
			document
						.querySelector(
							`[aria-label="${days[dW]}"]`,
						)
						?.classList.add("active");
		}
	}, []);

	useEffect(() => {
		setCurrentYear(moment(currentDateView).format("YYYY"));

		if (widgetEvents.length > 0) {
			widgetEvents.forEach((evento: WidgetEventsModel) => {
				if (evento.status === 4) {
					document
						.querySelector(
							`[data-date="${moment(evento.start).format("YYYY-MM-DD")}"]`,
						)
						?.classList.add("selected-event-atraso");
				}

				if (evento.status === 2) {
					document
						.querySelector(
							`[data-date="${moment(evento.start).format("YYYY-MM-DD")}"]`,
						)
						?.classList.add("selected-event-emprogresso");
				}
			});
		}
	}, [currentDateView]);

	useEffect(() => {
		if (data) {
			setWidgetEvents([]);
			renderEvents();
		}
	}, [data]);

	useEffect(() => {
		if (widgetEvents.length > 0 && calendarRef) {
			widgetEvents.map((evento: WidgetEventsModel) => {
				// Em atraso
				if (evento.status === 4) {
					document
						.querySelector(
							`[data-date="${moment(evento.start).format("YYYY-MM-DD")}"]`,
						)
						?.classList.add("selected-event-atraso");
				}

				// Em progresso
				if (evento.status === 2) {
					document
						.querySelector(
							`[data-date="${moment(evento.start).format("YYYY-MM-DD")}"]`,
						)
						?.classList.add("selected-event-emprogresso");
				}

				calendarRef?.addEvent({
					title: evento.title,
					date: evento.start,
					className: "selected-event",
				});
			});
		}
	}, [widgetEvents]);

	return (
		<div className="CalendarComponent">
			<div className="control text-center">
				<span className="futura-bold">{currentYear}</span>
				<ul>
					{MonthsEnum.map((month: any, key: number) => {
						return (
							<li
								key={key}
								className={`${
									moment(currentDateView).format("MM") === month.number && "active"
								}`}
								onClick={() => {
									let date = `${currentYear}-${month.number}-01`;
									calendarRef?.gotoDate(date);

									setCurrentDateView(
										new Date(moment(new Date(date)).add(2, "days").format("YYYY-MM-DD")),
									);
								}}
							>
								{month.short}
							</li>
						);
					})}
				</ul>
			</div>

			<div ref={widgetCalendarRef} />
		</div>
	);
};

export default CalendarComponent;
