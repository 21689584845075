import "./Dashboard.scss";

import { useEffect, useState } from "react";

import ChartEvolutionComfortZones from "../../components/ChartEvolutionComfortZones/ChartEvolutionComfortZones";
import ChartPerformance from "../../components/ChartPerformance/ChartPerformance";
import ComfortZoneChart from "../../components/ComfortZoneChart/ComfortZoneChart";
import DashboardResourceScore from "../../components/DashboardResourceScore/DashboardResourceScore";
import DashboardService from "../../services/DashboardService";
import DashboardTrackScore from "../../components/DashboardTrackScore/DashboardTrackScore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../components/Loading/Loading";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import { ToastContainer } from "react-toastify";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { toastSettings } from "../../models/toastConfig";
import ButtonNextScreen from "../../components/ButtonNextScreen/ButtonNextScreen";
import { Card, CardBody, CardTitle } from "reactstrap";
import TaskAgendas from "../../components/TaskAgendas/TaskAgendas";
import { useAuth } from "../../providers/AuthProvider";

const Dashboard = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [data, setData] = useState<any>([]);
	const [progress, setProgress] = useState<Number>(0);
	const { logout, user, planoActive } = useAuth();

	useEffect(() => {
		handleList().then();
	}, []);

	const handleList = async () => {
		try {
			const [_Response, _Error] = await new DashboardService().list();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			let progressoCursos: any = [];

			_Response.resultSet?.plusoft?.cursos.map((curso: any) => {
				progressoCursos.push(parseFloat(curso?.course_progress?.replace("%", "")));
			});

			setProgress(
				progressoCursos.reduce(function (a: any, b: any) {
					return a + b;
				}, 0) / progressoCursos.length,
			);
			setData(_Response.resultSet);
			setIsLoading(false);
		} catch (err) {
			console.warn(err);
		}
	};

	const render = (data: any) => {
		return (
			<div className="dashboard">
				<div className="d-flex" style={{ gap: "1rem" }}>
					<div className="w-100">
						<Card>
							<CardTitle>
								<h1 className="title">As notas dos seus recursos</h1>
							</CardTitle>
							<CardBody>
								<ChartPerformance items={data?.graficoRadar} />
							</CardBody>
						</Card>
					</div>
					<div className="w-100">
						<Card>
							<CardTitle>
								<h1 className="title">Meu sonho</h1>
							</CardTitle>
							<CardBody>
								<div className="my-dream d-flex justify-content-between">
									<div className="my-dream-texts">
										<div>
											<h2>
												Palavra que me define:{" "}
												{
													data?.objetivos?.questao?.[0]?.alternativas?.[0]?.respostas?.[0]
														?.resposta
												}
											</h2>
										</div>
										<div>
											<h2>
												Objetivo de vida:{" "}
												{
													data?.objetivos?.questao?.[1]?.alternativas?.[0]?.respostas?.[0]
														?.resposta
												}
											</h2>
										</div>
										<div>
											<h2>
												Como me sinto:{" "}
												{
													data?.objetivos?.questao?.[2]?.alternativas?.[0]?.respostas?.[0]
														?.resposta
												}
											</h2>
										</div>
									</div>
									<div className="my-dream-img-container">
										<img
											src={
												data?.objetivos?.questao?.[4]?.alternativas?.[0]?.respostas?.[0]
													?.caminhoHttp
													? process.env.REACT_APP_UELF_API +
													  "/" +
													  data?.objetivos?.questao?.[4]?.alternativas?.[0]?.respostas?.[0]
															?.caminhoHttp
													: "assets/img/my-dream.png"
											}
											alt=""
										/>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
				{/* <ComfortZoneChart data={data.graficoZona} /> */}
				{/* <ChartEvolutionComfortZones data={data.graficoEvolution} /> */}
				{data.recursos.length &&
					data.recursos.map((item: any, key: number) => (
						<DashboardResourceScore key={key} data={item} />
					))}
				{(planoActive == 3 || planoActive == 4) && (
					<DashboardTrackScore
						data={data.plusoft}
						piorRecurso={data.piorSubRecurso}
						porcentagemTrilha={progress}
					/>
				)}
				<TaskAgendas />
			</div>
		);
	};

	return (
		<PainelMasterPage
			title={"Dashboard"}
			subtitle={"Visão Geral"}
			subtitleclass="text-white"
			icon={<img src="assets/img/icons/title/dashboard-final.svg" />}
			onClick={() => {}}
		>
			{!isLoading ? <>{data && render(data)}</> : <Loading margin="my-6" />}

			<ButtonNextScreen text={"Voltar"} linkScreen={"/macro-goals"} />

			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</PainelMasterPage>
	);
};

export default Dashboard;
