import React, { FC } from "react";
import "./CardSurveyAssessment.scss";
import { useNavigate } from "react-router-dom";

interface CardSurveyAssessmentProps {}

const CardSurveyAssessment: FC<CardSurveyAssessmentProps> = () => {
	const navigate = useNavigate();

	return (
		<div className="CardSurveyAssessment" data-testid="CardSurveyAssessment">
			<div className="content">
				<div className="square">
					<div className="image">
						<img src="assets/img/posit.png" alt="Positivo" />
					</div>
					<div className="title">
						<span>
							PARABÉNS!
							<br />
							<br /> Você acaba de dar um passo a frente rumo ao seu sucesso pessoal!
						</span>
					</div>
					<div className="text">
						<span>
							A partir daqui você receberá o resultado da sua avaliação, permitindo que
							planeje melhor seus objetivos e metas, e aprenda conceitos-chave para a
							sua evolução
						</span>
					</div>
					<br />
					<div className="button">
						<button className="btn-result" onClick={() => navigate("/performance")}>
							Ver resultado
						</button>
					</div>
				</div>
				<div className="imageTwo">
					<img src="assets/img/conclusao.png" className="imageTwo" alt="Conclusão" />
				</div>
			</div>
		</div>
	);
};

export default CardSurveyAssessment;
