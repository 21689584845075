import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toastSettings } from "../models/toastConfig";
import ActiveJourneyService from "../services/ActiveJourneyService";
import { useAuth } from "./AuthProvider";

export interface IData {
	usuario: {
		id: number;
		nome: string;
		ocupacao: string;
		dataUsuario: string;
		cidade: string;
		estado: string;
		tipoPessoa: string;
		idade: string;
	};
	idJornadaAtiva: number;
	jornadasConcluidas: [
		{
			id: number;
			pontuacao: number;
			data: string;
		},
	];
}

export const ActiveJourneyContext = React.createContext<any>({});

export const ActiveJourneyProvider = (props: any) => {
	const [activeJourney, setActiveJourney] = useState<number | null>(null);
	const [completedJourneys, setCompletedJourneys] = useState<any[]>([]);
	const [dataUser, setDataUser] = useState<any[]>([]);
	const [error, setError] = useState(null);

	const { setPlanoActive } = useAuth();

	const navigate = useNavigate();
	const location = useLocation();

	let farolCreate = false;

	useEffect(() => {
		if (
			location.pathname !== "/login" &&
			location.pathname !== "/payments" &&
			!location.pathname.includes("/account-recovery") &&
			!location.pathname.includes("/external-payment") &&
			location.pathname !== "/" &&
			!location.pathname.startsWith("/admin")
		) {
			recoverActiveJourney().then();
		}
	}, [navigate]);

	const recoverActiveJourney = async () => {
		try {
			const [_Response, _Error] = await new ActiveJourneyService().list();

			if (!!_Error) {
				return setError(_Response || _Error);
			}

			setActiveJourney(Number(_Response?.resultSet?.idJornadaAtiva));
			setCompletedJourneys(_Response?.resultSet?.jornadasConcluidas);
			setPlanoActive(_Response?.resultSet?.idPlano);
			setDataUser(_Response?.resultSet);
		} catch (err) {
			console.warn(err);
		}
	};

	const startJourney = async () => {
		try {
			if (!farolCreate) {
				farolCreate = true;
				const [_Response, _Error] = await new ActiveJourneyService().create();

				if (!!_Error) {
					toastSettings(_Error, "bottom-center", "error");
					return setError(_Response || _Error);
				}

				await recoverActiveJourney();

				farolCreate = false;
				navigate("purpose");
			}
		} catch (err) {
			console.warn(err);
		}
	};

	const finishJourney = async () => {
		try {
			const [_Response, _Error] = await new ActiveJourneyService().plusoft();

			if (!!_Error) {
				return setError(_Response || _Error);
			}

			return true;
		} catch (err) {
			console.warn(err);
		}
	};

	if (error) {
		console.error("Error fetching data: ", error);
	}

	return (
		<ActiveJourneyContext.Provider
			value={{
				activeJourney,
				setActiveJourney,
				completedJourneys,
				setCompletedJourneys,
				startJourney,
				dataUser,
				setDataUser,
				recoverActiveJourney,
				finishJourney,
			}}
		>
			{props.children}
		</ActiveJourneyContext.Provider>
	);
};

export const useActiveJourney = () => React.useContext(ActiveJourneyContext);
