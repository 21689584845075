import Cookies from "js-cookie";
import Api from "./api";

const AdminService = {
	async resumo() {
		try {
			const response = await Api.get(`app/admin/home`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	},

	async dashboard() {
		try {
			const response = await Api.get(`app/admin/dashboard`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	},

	async listaUsuarios() {
		try {
			const response = await Api.get(`app/admin/usuarios`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	},

	async diagnose(id: Number) {
		try {
			const response = await Api.get(`app/admin/diagnose/${id}`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	},

	async listGradesRecurso(idUsuario: number, idRecurso: number) {
		try {
			const response = await Api.get(
				`app/admin/nota/recurso/${idUsuario}/${idRecurso}`,
			);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	},

	async changePassword(
		idUsuario: number,
		data: { passwordChange: string; confirmPasswordChange: string },
	) {
		try {
			const response = await Api.post(`app/admin/alterarSenha`, {
				idUsuario: idUsuario,
				newPassword: data.passwordChange,
				confirmNewPassword: data.confirmPasswordChange,
			});

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	},
	async changePlan(idUsuario: number, data: { idPlano: number }) {
		try {
			const response = await Api.post(`app/admin/alterarPlano`, {
				idUsuario: idUsuario,
				idPlano: data.idPlano,
			});

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	},

	async listAllOccupation() {
		try {
			const response = await Api.post(`app/admin/ocupacoes`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	},

	async editLogo(image: File) {
		try {
			let formdata = new FormData();
			formdata.append("imagem", image);

			const response = await Api.post(
				`app/admin/configuracao/editar/logotipo`,
				formdata,
			);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	},

	async editVideo(image: File, id: Number) {
		try {
			let formdata = new FormData();
			formdata.append("video", image);

			const response = await Api.post(
				`app/admin/configuracao/editar/video/${id}`,
				formdata,
			);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	},

	async listUserWorseResource(resource: string) {
		try {
			var response = await Api.get(`app/admin/piorrecurso/${resource}`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	},

	async listConfigurations() {
		try {
			var response = await Api.get(`app/admin/configuracao/listar`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	},

	async listUserConfigurations() {
		try {
			var response = await Api.get(`app/usuario/configuracoes`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	},

	async updateConfigurations(data: {
		video_boas_vindas: string;
		video_evolution: string;
		video_pontos_atencao: string;
		video_seu_resultado: string;
		video_education: string;
		email_rh: string;
	}) {
		try {
			var response = await Api.post(`app/admin/configuracao/atualizar`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	},
};

export default AdminService;
