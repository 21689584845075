import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect } from "react";
import { Button } from "reactstrap";
import { useJourneyBackpack } from "../../providers/JourneyBackpackProvider";
import "./JourneyBackpackItem.scss";

interface JourneyBackpackItemProps {
	data: any[];
}

const JourneyBackpackItem: FC<JourneyBackpackItemProps> = ({ data }) => {
	const {
		setShowModalAdd,
		itemSelected,
		setItemSelected,
		remove,
		showModalRemove,
		setShowModalRemove,
		itemSelectedRem,
		setItemSelectedRem,
	} = useJourneyBackpack();

	useEffect(() => {
		if (itemSelected) {
			setShowModalAdd(true);
		}
	}, [itemSelected]);

	useEffect(() => {
		if (itemSelectedRem) {
			setShowModalRemove(true);
		}
	}, [itemSelectedRem]);

	const renderList = () => {
		return (
			<>
				{data?.length > 0 ? (
					<>
						{data?.map((item: any, index: number) => {
							return (
								<div className="journey-backpack-item-row content-row" key={index}>
									<div className="journey-backpack-item-col">
										<span className="mb-label">Item:</span>
										<span className="content">{index + 1}</span>
									</div>
									<div className="journey-backpack-item-col">
										<span className="mb-label">Objetivo:</span>
										<span className="content">{item?.descricaoObjetivo}</span>
									</div>
									<div className="journey-backpack-item-col">
										<span className="mb-label">Link:</span>
										<span className="content">
											<a href={item?.link} target="_blank" rel="noreferrer">
												Clique aqui para visualizar
											</a>
											{/* {item?.link} */}
										</span>
									</div>
									<div className="journey-backpack-item-col">
										<span className="mb-label">Tempo Estimado:</span>
										<span className="content">{item?.tempoEstimado}</span>
									</div>
									{/* <div className="journey-backpack-item-col actions m-0">
										<Button onClick={() => setItemSelected(item)}>
											<FontAwesomeIcon icon={faEdit} />
											<span>Editar</span>
										</Button>
										<Button onClick={() => setItemSelectedRem(item?.id)}>
											<FontAwesomeIcon icon={faTrash} />
											<span>Excluir</span>
										</Button>
									</div>  */}
								</div>
							);
						})}
					</>
				) : (
					<p className="my-4 text-center w-100">Não há registros disponíveis</p>
				)}
			</>
		);
	};

	return <div className="wrapper">{renderList()}</div>;
};

export default JourneyBackpackItem;
