import "./DetailedSearchAdmin.scss";

import { useEffect, useState, FC } from "react";
import Footer from "../../components/Footer/Footer";
import HeaderAdmin from "../../components/HeaderAdmin/HeaderAdmin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAdd,
	faFilter,
	faPlus,
	faRefresh,
	faSearch,
} from "@fortawesome/free-solid-svg-icons";
import TableListUsersAdmin from "../../components/TableListUsersAdmin/TableListUsersAdmin";
import Loading from "../../components/Loading/Loading";
import AdminService from "../../services/AdminService";
import { toastSettings } from "../../models/toastConfig";
import { ToastContainer } from "react-toastify";
import MordalTerm from "../../components/ModalTerm/ModalTerm";
import ModalFilterAdmin from "../../components/ModalFilterAdmin/ModalFilterAdmin";
import { Button } from "reactstrap";
import ModalAddUser from "../../components/ModalAddUser/ModalAddUser";

const DetailedSearchAdmin: FC = () => {
	const [isLoading, setLoading] = useState<boolean>(false);
	const [users, setUsers] = useState<[]>([]);
	const [filteredText, setFilteredText] = useState<string>("");
	const [showFilter, setShowFilter] = useState<boolean>(false);
	const [showModalAddUser, setShowModalAddUser] = useState<boolean>(false);

	const [selectFilterSetor, setSelectFilterSetor] = useState<string>("");
	const [selectFilterDtConclusao, setSelectFilterDtConclusao] =
		useState<string>("");

	const getUsers = () => {
		AdminService.listaUsuarios().then((data) => {
			const [_Response, _Error] = data;

			if (_Response?.status && !_Error) {
				setUsers(_Response?.resultSet);
			} else if (_Error) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		if (!users.length) {
			setLoading(true);
			getUsers();
		}
	}, [users]);

	return (
		<>
			<div className="DetailedSearchAdmin">
				<div className="container-fluid">
					<HeaderAdmin
						title="Pesquisa detalhada"
						subtitle="Listagem dos usuários"
						icon={<FontAwesomeIcon icon={faSearch} />}
					/>
				</div>

				<div className="search-area d-flex justify-content-between">
					<input
						type="text"
						placeholder="Procurar por nome ou CPF"
						onChange={(e) => setFilteredText(e.target.value)}
					/>

					<div>
						<button
							className="btn-secondary btn filter"
							onClick={() => {
								setShowFilter(true);
							}}
						>
							<FontAwesomeIcon icon={faFilter} /> FILTROS
						</button>
						<Button
							title="ATUALIZAR"
							onClick={() => {
								setLoading(true);
								getUsers();
							}}
						>
							<FontAwesomeIcon icon={faRefresh} />
						</Button>
						<Button title="CADASTRAR NOVO" onClick={() => setShowModalAddUser(true)}>
							<FontAwesomeIcon icon={faPlus} />
						</Button>
					</div>
				</div>

				{isLoading ? (
					<Loading />
				) : users ? (
					<TableListUsersAdmin
						users={users}
						filter={filteredText}
						selectFilterSetor={selectFilterSetor}
						selectFilterDtConclusao={selectFilterDtConclusao}
					/>
				) : (
					""
				)}
			</div>
			<ModalFilterAdmin
				show={showFilter}
				onClose={setShowFilter}
				setSelectFilterDtConclusao={setSelectFilterDtConclusao}
				setSelectFilterSetor={setSelectFilterSetor}
			/>
			<ModalAddUser show={showModalAddUser} onClose={setShowModalAddUser} />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<Footer />
		</>
	);
};

// @ts-ignore
export default DetailedSearchAdmin;
