import { faAdd, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./ModalAddUser.scss";

import { Controller, useForm } from "react-hook-form";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputMask from "react-input-mask";
import RemoveAcento from "../../utils/RemoveAcento";
import SignUpService from "../../services/SignUpService";
import ptBR from "date-fns/locale/pt-BR";
import { schema } from "./Validate/Validate";
import { toast } from "react-toastify";
import { useAuth } from "../../providers/AuthProvider";
import { validateCep } from "validations-br";
import { yupResolver } from "@hookform/resolvers/yup";
import { toastSettings } from "../../models/toastConfig";

interface ModalAddUserProps {
	show: boolean;
	onClose?: any;
}

type FormValues = {
	nomeRazao: string;
	cpfCnpj: string;
	email: string;
	senha: string;
	repeatPass: string;
	ocupacao: string;
	dataNascimentoAbertura: Date | null;
	celular: string;
	cep: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	cidade: string;
	estado: string;
	imagem: File | null;
	sendWhats: boolean;
};

const ModalAddUser = ({ show, onClose }: ModalAddUserProps) => {
	const [modal, setModal] = useState<boolean>(false);

	useEffect(() => {
		if (show) {
			setModal(show);
		}
	}, [show]);

	const toggle = () => setModal(!modal);
	const handleOnClose = () => onClose(false);

	const { signUp, setActiveTab } = useAuth();
	const [disabled, setDisabled] = useState<boolean>(true);
	const [isLoadingCity, setIsLoadingCity] = useState<boolean>(false);

	const [selectedCep, setSelectedCep] = useState<string>("");
	const [selectedState, setSelectedState] = useState<any>();
	const [selectedCity, setSelectedCity] = useState<any>();
	const [selectedPhone, setSelectedPhone] = useState<string>("");
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);
	const [selectedCpfCnpj, setSelectedCpfCnpj] = useState<string>("");
	const [selectedImagem, setSelectedImagem] = useState<File>();

	const [states, setStates] = useState<any[]>([]);
	const [cities, setCities] = useState<any[]>([]);
	const [dateNow, setDateNow] = useState<Date>(new Date());

	const [isSubmitting, setIsSubmitting] = useState(false);

	let defaultValues = {
		nomeRazao: "",
		cpfCnpj: "",
		email: "",
		senha: "",
		repeatPass: "",
		ocupacao: "",
		dataNascimentoAbertura: null,
		celular: "",
		cep: "",
		logradouro: "",
		numero: "",
		complemento: "",
		bairro: "",
		cidade: "",
		estado: "",
		imagem: null,
		sendWhats: false,
	} as FormValues;

	const {
		register,
		handleSubmit,
		control,
		setValue,
		reset,
		formState: { errors },
	} = useForm<FormValues>({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		registerLocale("pt-br", ptBR);
		setDefaultLocale("pt-br");
		populateStateSelect();
	}, []);

	useEffect(() => {
		setValue("estado", selectedState?.value?.toUpperCase() || "");
	}, [selectedState]);

	useEffect(() => {
		setValue("cidade", selectedCity?.value?.toUpperCase() || "");
	}, [selectedCity]);

	useEffect(() => {
		setValue("cep", selectedCep);
	}, [selectedCep]);

	useEffect(() => {
		setValue("celular", selectedPhone);
	}, [selectedPhone]);

	useEffect(() => {
		setValue("dataNascimentoAbertura", selectedDate || null);
	}, [selectedDate]);

	useEffect(() => {
		setValue("cpfCnpj", selectedCpfCnpj);
	}, [selectedCpfCnpj]);

	const handleSignUp = async (data: any) => {
		setIsSubmitting(true);

		const ret = await signUp(data, selectedImagem);

		if (ret) {
			setDisabled(false);
			setValue("cpfCnpj", "");
			setValue("dataNascimentoAbertura", null);
			setValue("celular", "");
			setValue("logradouro", "");
			setValue("bairro", "");
			setValue("numero", "");
			setValue("complemento", "");
			setValue("imagem", null);
			setSelectedCpfCnpj("");
			setSelectedDate(null);
			setSelectedPhone("");
			setSelectedCep("");
			setSelectedState("");
			setSelectedCity("");
			setStates([]);
			setCities([]);
			setSelectedImagem(undefined);

			reset();

			setIsSubmitting(false);
		} else {
			setIsSubmitting(false);
		}
	};

	const handleCEP = async (cep: string) => {
		setSelectedCep(cep);

		if (cep === "") {
			setValue("logradouro", "");
			setValue("bairro", "");
			setValue("numero", "");
			setValue("complemento", "");
			setSelectedCep("");
			setSelectedState("");
			setSelectedCity("");
			setDisabled(true);
			return;
		}

		if (!validateCep(cep)) {
			return;
		}

		const [_Response, _Error] = await new SignUpService().getCep(cep);

		if (!!_Error || _Response?.errors) {
			setDisabled(false);

			setValue("logradouro", "");
			setValue("bairro", "");
			setValue("numero", "");
			setValue("complemento", "");
			setSelectedCep("");
			setSelectedState("");
			setSelectedCity("");
			setCities([]);

			const _error =
				"Seu CEP não foi encontrado, favor preencher manualmente o endereço";
			throwsAnToastError(_error);

			return;
		}

		setDisabled(true);

		if (_Response?.cep) {
			setSelectedCep(_Response.cep);
		}

		if (_Response?.street) {
			setValue("logradouro", _Response.street);
		}

		if (_Response?.neighborhood) {
			setValue("bairro", _Response.neighborhood);
		}

		if (_Response?.city) {
			setValue("cidade", _Response.city.toUpperCase());
		}

		if (_Response?.state) {
			setIsLoadingCity(true);

			setSelectedState(
				states.find(
					(c: any) => RemoveAcento(c.value) === RemoveAcento(_Response.state),
				),
			);

			populateCitiesSelect(_Response.state, _Response.city);
		}
	};

	const populateStateSelect = async () => {
		const [_Response, _Error] = await new SignUpService().getStates();

		if (!!_Error || _Response?.errors) {
			const _error =
				_Response?.message ||
				"Um erro inesperado ocorreu, tente novamente mais tarde.";
			throwsAnToastError(_error);
			return;
		}

		let estados = _Response?.map((state: any) => {
			return { label: state.nome.toUpperCase(), value: state.sigla };
		});

		estados = estados.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));

		setStates(estados);
	};

	const populateCitiesSelect = async (state: string, city: string) => {
		const [_Response, _Error] = await new SignUpService().getCities(state);

		if (_Error || _Response?.errors) {
			const _error =
				_Response?.message ||
				"Um erro inesperado ocorreu, tente novamente mais tarde.";
			throwsAnToastError(_error);
			return;
		}

		let cidades = _Response?.map((city: any) => {
			return { label: city.nome.toUpperCase(), value: city.nome.toUpperCase() };
		});

		// adicionado por conta da api não trazer essa cidade
		if (state?.toUpperCase() === "SP") {
			cidades.push({ label: "São Paulo", value: "São Paulo" });
		}

		cidades = cidades.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));

		setCities(cidades);

		if (city) {
			setSelectedCity(
				cidades.find((c: any) => RemoveAcento(c.value) === RemoveAcento(city)),
			);
		}

		setIsLoadingCity(false);
	};

	const throwsAnToastError = (error: string) => {
		toast.error(
			error ||
				"Desculpe! Parece que há um problema com nosso servidor! Por favor, tente novamente em alguns instantes!",
		);
	};

	return (
		<Modal
			centered
			scrollable
			size="lg"
			isOpen={modal}
			toggle={toggle}
			onClosed={handleOnClose}
			className="ModalAddUser"
		>
			<ModalHeader>
				<h1>Cadastrar Usuário</h1>
			</ModalHeader>
			<form
				className="SignUp"
				data-testid="SignUp"
				onSubmit={handleSubmit(handleSignUp)}
			>
				<ModalBody>
					<div className="fieldset">
						<label htmlFor="nomeRazao">Nome Completo *</label>
						<input
							type="text"
							id="nomeRazao"
							className={`${!errors.nomeRazao || "invalid"}`}
							{...register("nomeRazao")}
						/>
						{errors.nomeRazao?.type === "required" && (
							<p className="error">Nome completo é obrigatório</p>
						)}
						{errors.nomeRazao?.type === "min" && (
							<p className="error">Deve conter no mínimo 3 caracteres</p>
						)}
						{errors.nomeRazao?.type === "max" && (
							<p className="error">Deve conter no máximo 100 caracteres</p>
						)}
					</div>
					<div className="fieldset">
						<label htmlFor="celular">Celular/Whatsapp</label>
						<Controller
							name="celular"
							control={control}
							render={({ field: { name, ref } }) => (
								<InputMask
									ref={ref}
									name={name}
									id="celular"
									className={`input ${!errors.celular || "invalid"}`}
									mask="(99) 99999-9999"
									onBlur={(e) => setSelectedPhone(e.target.value)}
								/>
							)}
						/>
						{errors.celular?.type === "required" && (
							<p className="error">Celular/Telefone é obrigatório</p>
						)}
						{errors.celular?.type === "phone" && (
							<p className="error">Celular/Telefone é inválido</p>
						)}
					</div>
					{/* <div className="fieldset">
					<label htmlFor="cpfcnpj">CPF/CNPJ *</label>
					<Controller
						name="cpfCnpj"
						control={control}
						render={({ field: { name } }) => (
							<>
								<CpfCnpj
									name={name}
									type="tel"
									value={selectedCpfCnpj}
									className={`${!errors.cpfCnpj || "invalid"}`}
									onChange={(event: any, type: string) => {
										setSelectedCpfCnpj(event.target.value);
									}}
								/>
							</>
						)}
					/>
					{errors.cpfCnpj?.type === "required" && (
						<p className="error">CPF/CNPJ é obrigatório</p>
					)}
					{errors.cpfCnpj?.type === "cpfCnpj" && (
						<p className="error">CPF/CNPJ é inválido</p>
					)}
				</div> */}
					<div className="fieldset">
						<label htmlFor="email">E-mail *</label>
						<input
							type="email"
							id="email"
							className={`${!errors.email || "invalid"}`}
							{...register("email")}
						/>
						{errors.email?.type === "required" && (
							<p className="error">Email é obrigatório</p>
						)}
						{errors.email?.type === "email" && (
							<p className="error">Email é inválido</p>
						)}
						{errors.email?.type === "max" && (
							<p className="error">Deve conter no máximo 150 caracteres</p>
						)}
					</div>
					<div className="fieldset">
						<label htmlFor="senha">Senha *</label>
						<input
							type="password"
							id="senha"
							className={`${!errors.senha || "invalid"}`}
							maxLength={25}
							{...register("senha")}
						/>
						<span className="help-text">
							Use 8 ou mais caracteres com uma combinação de letras maiúsculas, letras
							minúsculas e números sem caracteres especiais.{" "}
						</span>
						{errors.senha?.type === "required" && (
							<p className="error">Senha é obrigatória</p>
						)}
						{errors.senha?.type === "matches" && (
							<p className="error">Senha não atinge os requisitos mínimos</p>
						)}
						{errors.senha?.type === "min" && (
							<p className="error">Deve conter no mínimo 8 caracteres</p>
						)}
						{errors.senha?.type === "max" && (
							<p className="error">Deve conter no máximo 50 caracteres</p>
						)}
					</div>
					<div className="fieldset">
						<label htmlFor="repeatPass">Repita a senha *</label>
						<input
							type="password"
							id="repeatPass"
							maxLength={25}
							className={`${!errors.repeatPass || "invalid"}`}
							{...register("repeatPass", { required: true })}
						/>
						{errors.repeatPass?.type === "required" && (
							<p className="error">A senha não foi prenchida</p>
						)}
						{errors.repeatPass?.type === "min" && (
							<p className="error">A senha precisa ter no minimo 8 caraceteres</p>
						)}
						{errors.repeatPass?.type === "max" && (
							<p className="error">A senha precisa ter no maximo 25 caraceteres</p>
						)}
						<p className="error">{errors.repeatPass?.message}</p>
					</div>
					{/* <div className="fieldset">
					<label htmlFor="ocupacao">Ocupação</label>
					<input
						type="text"
						id="ocupacao"
						className={`${!errors.ocupacao || "invalid"}`}
						{...register("ocupacao")}
					/>
					{errors.ocupacao?.type === "max" && (
						<p className="error">Deve conter no máximo 100 caracteres</p>
					)}
				</div> */}

					{/* <div className="fieldset">
					<label htmlFor="dataNascimentoAbertura">
						Data de Nascimento/Abertura *
					</label>
					<Controller
						name="dataNascimentoAbertura"
						control={control}
						render={({ field: { name, ref } }) => (
							<DatePicker
								ref={ref}
								name={name}
								maxDate={dateNow}
								selected={selectedDate}
								className={`${!errors.dataNascimentoAbertura || "invalid"}`}
								onChange={(date) => setSelectedDate(date || null)}
								dateFormat="dd/MM/yyyy"
								locale="pt-BR"
							/>
						)}
					/>
					{errors.dataNascimentoAbertura?.type === "required" && (
						<p className="error">Data de Nascimento/Abertura é obrigatória</p>
					)}
					{errors.dataNascimentoAbertura?.type === "dataNascimentoAbertura" && (
						<p className="error">{errors.dataNascimentoAbertura?.message}</p>
					)}
				</div> */}
					{/* <div className="fieldset">
					<label htmlFor="cep">CEP</label>
					<Controller
						name="cep"
						control={control}
						render={({ field: { name, ref } }) => (
							<InputMask
								ref={ref}
								name={name}
								id="cep"
								className={`input ${!errors.cep || "invalid"}`}
								mask="99999-999"
								onBlur={(e) => handleCEP(e.target.value)}
							/>
						)}
					/>
					{errors.cep?.type === "cep" && <p className="error">CEP é inválido</p>}
				</div> */}
					{/* <div className="fieldset">
					<label htmlFor="logradouro">Rua</label>
					<input
						type="text"
						id="public-place"
						className={`${!errors.logradouro || "invalid"}`}
						{...register("logradouro")}
					/>
					{errors.logradouro?.type === "max" && (
						<p className="error">Deve conter no máximo 150 caracteres</p>
					)}
				</div>
				<div className="fieldset">
					<label htmlFor="numero">Número</label>
					<input
						type="text"
						id="numero"
						className={`${!errors.numero || "invalid"}`}
						{...register("numero")}
					/>
					{errors.numero?.type === "max" && (
						<p className="error">Deve conter no máximo 10 caracteres</p>
					)}
				</div>
				<div className="fieldset">
					<label htmlFor="bairro">Bairro</label>
					<input
						type="text"
						id="bairro"
						className={`${!errors.bairro || "invalid"}`}
						{...register("bairro")}
					/>
					{errors.bairro?.type === "max" && (
						<p className="error">Deve conter no máximo 150 caracteres</p>
					)}
				</div>
				<div className="fieldset">
					<label htmlFor="complemento">Complemento</label>
					<input
						type="text"
						id="complemento"
						{...register("complemento")}
						maxLength={70}
					/>
				</div>
				<div className="fieldset">
					<label htmlFor="estado">Estado</label>
					<Controller
						name="estado"
						control={control}
						render={({ field: { onChange, name, ref } }) => (
							<Select
								ref={ref}
								name={name}
								isClearable
								isSearchable={false}
								options={states}
								placeholder="Selecione..."
								className={`select ${disabled && "disabled"} ${
									!errors.estado || "invalid"
								}`}
								value={selectedState || ""}
								onChange={(val: any) => {
									onChange(val?.value || "");
									setSelectedState(val);
									setSelectedCity("");
									populateCitiesSelect(val?.value, "");
								}}
								isDisabled={disabled}
								noOptionsMessage={() => "Não há registros"}
								styles={customLoginStyles}
							/>
						)}
					/>
				</div>
				<div className="fieldset">
					<label htmlFor="cidade">Cidade</label>
					{!isLoadingCity ? (
						<Controller
							name="cidade"
							control={control}
							render={({ field: { onChange, name, ref } }) => (
								<Select
									ref={ref}
									name={name}
									isClearable
									isSearchable={false}
									options={cities}
									placeholder="Selecione..."
									className={`select ${disabled && "disabled"} ${
										!errors.cidade || "invalid"
									}`}
									value={selectedCity || ""}
									onChange={(val: any) => {
										onChange(val?.value || "");
										setSelectedCity(val);
									}}
									isDisabled={disabled}
									noOptionsMessage={() => "Não há registros"}
									styles={customLoginStyles}
								/>
							)}
						/>
					) : (
						<FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: 26 }} />
					)}
				</div> */}
					{/* <div className="fieldset d-flex-inline">
					<label htmlFor="imagem">Envie uma selfie</label>
					<div className="content-selfie d-flex">
						<input
							type="file"
							accept="image/*"
							className={`${!errors.imagem || "invalid"}`}
							{...register("imagem")}
							onChange={onImageChange}
						/>
						{selectedImagem && (
							<div className="view-selfie">
								<img alt="not fount" src={URL.createObjectURL(selectedImagem)} />
							</div>
						)}
					</div>
					{errors.imagem?.type === "size" && (
						<p className="error">Tamanho máximo permitido 1mb</p>
					)}
				</div> */}
				</ModalBody>
				<ModalFooter>
					<Button
						type="submit"
						id="register"
						disabled={isSubmitting}
						className="btn btn-primary"
					>
						<FontAwesomeIcon icon={faAdd} />
						<span>Cadastrar</span>
					</Button>
					<Button type="button" onClick={toggle}>
						<FontAwesomeIcon icon={faXmark} />
						<span>Fechar</span>
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default ModalAddUser;
