import "./CardEvolutionJourney.scss";

import { Link, useNavigate } from "react-router-dom";
import React, { FC, useState } from "react";

import { Button } from "reactstrap";
import Plyr from "plyr";
import VideoModal from "../VideoModal/VideoModal";
import { useConfiguration } from "../../providers/ConfigurationProvider";

interface CardEvolutionJourneyProps {}

const CardEvolutionJourney: FC<CardEvolutionJourneyProps> = () => {
	const player = new Plyr("#player");
	const navigate = useNavigate();
	const [showModalVideo, setShowModalVideo] = useState<boolean>(false);
	const { dataConfig } = useConfiguration();

	return (
		<div className="CardEvolutionJourney" data-testid="CardEvolutionJourney">
			<div className="group">
				<div className="title">
					<span>Seja bem-vindo a UELF Evolution</span>
				</div>
				<div className="content">
					<div>
						<p className="text">
							Após avaliar seus recursos, é importante que você defina objetivos e
							metas corretamente. Os vencedores trocam desejos por ações, pois desejos
							são passageiros, enquanto objetivos e metas fazem a diferença. Muitas
							pessoas confundem objetivos com metas, tarefas e desejos. Objetivo é a
							ação que desejamos, como andar de monociclo. Meta é um objetivo com prazo
							determinado, como equilibrar-se no monociclo por 2 minutos até o dia X.
						</p>

						<p className="text">
							<b>Dica UELF:</b> Bons objetivos e metas possuem essas 5 características,
							incorpore-as no seu plano.
						</p>
					</div>
				</div>

				<div className="cards-evolution-help">
					<div
						data-tooltip-html={
							"Específica (Specific): A meta deve ser claramente definida e específica, evitando ambiguidades e sendo direcionada para um resultado concreto.  "
						}
						data-tooltip-id="header-page-tooltip"
					>
						<div className="title">Especifidade</div>
						<div className="description">S</div>
					</div>
					<div
						data-tooltip-html={
							"Mensurável (Measurable): A meta deve ser quantificável e ter critérios objetivos para avaliar seu progresso e sucesso."
						}
						data-tooltip-id="header-page-tooltip"
					>
						<div className="title">Mensurabilidade</div>
						<div className="description">M</div>
					</div>
					<div
						data-tooltip-html={
							"Atingível (Achievable): A meta deve ser desafiadora, mas também realista e alcançável dentro dos recursos, habilidades e circunstâncias disponíveis."
						}
						data-tooltip-id="header-page-tooltip"
					>
						<div className="title">Possibilidade de ser alcançada</div>
						<div className="description">A</div>
					</div>
					<div
						data-tooltip-html={
							"Relevante (Relevant): A meta deve estar alinhada com seus objetivos maiores e ser relevante para seu contexto e aspirações."
						}
						data-tooltip-id="header-page-tooltip"
					>
						<div className="title">Relevante</div>
						<div className="description">R</div>
					</div>
					<div
						data-tooltip-html={
							"Temporal (Time-bound): A meta deve ter um prazo definido ou um limite de tempo para criar um senso de urgência e fornecer um prazo para trabalhar e monitorar o progresso."
						}
						data-tooltip-id="header-page-tooltip"
					>
						<div className="title">Temporal</div>
						<div className="description">T</div>
					</div>
				</div>
			</div>
			{dataConfig?.video_evolution ? (
				<div className="video">
					<span className="title">Próximos Passos</span>
					<Button
						className="btn-video-modal"
						onClick={() => {
							setShowModalVideo(true);
						}}
					>
						<div className="image">
							<img src="assets/video-icon.png" alt="Video Icon" />
						</div>
						<span>ASSISTA AO VÍDEO</span>
					</Button>
					{/* <div className="video-container">
					<div className="player">
						<video id="player" playsInline controls>
							<source
								src="https://deved.app.br/uelfhomologacao/api-v2/videos/uelf-evolution.mp4"
								type="video/mp4"
							/>
						</video>
					</div>
				</div> */}
				</div>
			) : (
				""
			)}

			<div className="button">
				<Link to={"/journey-map"}>
					<button type="button" className="a">
						<span>Voltar</span>
					</button>
				</Link>
				<Link to={"/macro-goals"}>
					<button type="button" className="two">
						<span>Visualizar</span>
					</button>
				</Link>
			</div>
			<VideoModal
				show={showModalVideo}
				onClose={setShowModalVideo}
				url={`${dataConfig?.video_evolution}`}
			/>
		</div>
	);
};

export default CardEvolutionJourney;
