import "./MacroGoalsItem.scss";

import { Button, Card, FormGroup, Input, Label } from "reactstrap";
import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MacroGoalsService from "../../services/MacroGoalsService";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faEye, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toastSettings } from "../../models/toastConfig";
import { useMacroGoals } from "../../providers/MacroGoalsProvider";
import SuccessRoadmapStars from "../SuccessRoadmapStars/SuccessRoadmapStars";
import SuccessRoadmapDate from "../SuccessRoadmapDate/SuccessRoadmapDate";
import SuccessRoadmapStatusSelect from "../SuccessRoadmapStatusSelect/SuccessRoadmapStatusSelect";
import FormataData from "../../utils/FormataDate";

export interface Props {
	data: {
		id: number;
		resourceId: number;
		category: string;
		goal: string;
		why: string;
		icone: string;
		subobjetivos: any[];
	};
	uelfGoal?: boolean;
}

export interface Subobjetivo {
	alvo: string;
	dataFinal: string;
	dataInicial: string;
	descricao: string;
	descricaoStatus: string;
	descricaoTempoNecessario: string;
	esforcoFisico: number;
	esforcoMental: number;
	id: number;
	idMetasSecundarias: number;
	idObjetivoUelf: number;
	idTempoNecessario: number;
	importancia: number;
	urgencia: number;
	motivacao: number;
	status: number;
}

const renderStatusSelect = (
	level: number,
	isUELF: boolean,
	status: number,
	primaryID: number,
	secondaryID?: number,
) => {
	if (status == 4) {
		return <img src="/assets/img/icons/common/redellipse.svg" />;
	} else if (status == 2) {
		return <img src="/assets/img/icons/common/yellowellipse.svg" />;
	} else if (status == 3) {
		return <img src="/assets/img/icons/common/greenellipse.svg" />;
	}

	if (secondaryID) {
		return (
			<SuccessRoadmapStatusSelect
				level={level}
				isUELF={isUELF}
				primaryID={primaryID}
				secondaryID={secondaryID}
				status={status}
			/>
		);
	} else {
		return (
			<SuccessRoadmapStatusSelect
				level={level}
				isUELF={isUELF}
				primaryID={primaryID}
				status={status}
			/>
		);
	}
};

const renderStars = (
	isUELF: boolean = false,
	prevRating: number,
	ID: number,
	editable: boolean = false,
) => {
	return (
		<SuccessRoadmapStars
			isUELF={isUELF}
			prevRating={prevRating}
			ID={ID}
			editable={editable}
		/>
	);
};

const renderFinalDate = (
	isUELF: boolean,
	id: number,
	date: string,
	canModify: boolean = true,
) => {
	return (
		<SuccessRoadmapDate
			isUELF={isUELF}
			id={id}
			date={date}
			canModify={canModify}
		/>
	);
};

const renderTasks = (data: any, uelfGoal: any) => {
	return (
		<div
			id={"item-" + data.id + "-1"}
			className={`success-roadmap-item-macro${uelfGoal ? " uelf" : " uelf"}`}
			data-testid="SuccessRoadmapItem"
		>
			<div className="item-row roadmap-wrapper">
				{data?.subobjetivos?.map((subobjetivo: Subobjetivo, index: number) => (
					<Card key={index}>
						<div className="item-row">
							<div className="item-col">
								<div className="">
									<div className="item-row">
										{index == 0 && (
											<div className="item-row title-row title-row-macro">
												<div className="item-col">
													<span className="title">Tarefa</span>
												</div>
												{/* <div className="item-col">
												<span className="title">Objetivo</span>
											</div> */}
												<div className="item-col">
													<span className="title">Data de início</span>
												</div>
												<div className="item-col">
													<span className="title">Data fim</span>
												</div>
												<div className="item-col">
													<span className="title">Status</span>
												</div>
												<div className="item-col">
													<span className="title">Urgência</span>
												</div>
												{/* <div className="item-col">
												<span className="title">Minha motivação</span>
											</div>
											<div className="item-col">
												<span className="title">Esforço mental</span>
											</div>
											<div className="item-col">
												<span className="title">Esforço físico</span>
											</div> */}
												<div className="item-col">
													<span className="title">Tempo da data</span>
												</div>
											</div>
										)}
										<div className="item-row content-row">
											<div className="item-col">
												<span className="mb-label">Sub-Meta</span>
												<span className="content">{subobjetivo.descricao}</span>
											</div>
											{/* <div className="item-col">
												<span className="mb-label">Objetivo</span>
												<span className="content">{subobjetivo.alvo}</span>
											</div> */}
											<div className="item-col">
												<span className="mb-label">Data de início</span>
												<span className="content">
													{FormataData(subobjetivo?.dataInicial)}
												</span>
											</div>
											<div className="item-col">
												<span className="mb-label">Data fim</span>
												<span className="content">
													{FormataData(subobjetivo?.dataFinal)}
												</span>
											</div>
											<div className="item-col">
												<span className="mb-label">Status</span>
												<span className="content">
													{uelfGoal
														? renderStatusSelect(
																2,
																uelfGoal,
																subobjetivo.status,
																subobjetivo.id,
																subobjetivo.idObjetivoUelf,
														  )
														: renderStatusSelect(
																2,
																uelfGoal,
																subobjetivo.status,
																subobjetivo.id,
																subobjetivo.idMetasSecundarias,
														  )}
												</span>
											</div>
											<div className="item-col">
												<span className="mb-label">Urgência</span>
												{renderStars(uelfGoal, subobjetivo.urgencia, subobjetivo.id, false)}
											</div>
											{/* <div className="item-col">
												<span className="mb-label">Minha motivação</span>
												{renderStars(
													uelfGoal,
													subobjetivo.motivacao,
													subobjetivo.id,
													false,
												)}
											</div>
											<div className="item-col">
												<span className="mb-label">Esforço mental</span>
												{renderStars(
													uelfGoal,
													subobjetivo.esforcoMental,
													subobjetivo.id,
													false,
												)}
											</div>
											<div className="item-col">
												<span className="mb-label">Esforço físico</span>
												{renderStars(
													uelfGoal,
													subobjetivo.esforcoFisico,
													subobjetivo.id,
													false,
												)}
											</div> */}
											<div className="item-col">
												<span className="mb-label">Tempo da data</span>
												<span className="content date-time">
													{subobjetivo.descricaoTempoNecessario}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Card>
				))}

				{data?.subobjetivos?.length === 0 && <p>Não há registros disponíveis!</p>}
			</div>
		</div>
	);
};

const animateContentVisibility = (id: number, index: number) => {
	let element = document.querySelector(
		"#item-" + id + "-" + index,
	) as HTMLElement;

	let contentPanel = element.querySelector(".roadmap-wrapper") as HTMLElement;
	contentPanel.classList.toggle("is-active");
};

const MacroGoalsItem = ({ data, uelfGoal }: Props) => {
	const { goals, setGoals, viewDelete, setIsMultipleSelected } = useMacroGoals();
	const [isDisabled, setIsDisabled] = useState<boolean>(true);

	const handleClick = () => {
		setIsDisabled(!isDisabled);
	};

	const setForEdit = (id: number) => {
		setGoals(
			[...goals].map((goal: any) => {
				if (goal.id === id) {
					goal.isSelectedEdit = true;
				}

				return goal;
			}),
		);
	};

	const setEditMacroGoals = async (id: number, dataValue: string) => {
		try {
			if (dataValue === "") {
				return;
			}

			let data: any = { id: id, resposta: dataValue };

			const [_Response, _Error] = await new MacroGoalsService().updateResposta(
				data,
			);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
			}

			setIsDisabled(true);

			toastSettings(`Dados inseridos com sucesso!`, "bottom-center", "success");

			return _Response.resultSet;
		} catch (err) {
			console.warn(err);
		}
	};

	const setForDeletion = (id: number, isMultipleSelected?: boolean) => {
		setGoals(
			[...goals].map((goal: any) => {
				if (goal.id === id) {
					goal.isSelectedDelete = true;
				}

				if (isMultipleSelected) {
					setIsMultipleSelected(true);
				}

				return goal;
			}),
		);
	};

	if (uelfGoal) {
		return (
			<div
				id={"item-" + data.id}
				className="macro-goals-item uelf"
				data-testid="MacroGoalsItem"
			>
				<FormGroup check inline>
					<Label check htmlFor={"cbox-" + data.id}>
						<Card>
							<div className="macro-goals-item-row title-row">
								<div className="macro-goals-item-col">
									<span className="title">Categoria</span>
								</div>
								<div className="macro-goals-item-col">
									<span className="title">Tipo</span>
								</div>
								<div className="macro-goals-item-col">
									<span className="title">Objetivos</span>
								</div>
								<div className="macro-goals-item-col">
									<span className="title">Porque isso é importante pra mim...</span>
								</div>
								<div className="">
									<span className="title"></span>
								</div>
							</div>
							<div className="macro-goals-item-row content-row">
								<div className="macro-goals-item-col">
									<span className="mb-label">Categoria UELF:</span>
									<div className="data-icons">
										<img src={data.icone} alt={data.category} width="35px" />
										<span className="content ml-2"> {data.category} </span>
									</div>
								</div>
								<div className="macro-goals-item-col">
									<span className="mb-label">Tipo:</span>
									<span className="content">Hábito</span>
								</div>
								<div className="macro-goals-item-col">
									<span className="mb-label">Objetivos:</span>
									<span className="content">{data.why}</span>
								</div>
								<div className="macro-goals-item-col">
									<span className="mb-label">Porque isso é importante para mim:</span>
									<Input
										autoFocus
										type="text"
										name="objetivo-uelf"
										defaultValue={data.goal}
										data-idObjetivo={data.id}
										onBlur={(e: any) => {
											setEditMacroGoals(data.id, e.target.value);
										}}
										maxLength={100}
										disabled={isDisabled}
										required
									/>
								</div>
								<div className="d-inline-block">
									<div className="d-flex justify-content-center align-items-center">
										<div className="col-icons">
											<button onClick={() => animateContentVisibility(data.id, 1)}>
												<FontAwesomeIcon icon={faEye} />
											</button>
											<button onClick={handleClick} style={{ cursor: "pointer" }}>
												<FontAwesomeIcon icon={faEdit} />
											</button>
										</div>
									</div>
								</div>
							</div>
						</Card>
						{renderTasks(data, uelfGoal)}
					</Label>
				</FormGroup>
			</div>
		);
	} else {
		return (
			<div
				id={"item-" + data.id}
				className={`macro-goals-item ${viewDelete ? "enable-selection" : ""}`}
				data-testid="MacroGoalsItem"
			>
				<FormGroup check inline>
					{/* <div className="input-wrapper">
						<Input
							type="checkbox"
							id={"cbox-" + data.id}
							className="input"
							disabled={!viewDelete}
							onChange={() => setForDeletion(data.id, true)}
						/>
					</div> */}
					<Label check htmlFor={"cbox-" + data.id}>
						<Card>
							<div className="macro-goals-item-row title-row">
								<div className="macro-goals-item-col">
									<span className="title">Categoria</span>
								</div>
								<div className="macro-goals-item-col">
									<span className="title">Tipo</span>
								</div>
								<div className="macro-goals-item-col">
									<span className="title">Objetivos</span>
								</div>
								<div className="macro-goals-item-col">
									<span className="title">Porque isso é importante pra mim...</span>
								</div>
							</div>
							<div className="macro-goals-item-row content-row">
								<div className="macro-goals-item-col">
									<div className="data-icons">
										<img src={data.icone} alt={data.category} width="35px" />
										<span className="content ml-2"> {data.category} </span>
									</div>
								</div>
								<div className="macro-goals-item-col">
									<span className="mb-label">Tipo:</span>
									<span className="content">Hábito</span>
								</div>
								<div className="macro-goals-item-col">
									<span className="content">{data.why}</span>
								</div>
								<div className="macro-goals-item-col d-inline-block">
									<div className="d-flex justify-content-center align-items-center">
										<div className="col-text-question">
											<span className="content">{data.goal}</span>
										</div>

										<div className="col-icons">
											<button onClick={() => animateContentVisibility(data.id, 1)}>
												<FontAwesomeIcon icon={faEye} />
											</button>
											<button onClick={() => setForEdit(data.id)}>
												<FontAwesomeIcon icon={faEdit} />
											</button>
											<button
												className="mt-1"
												onClick={() => setForDeletion(data.id, false)}
											>
												<FontAwesomeIcon icon={faTrash} />
											</button>
										</div>
									</div>
								</div>
							</div>
						</Card>
						{renderTasks(data, uelfGoal)}
					</Label>
				</FormGroup>
			</div>
		);
	}
};

export default MacroGoalsItem;
