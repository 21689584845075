import React, { FC, useEffect, useState } from "react";
import "./CardTodayTasks.scss";
import FormataData from "../../utils/FormataDate";
import SuccessRoadmapStars from "../SuccessRoadmapStars/SuccessRoadmapStars";

interface CardTodayTasksProps {
	data: any;
}

const CardTodayTasks: FC<CardTodayTasksProps> = (data: any) => {
	const [tasks, setTasks] = useState<[] | null>(null);

	const searchByDate = (array: any, dataAtual: any) => {
		return array.filter((elemento: any) => {
			const dataInicial = new Date(elemento.dataInicial);
			const dataFinal = new Date(elemento.dataFinal);
			const dataAtualComparacao = new Date(dataAtual);

			return (
				dataAtualComparacao >= dataInicial && dataAtualComparacao <= dataFinal
			);
		});
	};

	const renderStars = (prevRating: number) => {
		return (
			<SuccessRoadmapStars
				isUELF={false}
				prevRating={prevRating}
				ID={0}
				editable={false}
			/>
		);
	};

	const getFirstTasks = () => {
		if (tasks == null && data != null) {
			let dataObj: any = [];

			dataObj.push(...data?.data?.objetivoUELF?.subobjetivos);
			data?.data?.objetivos?.forEach((element: any) => {
				dataObj.push(...element?.subobjetivos);
			});

			dataObj = searchByDate(dataObj, new Date().toISOString().slice(0, 10));
			setTasks(dataObj);
		}
	};

	useEffect(() => {
		if (tasks == null) {
			getFirstTasks();
		}
	}, [tasks]);

	return (
		<div className="CardTodayTasks" data-testid="CardTodayTasks">
			<div className="header">
				<span className="title">Tarefas de hoje</span>
			</div>
			<div className="container-task">
				<div className="grid">
					<span className="grid-item">Tarefas</span>
					<span className="grid-item">Urgência</span>
					<span className="grid-item">Tempo</span>
					<span className="grid-item">Data Início</span>
					<span className="grid-item">Data Fim</span>
				</div>

				<div className="table-task">
					{tasks?.map((task: any, index: number) => {
						return (
							<div className="table-row">
								<span className="table-cell justify-content-left">
									<span className="pos">{index + 1}</span>
									{task.descricao}
								</span>
								<span className="table-cell text-right">
									{renderStars(task.urgencia)}
								</span>
								<span className="table-cell">{task.descricaoTempoNecessario}</span>
								<span className="table-cell">{FormataData(task.dataInicial)}</span>
								<span className="table-cell">{FormataData(task.dataFinal)}</span>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default CardTodayTasks;
