import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
	faCamera,
	faCameraAlt,
	faMailBulk,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { useAuth } from "../../providers/AuthProvider";
import "./CardProfile.scss";
import { useConfiguration } from "../../providers/ConfigurationProvider";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	editprofile?: boolean;
	handleImageClick?: any;
}

const CardProfile = ({ editprofile = true, handleImageClick }: Props) => {
	const { user } = useAuth();
	const { dataConfig } = useConfiguration();

	return (
		<div className="card profile-card CardProfile" data-testid="CardProfile">
			<div className="d-flex">
				<div className="pic">
					{user.imagem ? (
						<img
							src={`${process.env.REACT_APP_UELF_API}/${user.imagem}`}
							alt="Foto de perfil"
						/>
					) : (
						<img src="assets/img/1.png" alt="Foto de perfil" />
					)}
				</div>
				<FontAwesomeIcon
					onClick={() => handleImageClick()}
					icon={faCamera}
					className="camera-upload"
				/>
			</div>
			<p className="name futura-bold">{user?.nome}</p>
			<div className="justify-content-center text-area">
				<label>
					Profissão: {user?.ocupacao || " -"}
					<br />
					<br />
					Idade: {user?.idade || " -"}
					<br />
					<br />
					Localização:{" "}
					{user?.cidade && user?.estado ? `${user?.cidade} - ${user?.estado}` : " -"}
				</label>
			</div>
			<div className="info">
				<a className="solicitation mt-5" href={`mailto:${dataConfig?.email_rh}`}>
					<button className="btn mt-5 solicitation">
						<span>
							<FontAwesomeIcon icon={faEnvelope} />
							{""} Solicitar atendimento ao RH
						</span>
					</button>
				</a>
			</div>

			<div className={`configs ${!editprofile && "border-0"}`}>
				{editprofile && (
					<Link to={"/edit-profile"}>
						<i className="bi bi-pencil-square"></i>
						<span>Editar o meu perfil</span>
					</Link>
				)}
			</div>
		</div>
	);
};

export default CardProfile;
