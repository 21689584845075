import {
	faEdit,
	faEllipsisV,
	faFrown,
	faMeh,
	faSmile,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { FC, useState } from "react";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Row,
	UncontrolledButtonDropdown,
} from "reactstrap";
import NotesConfirmModal from "../../components/NotesConfirmModal/NotesConfirmModal";
import NotesEditModal from "../NotesEditModal/NotesEditModal";
// CSS
import "./NotesStage.scss";
import { useDashboard } from "../../providers/DashboardProvider";

interface INotesStage {
	notes: any;
	setFunction: any;
	isFeedback: boolean;
	showGoal?: boolean;
}

const NotesStage: FC<INotesStage> = ({
	notes,
	setFunction,
	isFeedback,
	showGoal,
}: INotesStage) => {
	const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [SelectedDelete, setSelectedDelete] = useState<number>(0);
	const [editNote, setEditNote] = useState<any>({});
	const [idObjetivo, setIdObjetivo] = useState<number>(0);
	const [isUelf, setIsUelf] = useState<boolean>(false);

	const { renderBgIconHex } = useDashboard();

	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

	let arr_icon: any = [
		"/assets/img/icons/resources/icon_money.svg",
		"/assets/img/icons/resources/icon_health.svg",
		"/assets/img/icons/resources/icon_knowledge.svg",
		"/assets/img/icons/resources/icon_time.svg",
		"/assets/img/icons/resources/icon_emotions.svg",
	];

	const isModalDeleted = (idNote: number, objetivo: number, uelf: boolean) => {
		setShowModalConfirm(true);
		setSelectedDelete(idNote);
		setIdObjetivo(objetivo);
		setIsUelf(uelf);
	};

	const editNotesModal = (note: any) => {
		setEditNote(note);
		setShowEditModal(true);
	};

	const toggle = () => {
		setDropdownOpen(!dropdownOpen);
	};

	return (
		<>
			{notes && notes?.length > 0 ? (
				notes.map((item: any, index: number) => (
					<>
						<div id={`item-${index}`} key={index} className="notes-item">
							<div className="item-header">
								<div className="item-label-icons">
									<img
										className="label-icon"
										src={arr_icon[item.idRecurso - 1]}
										alt=""
									/>
									<span className="item-title" style={{color: renderBgIconHex(item?.descricaoRecurso)}}>{item?.descricaoRecurso}</span>
								</div>
								<div className="item-label-emojis">
									<FontAwesomeIcon
										className={"ml-1 " + (item?.humor === 1 ? "active" : "")}
										icon={faFrown}
									/>
									<FontAwesomeIcon
										className={"ml-1 " + (item?.humor === 2 ? "active" : "")}
										icon={faMeh}
									/>
									<FontAwesomeIcon
										className={"ml-1 " + (item?.humor === 3 ? "active" : "")}
										icon={faSmile}
									/>

									<UncontrolledButtonDropdown id={`item-${index}`} key={index}>
										<DropdownToggle className="nav-link" color="" tag="a">
											<FontAwesomeIcon icon={faEllipsisV} />
										</DropdownToggle>
										<DropdownMenu container="body">
											<DropdownItem
												onClick={() => {
													editNotesModal(item);
												}}
											>
												<FontAwesomeIcon
													className="ml-3 active"
													icon={faEdit}
													style={{ cursor: "pointer" }}
												/>
												<span>Editar</span>
											</DropdownItem>
											<DropdownItem divider />
											<DropdownItem
												onClick={() => {
													isModalDeleted(item.id, item.idObjetivo, item.isUelf);
												}}
											>
												<FontAwesomeIcon
													className="ml-1 active cursor:pointer"
													icon={faTrash}
													style={{ cursor: "pointer" }}
												/>
												<span>Excluir</span>
											</DropdownItem>
										</DropdownMenu>
									</UncontrolledButtonDropdown>
								</div>
							</div>
							<div className="item-body">
								<span className="goal">Objetivo: {item?.descricaoObjetivo}</span>
								<div className="note-text">
									<p>{item?.anotacao}</p>
								</div>
							</div>
							<div className="note-date">
								<span>{moment(item.data).locale("pt-BR").format("ll")}</span>
							</div>
						</div>
					</>
				))
			) : (
				<>
					<Row className="justify-content-center">
						<div className="text text-center">Não há registros disponíveis!</div>
					</Row>
				</>
			)}
			<NotesConfirmModal
				setFunction={setFunction}
				idNote={SelectedDelete}
				show={showModalConfirm}
				onClose={setShowModalConfirm}
				idObjetivo={idObjetivo}
				isUelf={isUelf}
				isFeedback={isFeedback}
			/>
			<NotesEditModal
				show={showEditModal}
				setFunction={setFunction}
				isFeedback={isFeedback}
				showGoal={showGoal}
				onClose={() => {
					setShowEditModal(false);
				}}
				note={editNote}
			/>
		</>
	);
};

export default NotesStage;
