import "./DashboardAdmin.scss";

import { useEffect, useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import HeaderAdmin from "../../components/HeaderAdmin/HeaderAdmin";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAdminAuth } from "../../providers/AuthAdminProvider";
import Footer from "../../components/Footer/Footer";
import { useDashboardAdmin } from "../../providers/DashboardAdminProvider";
import CardResourcesPanelAdmin from "../../components/CardResourcesPanelAdmin/CardResourcesPanelAdmin";
import ChartPerformanceAdmin from "../../components/ChartPerformanceAdmin/ChartPerformanceAdmin";
import Loading from "../../components/Loading/Loading";

const DashboardAdmin = () => {
	const { getSubresourcesData, subResourcesData } = useDashboardAdmin();
	const [isLoading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (!subResourcesData) {
			setLoading(true);
			getSubresourcesData().then(() => setLoading(false));
		}
	}, [subResourcesData]);

	return (
		<>
			<div className="DashboardAdmin">
				<div className="container-fluid">
					<HeaderAdmin
						title="Dashboard"
						subtitle="Área de Dashboard"
						icon={<FontAwesomeIcon icon={faHome} />}
					/>

					<Card className="card-principal">
						<div className="row d-flex flex-row w-100">
							<CardBody>
								<CardTitle>
									<h2>A média das notas de cada recurso</h2>
								</CardTitle>
								{isLoading ? (
									<Loading />
								) : (
									<ChartPerformanceAdmin subResourcesData={subResourcesData} />
								)}
							</CardBody>
						</div>
					</Card>

					{isLoading ? (
						<Loading />
					) : (
						<CardResourcesPanelAdmin subResourcesData={subResourcesData} />
					)}
				</div>
			</div>
			<Footer />
		</>
	);
};

// @ts-ignore
export default DashboardAdmin;
