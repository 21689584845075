import "./Purpose.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import PurposeStage from "../../components/PurposeStage/PurposeStage";
import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { faCheck, faList } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../providers/AuthProvider";
import { useStages } from "../../providers/StagesProvider";
import GoogleTagManager, { EventEnum } from "../../services/GoogleTagManager";
import Slugify from "../../utils/Slugify";

const Purpose = () => {
	const { currentStageName } = useStages();
	const { setSlide } = useAuth();

	setSlide(1);

	useEffect(() => {
		GoogleTagManager.event(EventEnum.Marketing, { action: Slugify("4etapas_home") });	  
	}, []) 

	return (
		<PainelMasterPage
			title={currentStageName}
			subtitle={"Complete as 4 etapas para receber a sua avaliação"}
			subtitleclass="text-white"
			icon={<FontAwesomeIcon icon={faList} />}
			onClick={() => {}}
		>
			<div className="purpose">
				<PurposeStage />
			</div>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</PainelMasterPage>
	);
};

export default Purpose;
