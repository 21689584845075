import { useReactToPrint } from "react-to-print";
import "./PrintPerformance.scss";
import { useRef, useState } from "react";
import { Button, Row } from "reactstrap";
import { useDashboard } from "../../providers/DashboardProvider";
import GaugeChart from "react-gauge-chart";

const PrintPerformance = () => {
	const [colorBody, setColorBody] = useState<string>("");
	
	const componentRefPrint = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRefPrint.current,
		onBeforePrint() {
		
		}
	});

	const { renderIcon, renderBgIcon, theme, renderBgIconHex } = useDashboard();

	return (
		<>
			<Button onClick={() => handlePrint()}>Imprimir / PDF</Button>
			<div className={`${theme == "dark" ? "PrintPerformance" : "PrintPerformanceLight"} m-3 PrintPerformanceBlock`} ref={componentRefPrint}>
				<hr />
				<Row>
					<div className="col">
						<div className="nome">Olá, Maria Domingues! :)</div>
						<div className="descricao">
							As suas notas estão nos cards relacionados aos sentimentos{" "}
						</div>
					</div>
					<div className="col text-right d-flex flex-column mb-4">
						<div>
							<img className="logo" src={theme == "dark" ? "./assets/img/pag.png" : "./assets/img/pag-light.png"} />
						</div>
						<div className="w-100 mt-4">
							<span className="descricao">
								Esse resultado corresponde a data de: 12/02/2023
							</span>
						</div>
					</div>
				</Row>
				<Row>
					<div className="col">
						<div className="card" style={{ height: "292px" }}>
							<div className="card-title">Meu sonho</div>

							<div className="row m-0">
								<div className="col p-0">
									<div className="items-my-dream">
										<span>
											<b>Palavra que me define:</b> Determinado
										</span>
										<span>
											<b>Objetivo de vida:</b> Determinado
										</span>
										<span>
											<b>Como me sinto:</b> Determinado
										</span>
									</div>
								</div>
								<div className="col">
									<img className="image-my-dream" src="./assets/img/bg-adm.png" />
								</div>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card card-nota-final" style={{ height: "292px" }}>
							<div className="card-title">Sua nota final</div>
							<div className="row">
								<div className="col">
									<div className="chart-wrapper">
										<div className="chart-label">
											<div>
												<GaugeChart
													className="gauge-chart"
													nrOfLevels={5}
													colors={["#A8E297", "#77B974", "#59A632", "#498A28", "#2C671A"]}
													arcWidth={0.5}
													percent={parseFloat("32000") / 100000}
													hideText
													cornerRadius={0}
													needleBaseColor="#fff"
													needleColor="#262626"
												/>
												<div className="text-center w-100">
													<span className="status">
														<b>{"32000"}</b>/100.000
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col">
									<h1 className="title">Frágil</h1>
									<span className="descricao">
										Lorem ipsum dolor sit amet consectetur. Gravida suscipit nisl dui
										gravida semper mauris parturient urna.
									</span>
								</div>
							</div>
						</div>
					</div>
				</Row>
				<div className="card card-pior-recurso">
					<div className="row">
						<div className="col-9">
							<div className="pior-recurso d-flex justify-content-between">
								<div className="d-flex flex-row">
									<div className="image">{renderIcon(0, "Saúde")}</div>
									<div>
										<h1 className="title" style={{color: renderBgIconHex("Saúde")}}>Saúde</h1>
										<h2 className="subtitle">SUB-RECURSOS</h2>
									</div>
								</div>
								<div>
									<span className="nota">2,66</span>
								</div>
							</div>
							<div className="row lista-evitar-fazer">
								<div className="col">
									<div className="card">
										<img src="/assets/img/print/fazer.svg" />
										<ul>
											<li>
												Uma avaliação física antes de iniciar qualquer atividade física
											</li>
											<li>Ter objetivos claros como ponto de partida.</li>
										</ul>
									</div>
								</div>

								<div className="col">
									<div className="card card-lista">
										<img src="/assets/img/print/evitar.svg" />
										<ul>
											<li>O sedentarismo</li>
											<li>Realizar exercícios físicos sem orientação profissional.</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col col-pior-sub-recurso">
							<div className="card card-sub-recurso">
								<h1 className="title">Gestão da Saúde</h1>
								<div className="circle">2,3</div>
							</div>
							<div className="card card-sub-recurso">
								<h1 className="title">Gestão da Saúde</h1>
								<div className="circle">2,3</div>
							</div>
							<div className="card card-sub-recurso">
								<h1 className="title">Gestão da Saúde</h1>
								<div className="circle">2,3</div>
							</div>
							<div className="card card-sub-recurso">
								<h1 className="title">Gestão da Saúde</h1>
								<div className="circle">2,3</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-md-6 col-sm-12 ">
						<div className="card card-sub-recurso">
							<div className="header d-flex justify-content-between">
								<div className="d-flex flex-row">
									<div className="image">{renderIcon(0, "Dinheiro")}</div>
									<div>
										<h1 className="title"><span style={{color: `${renderBgIconHex("Dinheiro")}`}}>Dinheiro</span></h1>
										<h2 className="subtitle">SUB-RECURSOS</h2>
									</div>
								</div>
								<div>
									<span className="nota">2,66</span>
								</div>
							</div>
							<div className="notas row">
								<div className="col-sm-12 col-md-6">
									<div className="card m-0 mt-4">
										<div className="conteudo row">
											<div className="col-7 card-title">Organização do Tempo</div>
											<div className="col nota">2,3</div>
											<div className="estrela">
												<img src={`${process.env.PUBLIC_URL}/assets/img/star.png`} alt="" />
											</div>
										</div>
									</div>
								</div>
								<div className="col-sm-12 col-md-6">
									<div className="card m-0 mt-4">
										<div className="conteudo row">
											<div className="col-7 card-title">Organização do Tempo</div>
											<div className="col nota">2,3</div>
										</div>
									</div>
								</div>
								<div className="col-sm-12 col-md-6">
									<div className="card m-0 mt-4">
										<div className="conteudo row">
											<div className="col-7 card-title">Organização do Tempo</div>
											<div className="col nota">2,3</div>
										</div>
									</div>
								</div>
								<div className="col-sm-12 col-md-6">
									<div className="card m-0 mt-4">
										<div className="conteudo row">
											<div className="col-7 card-title">Organização do Tempo</div>
											<div className="col nota">2,3</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-12 ">
						<div className="card card-sub-recurso">
							<div className="header d-flex justify-content-between">
								<div className="d-flex flex-row">
									<div className="image">{renderIcon(0, "Conhecimento")}</div>
									<div>
									<h1 className="title"><span style={{color: `${renderBgIconHex("Conhecimento")}`}}>Conhecimento</span></h1>
										<h2 className="subtitle">SUB-RECURSOS</h2>
									</div>
								</div>
								<div>
									<span className="nota">2,66</span>
								</div>
							</div>
							<div className="notas row">
								<div className="col-sm-12 col-md-6">
									<div className="card m-0 mt-4">
										<div className="conteudo row">
											<div className="col-7 card-title">Organização do Tempo</div>
											<div className="col nota">2,3</div>
										</div>
									</div>
								</div>
								<div className="col-sm-12 col-md-6">
									<div className="card m-0 mt-4">
										<div className="conteudo row">
											<div className="col-7 card-title">Organização do Tempo</div>
											<div className="col nota">2,3</div>
											<div className="estrela">
												<img src={`${process.env.PUBLIC_URL}/assets/img/star.png`} alt="" />
											</div>
										</div>
									</div>
								</div>
								<div className="col-sm-12 col-md-6">
									<div className="card m-0 mt-4">
										<div className="conteudo row">
											<div className="col-7 card-title">Organização do Tempo</div>
											<div className="col nota">2,3</div>
										</div>
									</div>
								</div>
								<div className="col-sm-12 col-md-6">
									<div className="card m-0 mt-4">
										<div className="conteudo row">
											<div className="col-7 card-title">Organização do Tempo</div>
											<div className="col nota">2,3</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-12 ">
						<div className="card card-sub-recurso">
							<div className="header d-flex justify-content-between">
								<div className="d-flex flex-row">
									<div className="image">{renderIcon(0, "Tempo")}</div>
									<div>
									<h1 className="title"><span style={{color: `${renderBgIconHex("Tempo")}`}}>Tempo</span></h1>
										<h2 className="subtitle">SUB-RECURSOS</h2>
									</div>
								</div>
								<div>
									<span className="nota">2,66</span>
								</div>
							</div>
							<div className="notas row">
								<div className="col-sm-12 col-md-6">
									<div className="card m-0 mt-4">
										<div className="conteudo row">
											<div className="col-7 card-title">Organização do Tempo</div>
											<div className="col nota">2,3</div>
											<div className="estrela">
												<img src={`${process.env.PUBLIC_URL}/assets/img/star.png`} alt="" />
											</div>
										</div>
									</div>
								</div>
								<div className="col-sm-12 col-md-6">
									<div className="card m-0 mt-4">
										<div className="conteudo row">
											<div className="col-7 card-title">Organização do Tempo</div>
											<div className="col nota">2,3</div>
										</div>
									</div>
								</div>
								<div className="col-sm-12 col-md-6">
									<div className="card m-0 mt-4">
										<div className="conteudo row">
											<div className="col-7 card-title">Organização do Tempo</div>
											<div className="col nota">2,3</div>
										</div>
									</div>
								</div>
								<div className="col-sm-12 col-md-6">
									<div className="card m-0 mt-4">
										<div className="conteudo row">
											<div className="col-7 card-title">Organização do Tempo</div>
											<div className="col nota">2,3</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-12 ">
						<div className="card card-sub-recurso">
							<div className="header d-flex justify-content-between">
								<div className="d-flex flex-row">
									<div className="image">{renderIcon(0, "Emoções")}</div>
									<div>
									<h1 className="title"><span style={{color: `${renderBgIconHex("Emoções")}`}}>Emoções</span></h1>
										<h2 className="subtitle">SUB-RECURSOS</h2>
									</div>
								</div>
								<div>
									<span className="nota">2,66</span>
								</div>
							</div>
							<div className="notas row">
								<div className="col-sm-12 col-md-6">
									<div className="card m-0 mt-4">
										<div className="conteudo row">
											<div className="col-7 card-title">Organização do Tempo</div>
											<div className="col nota">2,3</div>
										</div>
									</div>
								</div>
								<div className="col-sm-12 col-md-6">
									<div className="card m-0 mt-4">
										<div className="conteudo row">
											<div className="col-7 card-title">Organização do Tempo</div>
											<div className="col nota">2,3</div>
										</div>
									</div>
								</div>
								<div className="col-sm-12 col-md-6">
									<div className="card m-0 mt-4">
										<div className="conteudo row">
											<div className="col-7 card-title">Organização do Tempo</div>
											<div className="col nota">2,3</div>
											<div className="estrela">
												<img src={`${process.env.PUBLIC_URL}/assets/img/star.png`} alt="" />
											</div>
										</div>
									</div>
								</div>
								<div className="col-sm-12 col-md-6">
									<div className="card m-0 mt-4">
										<div className="conteudo row">
											<div className="col-7 card-title">Organização do Tempo</div>
											<div className="col nota">2,3</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="w-100 text-center mt-4">
					<span style={{ color: "#43433B" }}>
						Zeneconomics 2022 - Todos os direitos reservados
					</span>
				</div>
			</div>
		</>
	);
};

export default PrintPerformance;
