import "./CardFeeling.scss";

import React, { useEffect, useState } from "react";

import CardFeelingService from "../../services/CardFeelingSercive";
import { toastSettings } from "../../models/toastConfig";
import { useNavigate } from "react-router-dom";
import { useSurvey } from "../../providers/SurveyProvider";
import { useDashboard } from "../../providers/DashboardProvider";

const CardFeeling = (sentimento: any) => {
	const [data, setData] = useState([]);
	const navigate = useNavigate();
	const { currentStage } = useSurvey();
	const { renderBgIconHex } = useDashboard();

	useEffect(() => {
		handleList().then();
	}, []);

	const handleList = async () => {
		try {
			const [_Response, _Error] = await new CardFeelingService().list();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return false;
			}

			setData(_Response.resultSet);
		} catch (err) {
			console.warn(err);
		}
	};

	const recordResponses = async (id: number) => {
		let response = {
			idSentimento: id,
			idRecurso: currentStage,
		};

		try {
			const [_Response, _Error] = await new CardFeelingService().recordResponses(
				response,
			);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return false;
			}

			if (currentStage === 5) {
				navigate("/survey-finish");
			} else {
				navigate("/survey");
			}
		} catch (err) {
			console.warn(err);
		}
	};

	const renderOptions = () => {
		if (data) {
			return (
				<div className="options">
					{data?.length > 0 &&
						data.map((item: any, index: number) => {
							return (
								<button
									key={index}
									className="option"
									onClick={() => {
										recordResponses(item.sentimento.id);
									}}
									style={{ backgroundColor: renderBgIconHex(sentimento?.name) }}
								>
									<div className="image">
										<img src={item.sentimento.icone} alt="Icones de Sentimentos" />
									</div>
									<span className="description">{item.sentimento.descricao}</span>
								</button>
							);
						})}
				</div>
			);
		}
	};

	return (
		<div className="CardFeeling" data-testid="CardFeeling">
			<div className="content">
				<div className="title">
					<span>Identifique como está sua energia para lidar com este recurso</span>
				</div>
				<div className="text">
					<span>
						Você sabia que nossos sentimentos são um grande motivador para nós? Além
						de avaliar nossos recursos numericamente, também é importante avaliar como
						nos sentimos em relação a eles. Nossos sentimentos nos ajudam a medir a
						energia que colocamos em nossas ações e evitar agir de forma
						desequilibrada. Pense sobre seus sentimentos em relação aos recursos e
						acompanhe sua evolução ao longo do tempo.
					</span>
				</div>
			</div>
			{renderOptions()}
		</div>
	);
};

export default CardFeeling;
