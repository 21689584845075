import { Button, Container, Form, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { schema } from "./Validate/Validate";
import AdminService from "../../services/AdminService";
import { toastSettings } from "../../models/toastConfig";
import Loading from "../Loading/Loading";
import "./ModalChangePlanAdmin.scss";
import Select from "react-select";
import { customStyles } from "../../models/selectConfig";

interface ChangePlanInputs {
	idPlano: number;
}

interface ModalProps {
	idUsuario: number;
	show: boolean;
	onClose?: any;
}

const ModalChangePlanAdmin = ({ idUsuario, show, onClose }: ModalProps) => {
	const [modal, setModal] = useState<boolean>(false);
	const [selectedPlan, setSelectedPlan] = useState<number>(0);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const toggle = () => setModal(!modal);
	const handleOnClose = () => onClose(false);

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		control,
		formState: { errors },
	} = useForm<ChangePlanInputs>({
		mode: "onChange",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		if (show) {
			reset();
			setIsSubmitting(false);
			setModal(show);
		}
	}, [show]);

	const handleChangePlan = async (data: {
		idPlano: number;
	}) => {
		setIsSubmitting(true);

		const [_Response, _Error] = await AdminService.changePlan(idUsuario, data);

		setIsSubmitting(false);

		if (!!_Error || !_Response?.status) {
			toastSettings(_Error || _Response?.message, "bottom-center", "error");
		} else if (_Response?.status) {
			toastSettings("Plano alterado com sucesso!", "bottom-center", "success");
		}

		toggle();
	};

	return (
		<Modal
			centered
			scrollable
			size="lg"
			isOpen={modal}
			onClosed={handleOnClose}
			className="modal-change-plan"
			data-testid="ModalChangePlanAdmin"
			toggle={toggle}
		>
			<ModalHeader>
				<h1>Alterar Plano</h1>
			</ModalHeader>
			<ModalBody>
				<Row>
					<Form onSubmit={handleSubmit(handleChangePlan)}>
							{/* <select
								className="form-select select"
								{...register("idPlano", { required: true })}>
		
							</select> */}

						<div className="fieldset">
							<Controller
								name="idPlano"
								control={control}
								render={({ field: { onChange, name, ref } }) => (
									<Select
									ref={ref}
									name={name}
									isClearable
									isSearchable={false}
									options={[
										{label: "Valuation", value: 1},
										{label: "Evolution", value: 2},
										{label: "Education", value: 3},
										{label: "Full", value: 4},
									]}
									placeholder="Selecione..."
									className={`select`}
									onChange={(val: any) => {
										onChange(val?.value || 0);
										setSelectedPlan(val?.value)
									}}
									styles={customStyles}
									/>
								)}
							/>
						</div>
						<p className="error">{errors.idPlano?.message}</p>
						{isSubmitting && <Loading/>}
						<Button
							type="submit"
							disabled={isSubmitting}
							className="btndefault w-100 mt-5"
						>
							Confirmar
						</Button>
					</Form>
				</Row>
			</ModalBody>
		</Modal>
	);
};

export default ModalChangePlanAdmin;
