import "./ToDoAvoidSteps.scss";

import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { Button, Card } from "reactstrap";
import CheckBox from "../CheckBox/CheckBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToDoAvoidService from "../../services/ToDoAvoidService";
import { faFile, faFileCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { toastSettings } from "../../models/toastConfig";
import { useStages } from "../../providers/StagesProvider";
import { useToDoAvoid } from "../../providers/ToDoAvoidProvider";
import { Tooltip } from "react-tooltip";
export interface IData {
	idProcessoJornada: number;
	respostas: AnswersList;
}

export interface AnswersList {
	evitar: Answers[];
	fazer: Answers[];
}

export interface Answers {
	idListaEvitarFazer: number;
	resposta: AnswersList;
	prioridade: number;
	isUser: number;
}

const ToDoAvoidSteps = ({ steps }: any): JSX.Element => {
	const [toAvoidQuestionsState, setToAvoidQuestionsState] = useState<any[]>([]);
	const [toDoQuestionsState, setToDoQuestionsState] = useState<any[]>([]);
	const { currentStage } = useStages();
	const { handleList, setShowModalAdd, setItemSelected } = useToDoAvoid();
	const navigate = useNavigate();

	const handlePost = async (data: IData) => {
		try {
			const [_Response, _Error] = await new ToDoAvoidService().post(data);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return false;
			}

			toastSettings("Dados inseridos com sucesso!", "bottom-center", "success");

			return true;
		} catch (err) {
			console.warn(err);
			return false;
		}
	};

	const completeStep = (journeyId: number) => {
		const data: IData = {
			idProcessoJornada: journeyId,
			respostas: {
				evitar: [],
				fazer: [],
			},
		};

		let checkboxs = document.querySelectorAll("input[type='checkbox']:checked");

		checkboxs.forEach((checkbox: any, index: number) => {
			checkbox = checkbox as HTMLInputElement;

			let id = checkbox.id.split("-");
			let resposta = checkbox.dataset.label;
			let group = checkbox.dataset.group;
			let isUser = Number(checkbox.dataset.isuser);

			let item = {
				idListaEvitarFazer: Number(id[id.length - 1]),
				resposta,
				prioridade: group === "toDo" ? 1 : index,
				isUser,
			};

			group === "avoid"
				? data.respostas.evitar.push(item)
				: data.respostas.fazer.push(item);
		});

		handlePost(data).then((ret: boolean) => {
			if (ret) {
				if (currentStage === steps?.subrecursos?.length - 1) {
					navigate("/goals");
				} else {
					handleList();
				}
			}
		});
	};

	const handleClick = (question: any, type: string) => {
		let cboxes = document.querySelectorAll(
			`input[name='checkbox-group-${currentStage}-${type}']:checked`,
		);

		let cname = "checkbox-" + type + "-" + question.isUser + "-" + question.id;

		if (type === "avoid") {
			if (cboxes.length > 2) {
				// @ts-ignore
				document.getElementById(cname).checked = false;

				toastSettings(
					"É permitido selecionar apenas 2 itens dessa lista",
					"bottom-center",
					"warning",
				);
				return;
			}

			if (toAvoidQuestionsState.includes(`${question.isUser}-${question.id}`)) {
				const newArray = toAvoidQuestionsState.filter(
					(e: any) => e !== `${question.isUser}-${question.id}`,
				);
				setToAvoidQuestionsState(newArray);
			} else {
				const newArray = [
					...toAvoidQuestionsState,
					`${question.isUser}-${question.id}`,
				];
				setToAvoidQuestionsState(newArray);
			}
		} else if (type === "toDo") {
			if (cboxes.length > 1) {
				// @ts-ignore
				document.getElementById(cname).checked = false;

				toastSettings(
					"É permitido selecionar apenas 1 itens dessa lista",
					"bottom-center",
					"warning",
				);
				return;
			}

			if (toDoQuestionsState.includes(`${question.isUser}-${question.id}`)) {
				const newArray = toDoQuestionsState.filter(
					(e: any) => e !== `${question.isUser}-${question.id}`,
				);
				setToDoQuestionsState(newArray);
			} else {
				const newArray = [
					...toDoQuestionsState,
					`${question.isUser}-${question.id}`,
				];
				setToDoQuestionsState(newArray);
			}
		}
	};

	const renderItems = (data: any, type: string) => {
		if (data) {
			return data?.map((question: any, index: number) => {
				return (
					<div className="item" key={index}>
						<span className="label">{question.descricao}</span>
						<div className="radios-container">
							<div className="radio-wrapper">
								<CheckBox
									key={index}
									id={`checkbox-${type}-${question.isUser}-${question.id}`}
									name={`checkbox-group-${currentStage}-${type}`}
									value={question.descricao}
									dataQuestionId={question.id}
									data-label={question.descricao}
									data-group={type}
									data-isuser={question.isUser}
									label={""}
									onChange={() => handleClick(question, type)}
									// @ts-ignore
									checked={
										type === "avoid"
											? toAvoidQuestionsState.includes(`${question.isUser}-${question.id}`)
											: toDoQuestionsState.includes(`${question.isUser}-${question.id}`)
									}
									changeOpt={true}
								/>
							</div>
						</div>
					</div>
				);
			});
		}
	};

	const renderStep = () => {
		if (steps) {
			return (
				<>
					<div className="to-do-avoid-steps-content">
						<div className="list to-do">
							<div className="item title">
								<div className="icon">
									<FontAwesomeIcon
										icon={faThumbsUp}
										style={{ color: "hsla(45, 6%, 13%, 1)" }}
									/>
								</div>
								<div className="wrapper">
									<span className="title p">Fazer</span>
									<span className="title s">
										Selecione <b>1 item</b> para fazer
									</span>
								</div>
								<Button
									color="dark"
									className="btn-incluir"
									onClick={() => {
										setItemSelected({
											idProcessoJornada: steps.subrecursos[currentStage].idProcessoJornada,
											etapaLista: 4,
										});
										setShowModalAdd(true);
									}}
									data-tooltip-content={
										"A lista abaixo é uma sugestão de itens mais comuns de atividades que vai poder ajudar na sua evolução. Caso na lista sugerida pela plataforma não tenha uma atividade que você entenda ser mais interessante para seu plano, você poderá incluir um novo item"
									}
									data-tooltip-id="helper"
								>
									<FontAwesomeIcon icon={faFileCirclePlus} /> Incluir itens
								</Button>
							</div>
							<>{renderItems(steps.listaFazer, "toDo")}</>
						</div>
						<div className="list to-avoid">
							<div className="item title">
								<div className="icon">
									<FontAwesomeIcon
										icon={faThumbsDown}
										style={{ color: "hsla(45, 6%, 13%, 1)" }}
									/>
								</div>
								<div className="wrapper">
									<span className="title p">Evitar</span>
									<span className="title s">
										Selecione <b>2 itens</b> para evitar
									</span>
								</div>
								<Button
									color="dark"
									className="btn-incluir"
									onClick={() => {
										setItemSelected({
											idProcessoJornada: steps.subrecursos[currentStage].idProcessoJornada,
											etapaLista: 3,
										});
										setShowModalAdd(true);
									}}
									data-tooltip-content={
										"A lista abaixo é uma sugestão de itens mais comuns de atividades que vai poder ajudar na sua evolução. Caso na lista sugerida pela plataforma não tenha uma atividade que você entenda ser mais interessante para seu plano, você poderá incluir um novo item"
									}
									data-tooltip-id="helper"
								>
									<FontAwesomeIcon icon={faFileCirclePlus} /> Incluir itens
								</Button>
							</div>
							<>{renderItems(steps.listaEvitar, "avoid")}</>
						</div>
					</div>
					<div className="actions-wrapper">
						<Link to="/diagnose">
							<Button color="dark" className="step-backward">
								Voltar
							</Button>
						</Link>

						<Button
							color="dark"
							className="step-forward"
							onClick={() =>
								completeStep(steps.subrecursos[currentStage].idProcessoJornada)
							}
						>
							{steps?.subrecursos?.length - 1 === currentStage
								? "Finalizar"
								: "Continuar"}
						</Button>
					</div>

					<Tooltip id="helper" className="adp-tooltip--component"></Tooltip>
				</>
			);
		}
	};

	useEffect(() => {
		if (steps) {
			let array1: any[] = [];
			let array2: any[] = [];

			steps?.listaEvitar?.map((question: any) => {
				let isChecked = question.checked;

				if (isChecked) {
					array1.push(`${question.isUser}-${question.id}`);
				}
			});

			steps?.listaFazer?.map((question: any) => {
				let isChecked = question.checked;

				if (isChecked) {
					array2.push(`${question.isUser}-${question.id}`);
				}
			});

			setToAvoidQuestionsState(array1);
			setToDoQuestionsState(array2);
		}
	}, [steps]);

	return <>{renderStep()}</>;
};

export default ToDoAvoidSteps;
